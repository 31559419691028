.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:before,
.slick-next:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  left: -25px;
  right: auto;
}
.slick-arrow.slick-disabled {
  opacity: 0.4;
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 51px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 14px;
  width: 14px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 3px solid transparent;
  background: white;
  display: block;
  height: 6px;
  width: 6px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 0;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -3px 0 0 -3px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li.slick-active button {
  background: none;
  border-color: #27aae0;
  width: 14px;
  height: 14px;
  margin: -7px 0 0 -7px;
}

.slider {
  margin: 0;
}
.slider .slide_one {
  height: 560px;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.slider .slide_one .img_wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.slider .slide_one .img_wrap:after {
  background: rgba(0, 0, 0, 0.6);
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.slider .slide_one .completion img {
  margin: 32px auto 42px;
}
.slider .slide_one .completion p {
  font-size: 23px;
  line-height: 35px;
  text-transform: none;
}
.slider .slide_one .completion .btn {
  display: inline-block;
  color: #fff;
  background: #27aae0;
  height: 24px;
  line-height: 24px;
  padding: 0 11px;
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  border-radius: 25px;
}
.slider .slide_one .img {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-animation: zoomin 120s infinite linear 1s;
  animation: zoomin 120s infinite linear 1s;
  transform-origin: 50.01%;
}

.slider .slide_one p {
  font-size: 13px;
  line-height: 1.8em;
  font-family: 'open_sansbold', sans-serif;
  color: white;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}
.slider .slide_one .p1 {
  font-size: 21px;
  font-family: 'open_sansbold', sans-serif;
  margin: 60px 0 10px;
  color: white;
  position: relative;
  z-index: 1;
}
.slider .slide_one .p1.m--single {
  font-size: 40px;
  color: #27aae0;
  margin-bottom: 55px;
  margin-top: 80px;
}
.slider .slide_one .completion .spec_text p {
  font-size: 15px;
  line-height: 25px;
  font-family: 'open_sansregular', sans-serif;
  font-weight: 400;
  margin-top: 65px;
}
.slider .slide_one .p2 {
  font-size: 70px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #fff;
  margin: 0 0 22px;
  position: relative;
  z-index: 1;
}
.slider .slide_one .time {
  font-size: 30px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
  position: relative;
  margin: 0 0 53px;
  z-index: 1;
}
.slider .slide_one .time span {
  color: #27aae0;
}
.slider .slide_one .current_time {
  color: #27aae0;
  position: relative;
  font-size: 55px;
  font-family: 'open_sansextrabold', sans-serif;
  z-index: 1;
}
.slider .slide_one .current_time.m--waiting {
  color: #fff;
  opacity: 0.15;
}
.slider .slide_one .completion .not_today h2 {
  color: #27aae0;
  font-family: 'open_sansbold', sans-serif;
  font-size: 23px;
  margin-bottom: 20px;
}
.slider .slide_one .completion .not_today p {
  font-size: 15px;
  max-width: 600px;
  text-align: center;
  line-height: 20px;
  margin: 0 auto;
  font-family: 'open_sansregular', sans-serif;
}

.slider .slide_one .current_time.pm.m--closed:after {
  background-image: url(../img/icon_bridge_no.png);
  height: 24px;
  background-size: 120px 24px;
}

@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .slider .slide_one .current_time.pm.m--closed:after {
    background-image: url(../img/icon_bridge_no@2x.png);
  }
}
