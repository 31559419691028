.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  text-align: center;
}
.modal:before {
  display: inline-block;
  vertical-align: middle;
  content: ' ';
  height: 100%;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1040;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  position: relative;
  max-width: 1190px;
  z-index: 1041;
  width: auto;
  margin: 10px;
}
.modal-content {
  background: #fff;
  padding: 40px 40px 18px 40px;
  position: relative;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.modal-content .name {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 9px;
}
.modal-content .name span {
  color: #27aae0;
  white-space: nowrap;
}
.modal-content .name a {
  color: #27aae0;
}
.modal-content .img {
  margin-bottom: 20px;
  text-align: center;
  max-height: 100%;
}
.modal-content .img img {
  max-width: 100%;
  max-height: 100%;
}
.modal-content--wrap {
  height: 100%;
}
.modal-close {
  position: absolute;
  right: 16px;
  top: 11px;
  color: #27aae0;
  font-size: 20px;
  cursor: pointer;
}
.modal-controls {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 0;
}
.modal .prev,
.modal .next {
  position: absolute;
  font-size: 30px;
  color: #fff;
  opacity: 0.5;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.modal .prev:hover,
.modal .next:hover {
  opacity: 1;
  color: #27aae0;
}
.modal .prev {
  left: -45px;
}
.modal .next {
  right: -45px;
}

.modal-open {
  padding-right: 0 !important;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 1;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.8;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.modal-open {
  overflow: hidden;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-header {
  padding: 15px;
  /*border-bottom: 1px solid #e5e5e5*/
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: center;
  /*border-top: 1px solid #e5e5e5*/
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }

  .fb-body .modal-dialog {
    width: 990px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}
