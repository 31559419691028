.left_menu {
  padding: 15px 20px 20px 0;
}
.left_menu a {
  font-size: 14px;
  color: #27aae0;
  position: relative;
  display: block;
  margin: 0 0 22px;
  padding: 0 0 0 33px;
  line-height: 20px;
  letter-spacing: 1pt;
}
.left_menu a:first-child {
  font-size: 14px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
}
.left_menu a:before {
  background: #27aae0;
  content: '';
  display: block;
  width: 12px;
  height: 3px;
  position: absolute;
  left: 0;
  top: 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.left_menu a.rss {
  color: #000;
  font-size: 12px;
  padding-top: 15px;
}
.left_menu a.rss:before {
  background: url(../img/rss.png);
  height: 20px;
  width: 21px;
  top: 15px;
  left: -4px;
}
.left_menu a.current {
  color: black;
}
.left_menu a.current:before {
  background: black;
}
.left_menu .left_menu--sub {
  padding: 0 0 0 20px;
  margin: -3px 0 22px;
}
.left_menu .left_menu--sub a {
  font-size: 12px;
  font-family: 'open_sansregular', sans-serif;
  text-transform: none;
  margin: 0 0 10px;
}
.left_menu .left_menu--sub a:before {
  top: 10px;
}
.left_menu .subscibe {
  margin-left: -5px;
  margin-top: 44px;
}
.left_menu .subscibe p {
  font-size: 12px;
  margin-bottom: 5px;
}
.left_menu .subscibe label {
  width: 190px;
  box-sizing: border-box;
  height: 36px;
  border: 1px solid #dedede;
  display: block;
  padding: 7px;
  border-radius: 5px;
  position: relative;
}
.left_menu .subscibe input[type='text'] {
  font-size: 12px;
  color: #000;
  border: none;
  padding: 0;
  margin-left: 5px;
}
.left_menu .subscibe span {
  font-size: 12px;
  color: #000;
  position: absolute;
  top: 12px;
  left: 29px;
}
.left_menu .subscibe span i {
  font-size: 12px;
}
.left_menu .subscibe i {
  font-size: 14px;
  color: #27aae0;
  font-style: normal;
}
.left_menu .subscibe button {
  font-family: 'open_sansextrabold', sans-serif;
  font-size: 12px;
  color: #fff;
  background: #27aae0;
  display: block;
  height: 22px;
  line-height: 20px;
  width: 30px;
  float: right;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  text-transform: uppercase;
  position: absolute;
  top: 6px;
  right: 6px;
}
