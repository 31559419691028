.m_news {
  background: #222a32;
  padding: 52px 0 48px;
}
.m_news .m_news_one {
  position: relative;
  padding: 0 0 41px;
}
.m_news .col-sm-4:nth-of-type(odd) .head {
  color: #27aae0;
}
.m_news p {
  font-size: 13px;
  line-height: 25px;
  color: white;
}
.m_news .head {
  font-size: 17px;
  font-family: 'open_sansbold', sans-serif;
  margin: 0 0 14px;
  text-transform: uppercase;
}
.m_news .head:after {
  background: #fff;
  content: '';
  display: block;
  border-radius: 3px;
  width: 55px;
  height: 3px;
  margin: 10px 0 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.m_news .head.date {
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  color: #27aae0;
  margin-bottom: 3px;
}
.m_news .txt {
  height: 75px;
  overflow: hidden;
  margin-bottom: 4px;
  padding-right: 20px;
}
.m_news a {
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  bottom: 0;
}

.news_one .pagination a.btn_next {
  right: 0;
}

.news_one .pagination a.btn_prev {
  left: 0;
}

.news_one .ya-share2 {
  margin-bottom: 40px;
  padding-left: 100px;
  position: relative;
  margin-top: 37px;
}
.news_one .ya-share2:before {
  content: 'Поделиться:';
  display: inline-block;
  position: absolute;
  left: 0;
  font-size: 14px;
  bottom: 1px;
}
.news_one .wr {
  padding: 0;
}
.news_one .img {
  margin-bottom: 32px;
}
.news_one .img img {
  max-width: 100%;
}
.news_one .brief {
  margin-bottom: 32px;
}
.news_one .brief p {
  font-size: 12px;
  line-height: 25px;
}
.news_one .brief p.date {
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  margin-bottom: 24px;
}
.news_one .brief .name {
  font-size: 18px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
  margin-top: 0;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.news_list .wr {
  padding: 0;
}
.news_list a.news_link {
  display: block;
  position: relative;
  padding-top: 100%;
  color: #222a32;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 10px;
}
.news_list a.news_link p {
  font-size: 100%;
}
.news_list a.news_link:before,
.news_list a.news_link:after {
  border-color: #dbdbdb;
  width: 0;
  height: 0;
  border-style: solid;
  content: '';
  display: block;
  position: absolute;
  right: -20px;
  top: -20px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  border-bottom-left-radius: 10px;
}
.news_list a.news_link:hover:before {
  border-width: 0 40px 40px 0;
  border-color: transparent white transparent transparent;
  right: 0;
  top: 0;
}
.news_list a.news_link:hover:after {
  border-width: 40px 0 0 40px;
  border-color: transparent transparent transparent #bababa;
  right: 0;
  top: 0;
}
.news_list a.news_link span {
  position: absolute;
  padding: 0 7% 0 14%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.news_list a.news_link span.date {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #222a32;
  top: 14%;
}
.news_list a.news_link span.name {
  font-size: 17px;
  line-height: 20px;
  top: 26%;
  height: 40px;
  overflow: hidden;
}
.news_list a.news_link span.txt {
  font-size: 12px;
  line-height: 20px;
  top: 46.5%;
  height: 80px;
}
.news_list a.news_link span.details {
  font-size: 12px;
  color: #27aae0;
  bottom: 12.5%;
  text-decoration: underline;
  color: #27aae0;
}
.news_list a.news_link.bg_dark span.date {
  color: #27aae0;
}
.news_list a.news_link.bg_dark span.name,
.news_list a.news_link.bg_dark span.txt {
  color: white;
}
.news_list a.news_link.bg_blue span.details {
  color: white;
}
.news_list .pagination_index {
  padding-top: 22px;
}

.news_list_fullwidth {
  .news_elem {
    display: grid;
    grid-template-columns: 240px 1fr;
    gap: 20px 40px;
    align-items: start;
    background-color: transparent;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e9ea;
    margin-bottom: 20px;

    &__img {
      width: 100%;
      padding-top: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: #e8e9ea;
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.default {
          width: 50%;
          height: auto;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 16px;

      .date {
        font-size: 15px;
        font-family: 'open_sansbold', sans-serif;
        color: #27aae0;
        margin-bottom: 12px;
      }

      .name {
        font-size: 20px;
        font-family: 'open_sanssemibold', sans-serif;
        line-height: 1.5;
        margin-bottom: 19px;
      }

      .txt {
        p {
          font-size: 15px;
          color: fade(#222a32, 70%);
          line-height: 1.6;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .news_list_fullwidth {
    .news_elem {
      grid-template-columns: 1fr;

      &__content {
        padding-top: 0;
      }
    }
  }
}
