.contest .sep_hr {
  background: url(../img/sep4.png) repeat-x;
  height: 1px;
  margin: 34px 0;
  opacity: 0.7;
}
.contest.cont_fin .sep_hr {
  margin: 38px 0;
}
.contest--head {
  font-size: 50px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  margin: 9px 0;
}
.contest--head span {
  color: #27aae0;
}
.contest--head a.bloggers {
  margin-right: 25px;
}
.contest--description {
  font-size: 19px;
}
.contest--title {
  font-size: 30px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
  margin-bottom: 38px;
  text-transform: uppercase;
}
.winner .contest--title {
  margin-top: 10px;
  margin-bottom: 25px;
}
.winner .contest--title.sm {
  margin-bottom: 20px;
  text-transform: none;
  color: #000;
  font-size: 25px;
  font-family: 'open_sanssemibold', sans-serif;
}
.winner .contest--title.sm:after {
  display: none;
}
.winner .contest--title.sm .blue {
  color: #27aae0;
  font-size: 25px;
  font-family: 'open_sansextrabold', sans-serif;
}
.contest--title.black {
  color: #000;
}
.contest--title:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin: 24px auto 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.contest--gallery {
  margin: 0 0 46px;
}
.contest--gallery--item:not(.win) {
  display: block;
  text-align: center;
  color: #222a32;
  font-size: 14px;
}
.contest--gallery--item:not(.win):hover .img:before,
.contest--gallery--item:not(.win):hover .img:after,
.contest--gallery--item:not(.win):hover .img .fa.fa-search {
  opacity: 1;
}
.contest--gallery .img {
  display: block;
  padding-top: 100%;
  position: relative;
}
.contest--gallery .img:before {
  border: 5px solid white;
  content: '';
  display: block;
  margin: -27px 0 0 -27px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 55px;
  height: 55px;
  position: absolute;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  opacity: 0;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
  z-index: 2;
}
.contest--gallery .img:after {
  background: rgba(0, 0, 0, 0.5);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}
.contest--gallery .img .fa.fa-search {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 28px;
  color: #fff;
  margin: -7px 0 0 -8px;
  opacity: 0;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
  z-index: 3;
}
.contest--gallery .img span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.contest--gallery .img span img {
  width: 100%;
}
.contest--gallery .img span.likes {
  right: auto;
  bottom: 10px;
  top: auto;
  left: 50%;
  margin-left: -30px;
}
.contest--gallery .txt {
  display: block;
  margin-bottom: 21px;
  text-align: center;
}
.contest--gallery .txt .name {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #222a32;
  text-transform: uppercase;
  display: block;
  margin: 18px 0 3px;
}
.contest--gallery .txt .name a {
  color: #27aae0;
}
.contest--gallery .txt .title {
  display: block;
  height: 34px;
  position: relative;
  overflow: hidden;
  font-size: 14px;
}
.contest--rating {
  margin: 34px 0 0;
}
.contest--rating--item {
  position: relative;
  padding: 0 0 0 24px;
  margin: 0 0 16px;
  font-size: 14px;
}
.contest--rating--item .num {
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 1px;
}
.contest--rating--item a {
  font-size: 14px;
}
.contest--rating--item {
  color: #27aae0;
  white-space: normal;
}
.contest--rating--item .rate {
  color: #27aae0;
  white-space: nowrap;
  display: inline-block;
}
.contest--rating--item .rate .icon {
  margin-top: 4px;
}
.contest .likes {
  display: inline-block;
  font-size: 16px;
  font-family: 'open_sansbold', sans-serif;
  height: 34px;
  line-height: 34px;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  border-radius: 34px;
  background: #222a32;
  color: #fff;
  padding: 0 12px;
  margin-left: 10px;
  vertical-align: 2px;
}
.contest .likes i {
  color: #27aae0;
  font-size: 15px;
  vertical-align: 1px;
}
.contest .winner--name {
  font-size: 20px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.contest .winner--name span:not(.likes) {
  display: block;
  font-size: 16px;
  font-family: 'open_sansregular', sans-serif;
  line-height: 25px;
  text-transform: none;
}
.contest .winner img {
  max-width: 100%;
  margin-bottom: 27px;
}
.contest .winner .win_person {
  background: #eeeeee;
  padding: 45px 20px 27px;
  max-width: 600px;
}
.contest .winner .win_person .contest--gallery--item {
  position: relative;
  display: block;
}
.contest .winner .win_person .likes {
  right: auto;
  bottom: 45px;
  top: auto;
  left: 50%;
  margin-left: -33px;
  position: absolute;
}
.contest .winner .wysiwyg p {
  font-size: 17px;
  line-height: 30px;
  padding-right: 10px;
}
