.seige_jumb {
  background: #fff url(/img/seige_main.png) right top no-repeat;
  background-size: 95.5%;
  height: 580px;
}
.seige_jumb .wr {
  padding-top: 131px;
  padding-bottom: 115px;
}
.seige_jumb-title {
  font-size: 80px;
  line-height: 90px;
  font-family: 'akrobat_black';
  color: white;
  text-transform: uppercase;
  margin: 35px 0 48px;
}
.seige_jumb-cat {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  padding: 4px 3px;
  background: #dd2d3a;
  font-family: 'clearsans_bold';
  color: white;
  text-transform: uppercase;
  display: inline-block;
}
.seige_jumb-info {
  font-size: 20px;
  line-height: 25px;
  border-left: 2px solid #dd2d3a;
  padding-left: 30px;
  font-family: 'clearsans_bold';
  color: white;
}
.seige_days {
  padding: 68px 0 0;
}
.seige_days .seige-h2 {
  margin-top: 6px;
  margin-bottom: 21px;
}
.seige_days .wr {
  padding-bottom: 75px;
  border-bottom: 2px solid #24262e;
}
.seige_days-days {
  font-size: 220px;
  line-height: 155px;
  font-family: 'akrobat_black';
  color: #dd2d3a;
  margin-bottom: 30px;
}
.seige_days-title {
  font-size: 57px;
  line-height: 50px;
  font-family: 'akrobat_black';
  color: #24262e;
  text-transform: uppercase;
  margin-left: 6px;
}
.seige-h1 {
  font-size: 80px;
  line-height: 90px;
  font-family: 'akrobat_black';
  text-transform: uppercase;
  margin: 35px 0 32px;
}

.seige-h2 {
  font-size: 35px;
  line-height: 40px;
  font-family: 'akrobat_bold';
  color: #24262e;
  margin-top: 0;
  margin-bottom: 28px;
}
.seige-h3 {
  font-size: 30px;
  line-height: 40px;
  font-family: 'akrobat_bold';
  color: #24262e;
  margin-top: 0;
  margin-bottom: 20px;
}
.seige-h4 {
  font-size: 20px;
  line-height: 30px;
  font-family: 'akrobat_bold';
  color: #24262e;
  margin-top: 0;
}
.seige-title {
  font-size: 40px;
  line-height: 40px;
  font-family: 'akrobat_bold';
  color: #24262e;
  margin-top: 0;
  margin: 54px 0px 28px;
}
.seige-text {
  font-size: 16px;
  line-height: 30px;
  font-family: 'clearsans_regular';
  color: #24262e;
}
.seige-text-block p {
  font-size: 16px;
  line-height: 30px;
  font-family: 'clearsans_regular';
  color: #24262e;
  margin-bottom: 32px;
}

.seige_days-sep {
  height: 100%;
  width: 1px;
  background: #24262e;
  /* text-align: center; */
  margin: auto;
}

.seige_star-list {
  margin: 0;
}
.seige_star-list li {
  font-family: 'clearsans_italic';
  font-style: italic;
  font-size: 12px;
  line-height: 20px;
  padding-left: 23px;
  position: relative;
  margin-bottom: 20px;
}
.seige_star-list li:last-child {
  margin-bottom: 0;
}
.seige_star-list li:before {
  content: url(../img/seige-star-sml.png);
  position: absolute;
  left: 0;
  top: 2px;
}
.seige_docs img {
  width: 100%;
}
.seige_docs .seige-h2 {
  line-height: 20px;
}
.seige-btn {
  padding: 5px 16px;
  padding-right: 61px;
  background-color: #dd2d3a;
  background-image: url(../img/seige-arrow-w.png);
  background-repeat: no-repeat;
  background-position: 90% center;
  font-size: 16px;
  margin-top: 39px;
  display: inline-block;
  line-height: 30px;
  font-family: 'akrobat_regular';
  color: white;
}
.seige-btn.back-btn {
  padding: 5px 16px;
  padding-left: 61px;
  background-color: #dd2d3a;
  background-image: url(../img/seige-arrow-w-l.png);
  background-repeat: no-repeat;
  background-position: 10% center;
  font-size: 20px;
  margin-top: 0;
  display: inline-block;
  line-height: 30px;
  font-family: 'akrobat_bold';
  color: white;
}
.seige-header-title {
  font-size: 15px;
  line-height: 20px;
  font-family: 'akrobat_black';
  text-transform: uppercase;
  margin: 0;
  margin-left: 10px;
  display: inline-block;
}
.seige-header-cat {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  padding: 4px 3px;
  background: #dd2d3a;
  font-family: 'clearsans_bold';
  color: white;
  text-transform: uppercase;
  display: inline-block;
  display: inline-block;
}
.seige-header .seige-link {
  float: right;
  line-height: 22px;
  padding-right: 41px;
}
.seige-header .seige-link:after {
  right: 0;
}
.seige-header {
  margin-bottom: -12px;
}
.seige-header .wr {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  border-top: 1px solid #000;
}
.seige-header .wr:before {
  height: 3px;
  background: #000;
  content: '';
  position: absolute;
  top: -6px;
  left: 0;
  width: 100%;
}
.seige-header .wr:after {
  background: url(/img/seige-sep.png);
  height: 3px;
  width: 100%;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
}
.seige-photo .article-btns {
  border-top: 2px solid #000;
}
.seige-photo .article-btns:before {
  content: none;
}
.seige-docs .article-btns {
  margin: 0;
}
.seige-docs .article-btns:before {
  content: none;
}
.seige-star-btn {
  padding: 5px 19px;
  padding-left: 50px;
  background-color: #24262e;
  background-image: url(../img/seige-star.png);
  background-repeat: no-repeat;
  background-position: 10% center;
  font-size: 20px;
  margin-top: 0;
  display: inline-block;
  line-height: 30px;
  font-family: 'akrobat_bold';
  color: white;
}
.seige-link {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 30px;
  font-family: 'clearsans_regular';
}
.seige-link:after {
  content: url(../img/seige-arrow.png);
  position: absolute;
  right: -31px;
  top: 0px;
}
.seige-archive-item-img {
  position: relative;
  margin-bottom: -26px;
}
.seige-archive-item {
  border-bottom: 1px dashed #24262e;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.seige-archive-item span {
  left: 0;
  bottom: 62px;
  color: white;
  display: inline-block;
  font-family: 'akrobat_bold';
  padding: 8px;
  font-size: 20px;
  padding-left: 44px;
  padding-right: 20px;
  background-color: #24262e;
  background-image: url(../img/seige-star.png);
  background-repeat: no-repeat;
  position: relative;
  background-position: 16px center;
}

.seige_info {
  font-family: 'clearsans_italic';
  font-style: italic;
  font-size: 12px;
  line-height: 15px;
}

.seige_docs .wr {
  padding-top: 63px;
  padding-bottom: 63px;
  border-bottom: 3px solid #24262e;
}

.seige_podvig {
  padding: 81px 0 27px;
}
.seige_podvig-orden {
  margin: auto;
  text-align: center;
  display: block;
  margin-left: 48px;
  margin-top: 16px;
}
.seige_thanks {
  position: relative;
}
.seige_thanks .seige-h4 {
  width: 75%;
}
.seige-sep-col {
  border-left: 1px solid #000;
  padding: 0 36px;
}
.seige_thanks .wr {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}
.seige_thanks .wr:before {
  background: url(/img/seige-sep.png);
  height: 3px;
  width: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}
.seige-sep {
  background: url(/img/seige-sep.png);
  height: 3px;
  width: 100%;
}

.seige-text-block p.seige-note {
  font-family: 'clearsans_italic';
  font-style: italic;
  font-size: 17px;
  line-height: 30px;
  padding-left: 38px;
  border-left: 4px solid #dd2d3a;
  width: 88%;
  margin-bottom: 35px;
}
.seige-docs-thanks p,
p.photo-thanks {
  font-family: 'clearsans_italic';
  font-style: italic;
  font-size: 17px;
  line-height: 30px;
  padding-left: 38px;
  border-left: 4px solid #dd2d3a;
}
p.photo-thanks {
  margin: 62px 0 80px;
}
.seige-docs-thanks {
  border-bottom: none !important;
  padding: 79px 0 40px;
}
.seige-docs-list {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
.seige-article .seige-h2 {
  margin-bottom: 37px;
}
.seige-text-block img {
  max-width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}
.seige-text-block p.seige-footnote {
  margin-top: 26px;
  margin-bottom: 51px;
  font-family: 'clearsans_italic';
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  padding-left: 39px;
  border-left: 3px solid #dd2d3a;
  width: 45%;
  text-align: left;
}
.seige-text-block p.seige-whitenote {
  margin: 34px 0 26px;
  font-family: 'clearsans_italic';
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  color: white;
}
.seige-text-block p.seige-starnote {
  font-family: 'clearsans_bolditalic';
  font-style: italic;
  font-size: 15px;
  line-height: 30px;
  padding-left: 43px;
  width: 85%;
  position: relative;
  margin: 46px 0;
}
p.seige-starnote:before {
  content: url(../img/seige-star.png);
  position: absolute;
  left: 0;
  top: 0;
}
.seige_dark-block {
  background: #24262e;
  padding: 50px 0;
  margin: 55px 0;
}
.seige_dark-block-col p,
.seige_dark-block-col p.seige-footnote,
.seige_dark-block-col h2.seige-h2 {
  color: white;
}
.seige_dark-block-col:first-child {
  border-right: 1px dashed #505158;
  padding-right: 60px;
}
.seige_dark-block-col:last-child {
  padding-left: 65px;
}
.seige_dark-block-col:last-child img {
  margin-top: 0;
}
.seige_dark-block-col:last-child img:first-child {
  margin-top: 20px;
}
.seige_dark-block-col p.seige-whitenote,
.seige_dark-block-col p.seige-footnote {
  width: auto;
}
.seige-linesep {
  margin: 50px 0 30px;
  height: 1px;
  background: #000;
}
.seige-col p.seige-footnote {
  width: auto;
}
.seige-col img:first-child {
  margin-top: 0;
}
.seige-col:first-child {
  border-right: 1px dashed #a7a8ab;
  padding-right: 60px;
}
.seige-col:last-child {
  padding-left: 65px;
}
.seige-article_sep {
  position: relative;
  margin: 53px 0 65px;
}
.seige-article_sep:after {
  width: 100%;
  height: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 7px;
  content: '';
  z-index: -1;
}
.seige-sep_stars {
  height: 15px;
  width: 130px;
  background-color: white;
  background-image: url(../img/seige-star-triple.png);
  background-repeat: no-repeat;
  margin: auto;
  background-position: center;
}
.seige-docs-list ul {
  counter-reset: counter;
}
.seige-docs-list ul li {
  border-bottom: 1px solid #000;
  counter-increment: counter;
}
.seige-docs-list ul li .seige-docs-item::before {
  content: counter(counter) '. ';
  font-family: 'akrobat_bold';
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 60px;
}
p.seige-doc-title {
  font-family: 'akrobat_bold';

  font-size: 20px;
  margin-bottom: 3px;
}
.seige-doc-img {
  background: #24262e;
  padding: 0 10px;
  /* width: 290px; */
  height: 290px;
  margin-bottom: 10px;
}
.seige-doc-img img {
  max-width: 87%;
  margin-bottom: auto;
  margin-top: auto;
  /* margin-bottom: 10px; */
  /* margin-top: 20px; */
  margin: auto;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.seige-photo .photo-col {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}
.seige-photo .seige-article_sep {
  margin: 22px 0 50px;
}
.seige-photo_wrap {
  background: #fff;
  position: relative;
  text-align: center;
  padding: 14px;
  height: 92%;
}
.seige-photo_wrap span {
  display: block;
  margin: 11px 41px;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
}
.seige-photo_wrap img {
  max-width: 100%;
}
.seige-photo_wrap:before {
  background: url(../img/seige-sep.png);
  height: 104%;
  width: 104%;
  content: '';
  position: absolute;
  top: -2%;
  left: -2%;
  z-index: -1;
}
.seige-doc-number {
  font-family: 'akrobat_black';
  color: white;
  float: right;
  font-size: 20px;
  margin: 13px 8px;
  z-index: 2;
  position: relative;
}
p.seige-doc-info {
  font-family: 'clearsans_italic';
  font-style: italic;
  font-size: 13px;
  line-height: 20px;
}
.seige-doc-sep {
  position: relative;
  /*margin: 10px 0;*/
}
.seige-doc-sep:after {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #cccdcf;
  position: absolute;
  left: 0;
  top: 7px;
  content: '';
  z-index: -1;
}
.seige-doc-hide {
  display: none;
  margin: -13px 0;
  padding: 5px 0;
}
.seige-doc-hide .row {
  margin: 53px 0 66px;
}
.seige-docs-item {
  position: relative;
  padding: 58px 0 26px;
  padding-left: 47px;
  padding-right: 20%;
}
.seige-doc-btn {
  position: absolute;
  right: 0;
  top: 58px;

  border-radius: 50%;
  background: #e9e9ea;
}
.seige-doc-btn img {
  margin: auto;

  padding: 8px;
  transition: 2s;
}
.seige-doc-btn.open {
  background: #dd2d3a;
}
.seige-doc-btn.open img {
  transform: rotate(45deg);
  filter: invert(1) contrast(10);
}
.seige-doc-sep_stars {
  height: 15px;
  width: 130px;
  background-color: white;
  background-image: url(../img/seige-star-triple-r.png);
  background-repeat: no-repeat;
  margin: auto;
  background-position: center;
}

.seige-body .header__top,
.seige-body .mob_m .seige-body .header__middle .menu .sub {
  background: #dd2d3a;
}

.seige-body .icon.arr_d {
  background-image: url(../img/arr_d_black.png);
}
.seige-body .header__middle .menu li a.current,
.seige-body .header__middle .menu li span.current {
  color: #dd2d3a;
}
.seige-body .header__top .header__top-lang a,
.seige-body .header__middle .header__top-lang a {
  background: #000;
}
.seige-body .header__top .header__top-lang a.current,
.seige-body .header__middle .header__top-lang a.current {
  background: #fff;
}

.seige-body .header__top-spec {
  filter: grayscale(1) contrast(2);
}
.seige-body .icon.phone,
.seige-body .icon.mail,
.seige-body .header__top-socials a {
  filter: invert(1);
}
.seige-body.spez .icon.phone,
.seige-body.spez .icon.mail,
.seige-body.spez .header__top-socials a {
  filter: none;
}
.seige-body.spez .header__top-socials a:after {
  background: #fff;
}
.seige-body .header__top-socials a:after {
  background: #000000;
}
.seige-body footer .footer__top {
  background: #24262e;
}

.seige-body footer .footer__top p:last-child a:after {
  background: #6d6f7f;
}
.seige-body .copyright span {
  color: #6d6f7f;
}
.seige-body .pr_link:before {
  background-image: url(../img/pr_logo-white.png);
  filter: contrast(0);
}
.seige-body .siluet i {
  background: url(../img/siluet-white.png);
  filter: contrast(0);
}
.seige-body footer {
  background: #292b34;
}

.seige-body footer p span,
.seige-body footer p a {
  color: #6d6f7f !important;
}
.seige-body footer .footer__top p a {
  color: #ffffff !important;
}
.seige-body footer .footer__top p:last-child a {
  color: #6d6f7f !important;
}
.seige-body .socials a img {
  filter: contrast(0);
}
.seige-body footer p {
  color: #fff;
}
body.spez.seige-body.scale-3 .seige_jumb-title {
  font-size: 100px;
  line-height: initial;
}
body.spez.seige-body.scale-2 .seige_jumb-title {
  font-size: 90px;
  line-height: initial;
}
body.spez.scale-2.seige-body .seige_jumb .wr {
  padding-top: 51px;
  padding-bottom: 45px;
}
body.spez.scale-2.seige-body .seige_jumb-cat {
  font-size: 16px;
}
body.spez.scale-3.seige-body .seige_jumb-cat {
  font-size: 20px;
}
body.spez.scale-2.seige-body .seige_jumb-info {
  font-size: 24px;
}
body.spez.scale-3.seige-body .seige_jumb-info {
  font-size: 28px;
}
body.spez.scale-2.seige-body .seige-text,
body.spez.scale-2.seige-body .seige_info,
body.spez.scale-2.seige-body .seige-text-block p,
body.spez.scale-2.seige-body .seige_star-list li {
  font-size: 18px;
  line-height: inherit;
}
body.spez.scale-3.seige-body .seige-text,
body.spez.scale-3.seige-body .seige_info,
body.spez.scale-3.seige-body .seige-text-block p,
body.spez.scale-3.seige-body .seige_star-list li {
  font-size: 20px;
  line-height: inherit;
}
body.spez.scale-2.seige-body .seige-photo_wrap span {
  font-size: 14px;
  line-height: inherit;
}
body.spez.scale-3.seige-body .seige-photo_wrap span {
  font-size: 16px;
  line-height: inherit;
}
