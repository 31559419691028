.pagination {
  text-align: center;
  padding: 33px 0 25px;
  margin: 0 0 1px;
  position: relative;
}
.pagination.staff_inner a.btn_prev {
  left: 0px;
}
.pagination.staff_inner a.btn_next {
  right: 0px;
}
.pagination a {
  display: inline-block;
  vertical-align: top;
  position: relative;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  color: #222a32;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.pagination a.round {
  border: 2px solid #27aae0;
  height: 30px;
  line-height: 31px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.pagination a.btn_prev {
  position: absolute;
  left: 45px;
  top: 0px;
  padding: 0 21px 0 35px;
}
.pagination a.btn_prev i {
  position: absolute;
  left: 14px;
  top: 11px;
}
.pagination a.btn_next {
  position: absolute;
  right: 45px;
  top: 0px;
  padding: 0 35px 0 21px;
}
.pagination a.btn_next i {
  position: absolute;
  top: 11px;
  right: 14px;
}
.pagination a.btn_back {
  padding: 0 22px;
}

.pagination_index {
  padding-bottom: 21px;
}
.pagination_index .sep {
  background: url(../img/sep4.png) repeat-x;
  opacity: 0.5;
  height: 1px;
  margin: 1px 0 32px;
}
.pagination_index .pag {
  border: 2px solid #27aae0;
  float: left;
  height: 30px;
  line-height: 32px;
  margin: 9px 0 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  width: 286px;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.pagination_index .pag a.btn_first {
  display: block;
  float: left;
  padding: 10px 9px 10px;
  line-height: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.pagination_index .pag a.btn_prev {
  display: block;
  float: left;
  padding: 10px 6px 10px;
  line-height: 0;
  position: absolute;
  left: 30px;
  top: 0;
}
.pagination_index .pag a.btn_last {
  display: block;
  float: right;
  padding: 10px 9px 10px;
  line-height: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.pagination_index .pag a.btn_next {
  display: block;
  float: right;
  padding: 10px 6px 10px;
  line-height: 0;
  position: absolute;
  right: 30px;
  top: 0;
}
.pagination_index .pag span {
  margin: 0 40px;
}
.pagination_index .pag b {
  color: #27aae0;
  font-weight: normal;
  margin-left: 8px;
}
.pagination_index .pag_select {
  border: 2px solid #27aae0;
  float: right;
  line-height: 30px;
  margin: 9px 0 0;
  position: relative;
  overflow: hidden;
  width: 160px;
  text-transform: uppercase;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.pagination_index .pag_select span {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  display: block;
  padding: 0 30px 0 11px;
  height: 30px;
  line-height: 30px;
  position: relative;
  cursor: pointer;
}
.pagination_index .pag_select span i {
  font-style: normal;
  color: #27aae0;
}
.pagination_index .pag_select span:after {
  background-image: url(../img/arr_d_black.png);
  background-size: 10px 6px;
  content: '';
  display: block;
  position: absolute;
  right: 13px;
  top: 13px;
  width: 10px;
  height: 6px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .pagination_index .pag_select span:after {
    background-image: url(../img/arr_d_black@2x.png);
  }
}
.pagination_index .pag_select span.open:after {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.pagination_index .pag_select .select_wrap {
  display: none;
}
.pagination_index .pag_select .select_wrap a {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  display: block;
  padding: 0 44px 0 11px;
  height: 30px;
  line-height: 30px;
}
.pagination_index .pag_select .select_wrap a:hover {
  background: #27aae0;
  color: white;
}
