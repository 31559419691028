.categories {
  font-size: 0;
  padding: 0 0 23px;
  margin: 3px 0 0;
  float: right;
}
.categories a {
  display: inline-block;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-decoration: none;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  margin: 0 5px 5px 0;
  color: white;
  text-transform: uppercase;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.categories a.disabled {
  cursor: default;
  opacity: 0.5;
}

.bridge_view .img {
  margin: 7px 0 30px;
  position: relative;
}
.bridge_view .img img {
  max-width: 100%;
}
.bridge_view .img .fade {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.bridge_view .wr {
  position: relative;
}
.bridge_view .raising-flag {
  z-index: 1;
  display: block;
  position: absolute;
  right: 0;
  margin: 0;
  top: 15px;
  font-weight: normal;
  padding: 25px 0px 0px;
  margin-right: 20px;
  text-align: center;
  width: 150px;
  height: 260px;
  background: url(../img/flag2.png) top center no-repeat;
}
.bridge_view .raising-flag .discr {
  color: #fff;
  display: inline-block;
  font-size: 15px;
  font-family: 'open_sansregular', sans-serif;
  line-height: 18px;
}
.bridge_view .raising-flag i {
  display: block;
  background: url(../img/icon_bridge.png) center no-repeat;
  height: 23px;
  margin: 30px 0 27px;
}
.bridge_view .raising-flag span:not(.discr) {
  color: #27aae0;
  display: block;
  line-height: 22px;
  font-size: 18px;
  font-family: 'open_sansbold', sans-serif;
}
.bridge_view .schedule {
  font: 24px/23px 'open_sanslight', sans-serif;
  margin-bottom: 14px;
}
.bridge_view .schedule span {
  color: #27aae0;
  font-family: 'open_sanssemibold', sans-serif;
}
.bridge_view .boggers-flag {
  z-index: 1;
  display: block;
  position: absolute;
  right: 180px;
  top: 15px;
  padding: 24px 0px 0px;
  text-align: center;
  width: 150px;
  font-size: 13px;
  height: 260px;
  background: url(../img/flag1.png) top center no-repeat;
}
.bridge_view .boggers-flag .discr {
  color: #222a32;
  font-size: 15px;
  font-family: 'open_sansregular', sans-serif;
  line-height: 18px;
}
.bridge_view .boggers-flag i {
  display: block;
  background: url(../img/logo_blog2.png) center no-repeat;
  height: 40px;
  background-size: contain;
  margin: 28px 0 5px;
}
.bridge_view .boggers-flag span:not(.discr) {
  display: block;
  margin: 0 auto;
  width: 40px;
  line-height: 40px;
  color: #27aae0;
  height: 42px;
  background: url(../img/bloggers_l.png) center no-repeat;
  font-size: 20px;
  font-family: 'open_sansextrabold', sans-serif;
}
.bridge_view h1 {
  margin-bottom: 18px;
  font-weight: normal;
}
.bridge_view .params {
  padding: 19px 0 22px;
}
.bridge_view .params .row:first-child p {
  border: 0;
}
.bridge_view .params p {
  border-top: 1px solid #4e555b;
  font-size: 13px;
  color: #27aae0;
  margin: 0 50px 17px;
  padding: 16px 0 0;
}
.bridge_view .params p span,
.bridge_view .params p a {
  color: white;
}
.bridge_view .params p a {
  transition: all 0.2s ease;
  text-decoration: underline;
}
.bridge_view .params p a:hover {
  text-decoration: none;
}
.bridge_view .bridge_map {
  margin-bottom: 30px;
}

.jubilee-flag {
  background: url(../img/flag3.png) top center no-repeat !important;
}
.bridge_view .boggers-flag.jubilee-flag span:not(.discr).laureat {
  background: url(../img/laureat.png) center 15px no-repeat !important;
  height: 80px;
  padding-top: 40px;
  display: block;
  margin: 0 auto;
  width: 90px;
  font-size: 13px;
  line-height: 1.2em;
  font-family: 'open_sanslight', sans-serif;
  color: #fff;
}
.bridge_view .boggers-flag.jubilee-flag span:not(.discr).laureat b {
  text-align: center;
  display: block;
  color: #222a32;
  font-family: 'open_sansextrabold', sans-serif;
  font-size: 25px;
  line-height: 23px;
}

.bridge_view .heading {
  margin: 0 50px;
}
.bridge_view .wysiwyg {
  margin: 0 50px 21px;
}
.bridge_view .wysiwyg h2 {
  font-size: 25px;
  font-family: 'open_sansbold', sans-serif;
  margin: 0 0 22px;
  line-height: 150%;
  text-transform: uppercase;
}
.bridge_view .gallery {
  padding: 50px 0 40px;
}
.bridge_view .gallery a {
  display: block;
  margin-bottom: 10px;
}
.bridge_view .gallery a img {
  width: 100%;
}

.bridge_view .view_buttons {
  top: 13px;
}
.bridge_view .view_buttons .wr {
  padding: 0 110px;
}

.bridge_view.bridge_view_construction {
  hr {
    background: url(../img/sep4.png) bottom repeat-x;
    border: none;
    margin: 0 0 25px;
  }

  .main-news {
    padding: 20px 0 80px;
  }

  .bridge_map {
    margin-bottom: 0;
  }

  .pagination {
    padding: 27px 0 8px;

    a.btn_prev {
      left: 0;
    }

    a.btn_next {
      right: 0;
    }
  }
}

.bridge_slider-other,
.bridge_slider-news {
  margin-bottom: 30px;
}
.bridge_slider-other .slick-prev,
.bridge_slider-other .slick-next,
.bridge_slider-news .slick-prev,
.bridge_slider-news .slick-next {
  background: #ffffff url(../img/arr_d@m.png) center no-repeat;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  z-index: 5;
}
.bridge_slider-other .slick-prev,
.bridge_slider-news .slick-prev {
  left: -20px;
  background-position: 19px center;
}
.bridge_slider-other .slick-next,
.bridge_slider-news .slick-next {
  right: -20px;
  transform: rotate(-180deg);
  margin-top: -20px;
  background-position: 18px center;
}

.bridge_slider-news img {
  display: inline-block;
}
.bridge_slider-news a {
  display: block;
  text-align: center;
}

.bridge_list {
  min-height: 750px;
}
.bridge_list .wr {
  position: relative;
}
.bridge_list hr {
  margin: 0 0 25px;
  background: url(../img/sep4.png) bottom repeat-x;
  border: none;
}
.bridge_list .head {
  margin-bottom: 32px !important;
  position: relative;
  padding-right: 400px;
}
@media screen and (max-width: 1000px) {
  .bridge_list .head {
    padding-right: 0;
  }
}
.bridge_list .head h1 {
  display: inline;
  font-size: inherit;
}
@media screen and (max-width: 1000px) {
  .bridge_list .head h1 {
    text-align: center;
    display: block;
  }
}
.bridge_list .buttons_geay {
  position: absolute;
  right: 0;
  top: 14px;
}
.bridge_list.embankments .buttons_geay {
  z-index: 10;
  top: 1px;
}
.bridge_list.embankments .bridge_wrap {
  margin-top: 70px;
}
.bridge_list .buttons_geay.static {
  float: right;
  position: static;
}
@media screen and (max-width: 1000px) {
  .bridge_list .buttons_geay.static {
    float: none;
    text-align: center;
  }
}
@media only screen and (max-width: 1000px) {
  .bridge_list .buttons_geay {
    position: static;
    text-align: center;
  }
}
.bridge_list .buttons_geay a {
  display: inline-block;
  font-size: 13px;
  font-family: 'open_sansbold', sans-serif;
  text-decoration: none;
  padding: 0 22px 0 42px;
  height: 40px;
  line-height: 40px;
  margin: 0 5px 5px 0;
  position: relative;
  /*color: #a2a4a6;*/
  text-transform: uppercase;
  border-radius: 30px;
  /*background: #ebebeb;*/
  vertical-align: top;
  border: #ebebeb 2px solid;
  color: #000;
}
.bridge_list .buttons_geay a.current {
  color: #000;

  background: #ebebeb;
}

.bridge_list .buttons_geay a.current i {
  background-position: bottom;
}
.bridge_list .buttons_geay i {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 12px;
  background-image: url('../img/s_marker.png');
  background-position: top;
}
.bridge_list .buttons_geay i.bars {
  background-image: url('../img/s_menu.png');
}
.bridge_list .search_b {
  position: absolute;
  display: inline-block;
  z-index: 1;
}
.bridge_list .search_b i {
  width: 24px;
  height: 24px;
  font-size: 15px;
  background: #000;
  color: #fff;
  line-height: 23px;
  text-align: center;
  margin-left: -11px;
  border-radius: 20px;
  vertical-align: -2px;
  padding-top: 1px;
  box-sizing: border-box;
  margin-top: 2px;
  padding-right: 1px;
  margin-right: 5px;
}
.bridge_list .search_b .select {
  background: white;
  border: 2px solid #27aae0;
  width: 220px;
  position: relative;
  overflow: hidden;
  border-radius: 21px;
  display: inline-block;
  height: auto;
}
.bridge_list .search_b .select span {
  height: 30px;
  line-height: 28px;
  position: relative;
  display: block;
  padding: 0 30px 0 14px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-size: 12px;
  font-family: 'open_sanslight', sans-serif;
}
.bridge_list .search_b .select .options span {
  min-height: 30px;
  height: auto;
  padding: 7px 30px 7px 14px;
  line-height: 1.2em;
  box-sizing: border-box;
}
.bridge_list .search_b .select .options span:hover {
  color: white;
  background: #27aae0;
}
.bridge_list .search_b .select span:after {
  background-image: url(../img/arr_d_black.png);
  background-size: 10px 6px;
  content: '';
  display: block;
  position: absolute;
  right: 15px;
  top: 12px;
  width: 10px;
  height: 6px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  display: none;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .bridge_list .search_b .select span:after {
    background-image: url(../img/arr_d_black@2x.png);
  }
}
.bridge_list .search_b .select span.open {
  border: 0;
}
.bridge_list .search_b .select span.open:after {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.bridge_list .search_b .select .options,
.bridge_list .search_b .select .sub {
  display: none;
  padding: 10px 0 0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.bridge_list .search_b .select .options a,
.bridge_list .search_b .select .sub a {
  font-size: 12px;
  font-family: 'open_sanslight', sans-serif;
  display: block;
  height: auto;
  line-height: 16px;
  padding: 7px 14px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.bridge_list .search_b .select .options a:hover,
.bridge_list .search_b .select .sub a:hover {
  background: #27aae0;
  color: white;
}
.bridge_list .search_b .select .options {
  overflow-y: scroll;
  max-height: 280px;
  overflow-x: inherit;
}
.bridge_list .search_b .select .sub a {
  margin: 0 -4px;
}
.bridge_list .search_b input[type='text'] {
  border: none;
  font-size: 12px;
  padding: 3px 10px;
  width: 90%;
}
.bridge_list .search_b form.select i {
  font-size: 12px;
  vertical-align: 0;
  margin: 0;
  float: right;
  padding-right: 0;
}
.bridge_list .search_b form.select {
  padding: 3px;
  vertical-align: top;
  position: relative;
  top: 0;
  left: 0px;
  width: 260px;
}
.bridge_list .search_b .select button {
  position: absolute;
  z-index: 5;
  opacity: 0;
  right: 0;
  width: 25px;
  height: 25px;
}
.bridge_list.embankments .clearfix {
  margin-bottom: 20px;
}
.bridge_list.embankments .search_b {
  margin-top: 5px;
  margin-bottom: 10px;
}
.bridge_list.embankments .search_b form.select {
  position: relative;
  left: 0px;
}
.bridge_list.embankments .search_b form.select input {
  width: 88%;
}
.bridge_list.embankments .search_b .select {
  width: 230px;
}

.bridge_list.piers {
  min-height: 500px;
}
.bridge_list.piers-map {
  min-height: 750px;
}

.bridge_list.piers .search_b form.select input {
  width: 90%;
}
.bridge_list.piers .head {
  padding-right: 0;
}
.bridge_list.piers .search_b .select {
  width: 290px;
}

.bridge_list.piers .search_b form.select {
  width: 270px;
}
.bridge_list hr {
  clear: both;
}
.piers-btn {
  color: #fff;
  display: inline-block;
  font-size: 19px;
  font-family: 'open_sansregular', sans-serif;
  text-decoration: none;
  padding: 0 20px 0 53px;
  height: 40px;
  line-height: 40px;
  margin: 13px 5px 5px 0;
  position: relative;
  border-radius: 30px;
  background: #27aae0;
  vertical-align: top;
  float: right;
  transition: 0.5s;
}
.piers-btn:hover {
  background-color: #ebebeb;
  color: #000;
}
.piers-btn i.anchor {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 12px;
  background-position: top;
  background-image: url('../img/anchor.png');
}
.bridge_list.embankments h1.head {
  display: inline-block;
  padding-right: 40px;
}
.bridge_wrap a {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 32px;
}
.bridge_wrap a span.txt {
  display: block;
  padding: 14px 0 0;
  height: 34px;
  position: relative;
  overflow: hidden;
}
.bridge_wrap a img {
  max-width: 100%;
  border-radius: 10px;
}

.bridge_wrap {
  padding: 40px 0;
}
.bridge_wrap h2 {
  font-size: 25px;
  font-family: 'open_sansbold', sans-serif;
  margin: 0 0 22px;
  line-height: 150%;
  text-transform: uppercase;
}
.bridge_wrap h2:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 23px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.bridge_wrap.bridge_wrap_construction {
  padding: 17px 0 18px;

  .main-news {
    padding: 18px 0 46px;
  }
}

.bridge_wrap.bridge_wrap_construction a {
  margin-bottom: 56px;
}

.bridge_wrap.bridge_wrap_construction a span.img {
  display: block;
  margin-bottom: 20px;
}

.bridge_wrap.bridge_wrap_construction a img {
  width: 100%;
}

.bridge_wrap.bridge_wrap_construction a span.txt {
  text-align: left;
  padding: 0;
  padding-left: 50px;
  font-weight: 600;
  height: auto;
}

.bridge_wrap.bridge_wrap_construction a span.txt.txt_caption {
  font-size: 17px;
  background: url(../img/helmet@2x.png) 12px no-repeat;
  background-size: 22px 17px;
  line-height: 1.6;
  margin-bottom: 5px;
}

.bridge_wrap.bridge_wrap_construction a span.txt.txt_dates {
  background: url(../img/calendar@2x.png) 12px no-repeat;
  background-size: 21px 22px;
  line-height: 1.6;
}
.info-bridge {
  box-sizing: border-box;
  height: 34px;
  width: auto;
  background: #ffba00;
  top: -50px;
  left: 20px;
  padding-left: 10px;
  padding-right: 20px;
  white-space: nowrap;
  border-radius: 0 35px 35px 0;
  font: 10px/35px 'open_sansregular';
  color: #222a32;
  text-transform: uppercase;
  z-index: 3;
  position: absolute;
}
.info-bridge:before {
  height: 24px;
  width: 24px;
  background: #ffba00;
  position: absolute;
  top: 5px;
  left: -12px;
  content: '';
  z-index: 1;
  display: block;
  transform: rotate(45deg);
}
.info-bridge.min {
  width: 65px;
}
.info-bridge p {
  z-index: 3;
  position: relative;
  /*height: 34px;
    left: 0;*/
  font: 11px/35px 'open_sansregular';
  color: #222a32;
}

@media screen and (max-width: 1200px) {
  .bridge_view.bridge_view_construction {
    .main-news {
      padding: 20px 0 30px;
    }
  }
}
