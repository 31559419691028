.contacts .cont_info_blocks {
  margin-bottom: 40px;
}
.contacts .cont_info_blocks img {
  width: 100%;
}
.contacts .cont_info_blocks .img {
  position: relative;
  padding-top: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 10px;
}
.contacts .cont_info_blocks .img img {
  position: absolute;
  left: 0;
  top: 0;
}
.contacts .slick-slide {
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.contacts .slick-slide img {
  width: 100%;
}
.contacts .inf_block_one {
  position: relative;
  padding: 100% 0 0;
  color: white;
  margin-bottom: 10px;
  border-radius: 10px;
}
.contacts .inf_block_one p {
  font-size: 12px;
  line-height: 16px;
}
.contacts .inf_block_one p.bold {
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  margin-top: 12px;
}
.contacts .inf_block_one p.hd {
  position: absolute;
  top: 35px;
  left: 40px;
  font-size: 13px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
  text-transform: uppercase;
}
.contacts .inf_block_one p a {
  color: white;
}
.contacts .inf_block_one .txt_bottom {
  position: absolute;
  left: 40px;
  bottom: 33px;
}
.contacts .inf_block_one.bg_gray {
  color: #222a32;
}
.contacts .inf_block_one.bg_gray p,
.contacts .inf_block_one.bg_gray a {
  color: #222a32;
}
.contacts .inf_block_one .rhombus {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  display: block;
  margin: 0 0 0 -32px;
  position: absolute;
  left: 50%;
  top: 98px;
  width: 55px;
  height: 55px;
  position: absolute;
  text-align: center;
  line-height: 55px;
  font-size: 28px;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.contacts .inf_block_one .rhombus.white {
  border: 5px solid white;
}
.contacts .inf_block_one .rhombus.dark {
  border: 5px solid #222a32;
  color: #222a32;
}
.contacts .inf_block_one .rhombus.blue {
  border: 5px solid #27aae0;
}
.contacts .inf_block_one .rhombus.bg_blue {
  color: white !important;
}
.contacts .inf_block_one .rhombus i {
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.contacts .form {
  padding: 35px 0 52px;
  position: relative;
  overflow: hidden;
}
.contacts .map {
  position: relative;
  overflow: hidden;
  margin-bottom: -42px;
}

.contact-map {
  background: #fff;
  position: absolute;
  padding: 6px 10px 10px;
  font: 14px 'open_sansregular', sans-serif, serif;
  color: #000;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}
.contact-map .h {
  font-size: 17px;
  width: 300px;
  font-family: 'open_sanssemibold', sans-serif;
  margin-bottom: 5px;
  color: #27aae0;
}

.info_w {
  position: relative;
}
.info_w .inf_block_one {
  top: 56px;
  width: 290px;
  height: 290px;
  position: absolute;
  z-index: 5;
  padding: 20px;
  box-sizing: border-box;
}
.info_w .inf_block_one .rhombus {
  top: 74px;
  height: 53px;
  width: 53px;
}
.info_w .inf_block_one .rhombus i {
  padding-left: 3px;
}
.info_w .inf_block_one .txt_bottom {
  bottom: -5px;
}
.info_w .inf_block_one .txt_bottom p {
  margin-bottom: 10px;
}
.info_w .inf_block_one .txt_bottom p strong {
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  margin-top: 12px;
  font-weight: normal;
  display: block;
  margin-bottom: -8px;
}
