.job_list {
  margin: -13px 0 22px;
}
.job_list .doc_slide {
  padding: 0;
}
.job_list .bg_gray {
  padding: 46px;
}
.job_list .button {
  height: 34px;
  padding: 8px 18px;
  background: #27aae0;
  font: 13px 'open_sansbold', sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin: 20px 0 50px;
  box-sizing: border-box;
}
.job_list .job_list--title h1 {
  font-size: 25px;
  font-family: 'open_sanssemibold', sans-serif;
  margin: 0;
}
.job_list .job_list--title span {
  display: block;
  color: #27aae0;
  font-size: 20px;
  font-family: 'open_sansbold', sans-serif;
  margin: 5px 0 19px;
}
