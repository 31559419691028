@font-face {
  font-family: 'open_sansbold';
  src: url('../fonts/opensans-bold-webfont.eot');
  src: url('../fonts/opensans-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-bold-webfont.woff2') format('woff2'),
    url('../fonts/opensans-bold-webfont.woff') format('woff'),
    url('../fonts/opensans-bold-webfont.ttf') format('truetype'),
    url('../fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansbold_italic';
  src: url('../fonts/opensans-bolditalic-webfont.eot');
  src: url('../fonts/opensans-bolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-bolditalic-webfont.woff2') format('woff2'),
    url('../fonts/opensans-bolditalic-webfont.woff') format('woff'),
    url('../fonts/opensans-bolditalic-webfont.ttf') format('truetype'),
    url('../fonts/opensans-bolditalic-webfont.svg#open_sansbold_italic')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansextrabold';
  src: url('../fonts/opensans-extrabold-webfont.eot');
  src: url('../fonts/opensans-extrabold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/opensans-extrabold-webfont.woff') format('woff'),
    url('../fonts/opensans-extrabold-webfont.ttf') format('truetype'),
    url('../fonts/opensans-extrabold-webfont.svg#open_sansextrabold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansextrabold_italic';
  src: url('../fonts/opensans-extrabolditalic-webfont.eot');
  src: url('../fonts/opensans-extrabolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-extrabolditalic-webfont.woff2') format('woff2'),
    url('../fonts/opensans-extrabolditalic-webfont.woff') format('woff'),
    url('../fonts/opensans-extrabolditalic-webfont.ttf') format('truetype'),
    url('../fonts/opensans-extrabolditalic-webfont.svg#open_sansextrabold_italic')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansitalic';
  src: url('../fonts/opensans-italic-webfont.eot');
  src: url('../fonts/opensans-italic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-italic-webfont.woff2') format('woff2'),
    url('../fonts/opensans-italic-webfont.woff') format('woff'),
    url('../fonts/opensans-italic-webfont.ttf') format('truetype'),
    url('../fonts/opensans-italic-webfont.svg#open_sansitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanslight';
  src: url('../fonts/opensans-light-webfont.eot');
  src: url('../fonts/opensans-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-light-webfont.woff2') format('woff2'),
    url('../fonts/opensans-light-webfont.woff') format('woff'),
    url('../fonts/opensans-light-webfont.ttf') format('truetype'),
    url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanslight_italic';
  src: url('../fonts/opensans-lightitalic-webfont.eot');
  src: url('../fonts/opensans-lightitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-lightitalic-webfont.woff2') format('woff2'),
    url('../fonts/opensans-lightitalic-webfont.woff') format('woff'),
    url('../fonts/opensans-lightitalic-webfont.ttf') format('truetype'),
    url('../fonts/opensans-lightitalic-webfont.svg#open_sanslight_italic')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansregular';
  src: url('../fonts/opensans-regular-webfont.eot');
  src: url('../fonts/opensans-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
    url('../fonts/opensans-regular-webfont.woff') format('woff'),
    url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
    url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanssemibold';
  src: url('../fonts/opensans-semibold-webfont.eot');
  src: url('../fonts/opensans-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
    url('../fonts/opensans-semibold-webfont.woff') format('woff'),
    url('../fonts/opensans-semibold-webfont.ttf') format('truetype'),
    url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanssemibold_italic';
  src: url('../fonts/opensans-semibolditalic-webfont.eot');
  src: url('../fonts/opensans-semibolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/opensans-semibolditalic-webfont.woff2') format('woff2'),
    url('../fonts/opensans-semibolditalic-webfont.woff') format('woff'),
    url('../fonts/opensans-semibolditalic-webfont.ttf') format('truetype'),
    url('../fonts/opensans-semibolditalic-webfont.svg#open_sanssemibold_italic')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'akrobat_black';
  src: url('../fonts/akrobat/Akrobat-Black.otf');
  font-weight: black;
  font-style: normal;
}
@font-face {
  font-family: 'akrobat_regular';
  src: url('../fonts/akrobat/Akrobat-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'akrobat_bold';
  src: url('../fonts/akrobat/Akrobat-Bold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'clearsans_bold';
  src: url('../fonts/clearsans/ClearSans-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'clearsans_regular';
  src: url('../fonts/clearsans/ClearSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'clearsans_italic';
  src: url('../fonts/clearsans/ClearSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'clearsans_bolditalic';
  src: url('../fonts/clearsans/ClearSans-BoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

.wysiwyg h1.timetable_head {
  text-align: center;
  font-size: 18px;
  font-family: 'open_sansextrabold', sans-serif;
  margin: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.wysiwyg h1.timetable_head:after {
  display: none;
}
.wysiwyg .timetable_text p {
  font-size: 17px;
  margin-bottom: 10px;
}
