// Новые блоки
.main-intro {
  position: relative;
  padding: 20px 0;
  overflow: hidden;

  &__slider {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .slider {
      height: 100%;
      width: 100%;

      .slick-list,
      .slick-track {
        height: 100%;
      }

      .slick-slide {
        & > div {
          height: 100%;
        }
      }
    }

    .slide {
      height: 100%;

      &__image {
        position: relative;
        height: 100%;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__plate {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'time time' 'today app';
    gap: 10px;
    position: relative;
    width: 100%;
    max-width: 590px;

    & > .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px;
      border-radius: 10px;
      position: relative;

      .item__title {
        font-family: 'open_sansbold', sans-serif;
        font-size: 17px;
        color: #222a32;
        margin-bottom: 28px;
        width: 100%;
      }

      .item__link {
        position: absolute;
        right: 9px;
        top: 11px;
        width: 22px;
        height: 22px;
        background-image: url(../img/arrow_t_r_black.png);
      }

      .item__content {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 22px;
        gap: 15px;

        .date {
          font-family: 'open_sansextrabold', sans-serif;
          font-size: 30px;
          line-height: 0.8;

          span {
            display: block;
            font-family: 'open_sansregular', sans-serif;
            font-size: 11px;
            text-align: center;
            margin-top: 5px;
          }
        }

        .text {
          font-size: 14px;
          line-height: 1.4;

          p {
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }

      .item__btns {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: auto;

        a {
          font-size: 10px;
          font-family: 'open_sanssemibold', sans-serif;
          text-transform: uppercase;
          border: 2px solid #fff;
          border-radius: 100px;
          color: #fff;
          padding: 7px 13px;
          padding-left: 36px;

          &.map {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 12px;
              top: 4px;
              width: 14px;
              height: 18px;
              background-image: url(../img/placemark.png);
              background-size: 14px 18px;

              @media only screen and (-moz-min-device-pixel-ratio: 1.5),
                only screen and (-o-min-device-pixel-ratio: 3 / 2),
                only screen and (-webkit-min-device-pixel-ratio: 1.5),
                only screen and (min-device-pixel-ratio: 1.5) {
                background-image: url(../img/placemark@2x.png);
              }
            }
          }

          &.list {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 12px;
              top: 7px;
              width: 14px;
              height: 12px;
              background-image: url(../img/bars.png);
              background-size: 14px 12px;

              @media only screen and (-moz-min-device-pixel-ratio: 1.5),
                only screen and (-o-min-device-pixel-ratio: 3 / 2),
                only screen and (-webkit-min-device-pixel-ratio: 1.5),
                only screen and (min-device-pixel-ratio: 1.5) {
                background-image: url(../img/bars@2x.png);
              }
            }
          }

          &.today {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 10px;
              top: 5px;
              width: 18px;
              height: 16px;
              background-image: url(../img/alarm.png);
              background-size: 18px 16px;

              @media only screen and (-moz-min-device-pixel-ratio: 1.5),
                only screen and (-o-min-device-pixel-ratio: 3 / 2),
                only screen and (-webkit-min-device-pixel-ratio: 1.5),
                only screen and (min-device-pixel-ratio: 1.5) {
                background-image: url(../img/alarm@2x.png);
              }
            }
          }

          &.android {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 11px;
              top: 4px;
              width: 16px;
              height: 18px;
              background-image: url(../img/android.png);
              background-size: 16px 18px;

              @media only screen and (-moz-min-device-pixel-ratio: 1.5),
                only screen and (-o-min-device-pixel-ratio: 3 / 2),
                only screen and (-webkit-min-device-pixel-ratio: 1.5),
                only screen and (min-device-pixel-ratio: 1.5) {
                background-image: url(../img/android@2x.png);
              }
            }
          }

          &.ios {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 12px;
              top: 4px;
              width: 14px;
              height: 16px;
              background-image: url(../img/apple.png);
              background-size: 14px 16px;

              @media only screen and (-moz-min-device-pixel-ratio: 1.5),
                only screen and (-o-min-device-pixel-ratio: 3 / 2),
                only screen and (-webkit-min-device-pixel-ratio: 1.5),
                only screen and (min-device-pixel-ratio: 1.5) {
                background-image: url(../img/apple@2x.png);
              }
            }
          }
        }
      }

      .item__select {
        width: 100%;
        margin-bottom: 22px;
        // height: auto;

        .select__head {
          margin-bottom: 12px;
          position: relative;

          .select2-container--default {
            .select2-selection--single {
              border-radius: 100px;
              height: 50px;
              overflow: hidden;

              .select2-selection__rendered {
                font-family: 'open_sanssemibold', sans-serif;
                font-size: 25px;
                line-height: 50px;
                padding-left: 20px;
              }

              .select2-selection__arrow {
                top: 13px;
                right: 16px;

                b {
                  border: none;
                  background: url(../img/arr_d_black.png) no-repeat;
                  width: 10px;
                  height: 6px;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
            }

            .select2-results {
              & > .select2-results__options {
                scrollbar-width: thin;
                scrollbar-color: #27aae0 #e8e9ea;

                &::-webkit-scrollbar {
                  height: 6px;
                  width: 6px;
                }
                &::-webkit-scrollbar-track {
                  background: #e8e9ea;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #27aae0;
                  border-radius: 5px;
                }
              }
            }

            .select2-results__option--highlighted[aria-selected] {
              background-color: #27aae0 !important;
            }
          }

          .select2-container--open {
            left: 0 !important;
            top: 100% !important;

            .select2-dropdown--below {
              border-radius: 10px !important;
            }
          }
        }

        .select__content {
          padding-right: 20px;
          padding-left: 12px;

          .item {
            display: none;
          }

          .elem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0 10px;

            &:not(:last-child) {
              margin-bottom: 7px;
            }

            .key {
              font-size: 17px;
            }

            .value {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              font-family: 'open_sansbold', sans-serif;
              font-size: 20px;

              p {
                font-size: 20px;
              }

              &_timer {
                font-size: 35px;
              }
            }
          }
        }
      }

      &.item_time {
        grid-area: time;
        background-color: fade(#fff, 40%);
        backdrop-filter: blur(6px);
        padding-right: 20px;
        padding-left: 20px;
        position: relative;
        z-index: 2;

        .item__title {
          padding-left: 13px;
        }

        .item__btns {
          padding-left: 12px;

          a {
            color: #222a32;
          }
        }
      }

      &.item_today {
        grid-area: today;
        background-color: #27aae0;

        .item__link {
          filter: brightness(0) invert(1);
        }

        .item__content {
          .text {
            color: #fff;
          }
        }
      }

      &.item_app {
        grid-area: app;
        background-color: #ff9600;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-intro {
    &__plate {
      max-width: 536px;

      & > .item {
        padding: 20px;

        .item__title {
          font-size: 15px;
          margin-bottom: 14px;
        }

        .item__select {
          width: 100%;
          margin-bottom: 14px;

          .select__head {
            margin-bottom: 12px;

            .select2-container--default {
              .select2-selection--single {
                height: 40px;

                .select2-selection__rendered {
                  font-size: 20px;
                  line-height: 40px;
                  padding-left: 15px;
                }

                .select2-selection__arrow {
                  top: 8px;
                }
              }
            }
          }

          .select__content {
            padding-left: 0;
            padding-right: 0;

            .elem {
              .key {
                font-size: 15px;
              }
              .value {
                font-size: 17px;

                p {
                  font-size: 17px;
                }

                &_timer {
                  font-size: 30px;
                }
              }
            }
          }
        }

        &.item_time {
          .item__title {
            padding-left: 0;
          }

          .item__btns {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .main-intro {
    &__plate {
      & > .item {
        .item__select {
          .select__head {
            .select2-container--default {
              .select2-selection--single {
                height: 35px;

                .select2-selection__rendered {
                  font-size: 17px;
                  line-height: 35px;
                }

                .select2-selection__arrow {
                  top: 6px;
                  right: 8px;
                }
              }
            }
          }

          .select__content {
            .elem {
              .value {
                font-size: 15px;

                p {
                  font-size: 15px;
                }

                &_timer {
                  font-size: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .main-intro {
    &__plate {
      grid-template-columns: 1fr;
      grid-template-areas: 'time' 'today' 'app';
    }
  }
}
