.timetable {
  height: 560px;
  position: relative;
  overflow: hidden;
}
.timetable .wysiwyg {
  margin-top: 30px;
  text-align: center;
}
.timetable .wysiwyg * {
  color: white;
}
.timetable .bg {
  background-image: url(../img/saint_p2.png);
  background-size: 1600px 560px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  top: 0;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .timetable .bg {
    background-image: url(../img/saint_p2@2x.png);
  }
}
.timetable .wr {
  position: relative;
  z-index: 1;
}
.timetable .time_list {
  margin: 0 18px 75px 178px;
  padding: 69px 0 0;
}
.timetable .time_list:after {
  content: '';
  clear: both;
  clear: both;
}
.timetable .time_list .time_block {
  float: left;
  width: 16.66666667%;
  position: relative;
  min-height: 1px;
}
.timetable .time_list span {
  display: block;
  position: absolute;
}
.timetable .time_list span.t1 {
  font-size: 20px;
  font-family: 'open_sansbold', sans-serif;
  color: white;
  left: 0;
}
.timetable .time_list span.t1:after {
  background: white;
  content: '';
  display: block;
  position: absolute;
  width: 3px;
  height: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  bottom: -15px;
  left: 3px;
}
.timetable .time_list span.t1.last {
  left: auto;
  right: 0;
}
.timetable .time_list span.t2 {
  font-size: 12px;
  color: #27aae0;
  left: 50%;
  width: 20px;
  text-align: center;
  margin-left: -10px;
}
.timetable .time_list span.t2:after {
  background: #27aae0;
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  height: 7px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  bottom: -17px;
  left: 10px;
}
.timetable .bridge {
  margin: 0 0 17px;
}
.timetable .bridge:after {
  content: '';
  clear: both;
  display: block;
}
.timetable .bridge .name {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
  float: left;
  width: 180px;
  position: relative;
  text-transform: uppercase;
}
.timetable .bridge .line_wrapper {
  margin: 0 18px 0 182px;
  position: relative;
  top: 7px;
}
.timetable .bridge .line_wrapper .line {
  position: absolute;
  height: 3px;
}
.timetable .bridge .line_wrapper .line.l_left {
  left: 0;
}
.timetable .bridge .line_wrapper .line.l_left:before {
  background: rgba(255, 255, 255, 0.3);
  content: '';
  height: 3px;
  position: absolute;
  left: 0;
  right: 25px;
  display: block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.timetable .bridge .line_wrapper .line.l_right {
  right: 0;
}
.timetable .bridge .line_wrapper .line.l_right:before {
  background: rgba(255, 255, 255, 0.3);
  content: '';
  height: 3px;
  position: absolute;
  left: 25px;
  right: 0;
  display: block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.timetable .bridge .line_wrapper .line_cur {
  position: absolute;
  height: 3px;
}
.timetable .bridge .line_wrapper .line_cur:before {
  background: #27aae0;
  content: '';
  height: 3px;
  position: absolute;
  left: 25px;
  right: 25px;
  display: block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.timetable .bridge .line_wrapper .line_cur span {
  width: 50px;
  position: absolute;
  text-align: center;
  font-size: 15px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
  top: -9px;
}
.timetable .bridge .line_wrapper .line_cur span.s1 {
  left: -25px;
}
.timetable .bridge .line_wrapper .line_cur span.s2 {
  right: -25px;
}
.timetable .bridge .line_wrapper .line_cur_sec {
  position: absolute;
  height: 3px;
}
.timetable .bridge .line_wrapper .line_cur_sec span {
  width: 50px;
  position: absolute;
  text-align: center;
  font-size: 15px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
  top: -9px;
}
.timetable .bridge .line_wrapper .line_cur_sec span.s1 {
  left: -25px;
}
.timetable .bridge .line_wrapper .line_cur_sec span.s2 {
  right: -25px;
}
.timetable .bridge .line_wrapper .line_cur_sec .line.l_left:before {
  background: #27aae0;
  left: 25px;
}
.timetable .bridge .line_wrapper .line_cur_sec .line.l_right:before {
  background: #27aae0;
  right: 25px;
}
.timetable .bridge .line_wrapper .line_cur_sec_wrapper {
  position: absolute;
  height: 3px;
  min-width: 53px;
}
.timetable .bridge .line_wrapper .line_cur_sec_wrapper:before {
  background: rgba(255, 255, 255, 0.3);
  content: '';
  height: 3px;
  position: absolute;
  left: 25px;
  right: 25px;
  display: block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.timetable .time_tracker {
  margin: 0 18px 0 182px;
  position: relative;
}
.timetable .time_tracker .tracker {
  background: url(../img/tracker_dots.png) repeat-y;
  width: 1px;
  position: absolute;
  padding: 42px 0 0;
  bottom: -6px;
  left: 0;
}
.timetable .time_tracker .tracker:before {
  background: #27aae0;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -2px;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.timetable .time_tracker .tracker:after {
  background: #27aae0;
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: -2px;
  width: 5px;
  height: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.timetable .time_tracker .tracker span {
  position: absolute;
  font-size: 13px;
  font-family: 'open_sansbold', sans-serif;
  width: 30px;
  left: -15px;
  bottom: -22px;
  text-align: center;
  color: white;
}
