.jubilee header {
  background: url(../img/cream_bg.png) repeat;
  z-index: 11;
}
.jubilee .header__bottom {
  top: 4px;
}
.jubilee .mob_m {
  background: #80a0a7;
}
.jubilee .header__top {
  background: url(../img/blue_bg.png) repeat;
}
.jubilee__head {
  font-family: 'Gabriela', serif;
  color: #e6a628;
  text-shadow: 0 3px 2px rgba(0, 0, 0, 0.45);
  line-height: 95px;
  font-size: 110px;
}
.jubilee__main {
  padding: 65px 0;
  background: url(../img/cream_bg.png) repeat;
}
.jubilee__top {
  position: relative;
  background: url(../img/jubilee-top.jpg) center no-repeat;
  height: 580px;
  box-sizing: border-box;
  background-size: cover;
  text-align: center;
  padding: 100px 0;
}
.jubilee__top:before,
.jubilee__top:after {
  position: absolute;
  left: 0;
  top: -17px;
  width: 100%;
  height: 20px;
  content: '';
  z-index: 10;
  background: url(../img/jubilee-wrap.png) repeat-x;
}
.jubilee__top:after {
  top: auto;
  bottom: -17px;
}
.jubilee__about {
  text-align: center;
  font-family: 'Georgia', 'Tahoma', sans-serif;
}
.jubilee__head {
  padding-bottom: 45px;
}
.jubilee__top p {
  font-size: 17px;
  line-height: 30px;
  color: #fff;
  font-style: italic;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.65);
  margin-bottom: 20px;
}
.jubilee__sep {
  background: url(../img/jubilee-hr.png) center no-repeat;
  border: none;
  height: 22px;
  margin: 45px 0 35px;
}
.jubilee__item:after {
  content: '';
  display: block;
  clear: both;
}
.jubilee__text {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 495px);
  padding-right: 65px;
  box-sizing: border-box;
}
.jubilee__text h2 {
  color: #5c97a8;
  font-size: 50px;
  font-family: 'Gabriela', serif;
  font-weight: 400;
  line-height: 1.2em;
  margin-bottom: 20px;
  margin-top: 0;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
.jubilee__text h2.m--yellow {
  color: #e5a629;
}
.jubilee__text p {
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-size: 18px;
  line-height: 35px;
  color: #1e2627;
}
.jubilee__image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 490px;
  border: 26px solid #eeefea;
  box-sizing: border-box;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
}
.jubilee__image_wrap {
  border: 1px dashed #d0ccc1;
}
.jubilee__image:after,
.jubilee__current_image:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url(../img/scratches.png);
}
.jubilee__current_image:after {
  background-size: cover;
}
.jubilee__image img,
.jubilee__current_image img {
  position: relative;
  display: block;
  border: 14px solid #fff;
  width: 100%;
  box-sizing: border-box;
  filter: saturate(20%) sepia(30%) hue-rotate(-20deg);
}
.jubilee__item:nth-child(even) .jubilee__text {
  padding-right: 0;
  padding-left: 65px;
}
.jubilee__item {
  margin-bottom: 30px;
}
.jubilee__current {
  position: relative;
  padding: 40px 0 25px;
  background: url(../img/blue_bg.png) repeat;
  z-index: 1;
}
.jubilee__current:before,
.jubilee__current:after {
  background: url(../img/jubilee-wrap_blue.png) repeat-x;
  width: 100%;
  position: absolute;
  content: '';
  height: 12px;
  top: -2px;
  left: 0;
  z-index: 0;
}
.jubilee__current:after {
  top: auto;
  bottom: -2px;
}
.jubilee__current h2 {
  font-family: 'Gabriela', serif;
  font-weight: 400;
  margin: 0;
  font-size: 50px;
  line-height: 1.2em;
  margin-bottom: 25px;
  text-align: center;
  color: #fff;
  text-shadow: 0 3px rgba(0, 0, 0, 0.2);
}
.jubilee__current h2.m--inner {
  font-size: 40px;
}

.jubilee__current h2.m--yellow {
  color: #e6a628;
}
.jubilee__current_item {
  position: relative;
  width: 25%;
  box-sizing: border-box;
  text-align: center;
  float: left;
  border-right: 1px dashed #fff;
  margin-top: 33px;
  margin-bottom: 33px;
}
.jubilee__current_item:after {
  position: absolute;
  bottom: -33px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #fff;
}
.jubilee__current_item:nth-child(4n) {
  border-right: none;
}
.jubilee__current_list {
  position: relative;
}
.jubilee__current_list:before {
  content: '';
  position: absolute;
  z-index: 10;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: url(../img/blue_bg.png) 5px repeat;
}
.jubilee__current_list:after {
  content: '';
  display: block;
  clear: both;
}
.jubilee__current_image {
  position: relative;
  overflow: hidden;
  border: 9px solid #eeefea;
  box-sizing: border-box;
  width: 180px;
  margin: 0 auto;
  border-radius: 130px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1) translate3d(0, 0, 0);
  transition: border 0.15s ease;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.jubilee__current_image_wrap {
  border: 1px dashed #d3d0c6;
  box-sizing: border-box;
  border-radius: 130px;
  background: #fff;
}
.jubilee__current_item:hover .jubilee__current_image {
  border: 1px solid #fff;
}
.jubilee__current_image img {
  box-sizing: border-box;
  border: 5px solid #fff;
  border-radius: 130px;
  width: 100%;
}
.jubilee__current_image:after {
  top: -9px;
  left: -9px;
  width: 100%;
  height: 100%;
}
.jubilee__icon {
  color: #544039;
  margin-top: 20px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  font-size: 14px;
}
.jubilee__current_item h3 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 1.2em;
  font-size: 20px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-weight: 400;
}
.jubilee__current_date {
  font-size: 13px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  color: #fff;
  margin: 6px 0;
}
.jubilee__current_time {
  font-size: 15px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  color: #544039;
  font-weight: 700;
}
.jubilee__links {
  text-align: center;
  margin-top: 30px;
}
.jubilee__jinks_item {
  border: 1px solid #fff;
  border-radius: 100px;
  padding: 6px 15px;
  font-family: 'Gabriela', serif;
  font-size: 15px;
  color: #fff;
  display: inline-block;
  margin-bottom: 5px;
}
.jubilee__jinks_item:hover {
  background: rgba(255, 255, 255, 0.3);
}
.jubilee__calendar {
  position: relative;
  /* //border-top: 2px solid #fff; */
  padding: 40px 0 60px;
  background: url(../img/calendar_bg.jpg) repeat;
  /* // margin-bottom: -20px; */
}
.jubilee .content {
  padding-bottom: 0;
}
.jubilee__calendar.m--top {
  border-top: 0;
}
.jubilee__calendar.m--top:before {
  position: absolute;
  left: 0;
  top: -17px;
  width: 100%;
  height: 20px;
  content: '';
  z-index: 10;
  background: url(../img/jubilee-wrap.png) repeat-x;
}
.jubilee__calendar h2 {
  font-family: 'Gabriela', serif;
  font-weight: 400;
  margin: 0;
  font-size: 40px;
  line-height: 1.2em;
  margin-bottom: 50px;
  text-align: center;
  color: #544039;
  text-shadow: 0 3px rgba(0, 0, 0, 0.2);
}
.jubilee__years_wrap {
  width: 100%;
  position: relative;
  margin-top: -15px;
}
.jubilee__years_wrap:before {
  content: '';
  display: block;
  position: absolute;
  background: #e3d6ca;
  height: 1px;
  width: 100%;
  top: 38px;
}
.jubilee__years.slick-slider {
  height: 75px;
}
.jubilee__years .slick-prev,
.jubilee__years .slick-next {
  position: absolute;
  height: 40px;
  width: 40px;
  background: url(../img/arr_brown.png) center no-repeat, #fffbf4;
  border-radius: 100px;
  border: 1px solid #c8ad98;
  display: inline-block !important;
  left: -15px;
  margin-right: -4px;
  vertical-align: middle;
  margin-top: 20px;
  margin-top: 38px;
  top: 00px;
}
.jubilee__calendar h2.jubilee__stars {
  font-size: 55px;
  line-height: 95px;
}
.jubilee__stars:before,
.jubilee__stars:after {
  content: '';
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/bridge_sep.png) center no-repeat;
  height: 20px;
}
.jubilee__years .slick-next {
  background: url(../img/arr_brown_right.png) center no-repeat, #fffbf4;
  right: -15px;
  left: auto;
}
.jubilee__years .slick-list {
  width: calc(100% - 90px);
  margin-right: -4px;
  margin-left: -4px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.jubilee__years_item {
  text-align: center;
  vertical-align: middle;
}
.jubilee__years_item span {
  position: relative;
  width: 64px;
  height: 64px;
  background: #fffbf4;
  border: 1px solid #c7ab96;
  border-radius: 100px;
  display: block;
  line-height: 64px;
  margin: 4px auto;
  font-family: 'Gabriela', serif;
  text-shadow: 0 4px rgba(0, 0, 0, 0.15);
  color: #544039;
  cursor: pointer;
}
.jubilee__years_item span:before {
  content: '';
  position: absolute;
  left: -4px;
  top: -4px;
  width: 70px;
  height: 70px;
  background: #fffbf4;
  border: 1px solid #c7ab96;
  border-radius: 100px;
  z-index: -1;
}
.jubilee__years_item.m--active span {
  position: relative;
  background: #8db3bc;
  border-color: #8db3bc;
  color: #fff;
}
.jubilee__years_item.m--active span:before {
  border-color: #8db3bc;
}
.jubilee__years_item.m--active:hover span:before {
  background: #fff;
}
.jubilee__years_item:hover span:before {
  background: #8db3bc;
}
.jubilee__years_item.m--disabled span {
  background: transparent;
  border-color: transparent;
  opacity: 0.5;
  cursor: default;
}
.jubilee__years_item.m--disabled span:before {
  background: url(../img/btn_disabled.png);
  border-color: transparent;
}

.jubilee .header__top .header__top-mail i,
.jubilee .header__top .header__top-phone i {
  filter: brightness(30%);
}
/* .jubilee__years_item.m--active span:after{
  content: "";
  position: absolute;
  left: 34px;
  bottom: -6px;
  height: 16px;
  width: 1px;
  border-right: 1px dashed #c7ab96;
  } */
.jubilee__months {
  text-align: center;
  margin-top: 25px;
}
.jubilee__months_item {
  width: 82px;
  display: inline-block;
  text-transform: uppercase;
  color: #544039;
  font-size: 12px;
  background: #f7f2eb;
  text-align: center;
  border: 1px solid #e5d8ce;
  border-radius: 100px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  padding: 8px 0;
  opacity: 0.5;
  cursor: pointer;
  margin-bottom: 5px;
}
.jubilee__months_item:hover {
  opacity: 1;
}
.jubilee__months_item.m--disabled {
  background: url(../img/btn_disabled.png) repeat;
  border-color: transparent;
}
.jubilee__months_item.m--disabled:hover {
  cursor: default;
  opacity: 0.5;
}
.jubilee__months_item.m--active {
  background: #fff;
  opacity: 1;
  border: 1px solid #c7ab96;
}
.jubilee .footer__top {
  position: relative;
  z-index: 1;
  background: #181f20;
}
/* .jubilee .footer__top:before{
  content: "";
  position: absolute;
  background: url(../img/jubilee-wrap_black.png) repeat-x;
  height: 12px;
  width: 100%;
  top: -2px;
  left: 0;
  z-index: 0;
  } */
.jubilee .pr_link:before,
.jubilee .siluet i {
  filter: grayscale(70%);
}
.jubilee .copyright span,
.jubilee footer .footer__top p:last-child a {
  color: #8db3bc;
}
.jubilee footer .footer__top p:last-child a:after {
  background: #8db3bc;
}
.jubilee footer {
  background: #1e2627;
}
.jubilee .header__middle .logo {
  background: url(../img/logo-jubilee.png) no-repeat;
  width: 100px;
  height: 99px;
  margin: 11px 0 0 1px;
}
.jubilee .header__middle .menu li,
.jubilee .header__middle .menu a {
  color: #54413a;
}
.jubilee .header__middle .menu li a i,
.jubilee .header__middle .menu li span i {
  filter: grayscale(70%);
}
.jubilee .header__middle .menu li a.current,
.jubilee .header__middle .menu li span.current {
  color: #80a0a7;
}
.jubilee .header__middle .menu .sub {
  background: #80a0a7;
}
.jubilee .header__top-spec {
  background: url(../img/eye-blue.png) no-repeat 10px 6px, #54413a;
  filter: grayscale(70%);
  color: #fff;
}
.jubilee .header__top .header__top-lang a {
  background: #80a0a7;
  color: #fff;
}
.jubilee .header__top .header__top-lang a.current {
  background: #e6a628;
}
.jubilee footer p span,
.jubilee footer p span a {
  color: #8db3bc !important;
}
.jubilee__text p a {
  color: #5c97a8;
}
.jubilee__bridge {
  position: relative;
  padding: 40px 0;
  background: url(../img/calendar_bg.jpg) repeat;
}
.jubilee__bridge:before,
.jubilee__bridge:after {
  position: absolute;
  left: 0;
  top: -17px;
  width: 100%;
  height: 20px;
  content: '';
  z-index: 10;
  background: url(../img/jubilee-wrap.png) repeat-x;
}
.jubilee__bridge:after {
  top: auto;
  bottom: -17px;
}
.jubilee__bridge h1 {
  font-family: 'Gabriela', serif;
  font-weight: 400;
  margin: 0;
  font-size: 20px;
  line-height: 1.2em;
  text-align: center;
  font-size: 55px;
  line-height: 95px;
  color: #544039;
  text-shadow: 0 3px rgba(0, 0, 0, 0.2);
}
.jubilee__bridge .jubilee__sep {
  background: url(../img/bridge_sep.png) center no-repeat;
  height: 20px;
  margin: 10px 0 20px;
}
.jubilee__bridge p {
  text-align: center;
  font-size: 20px;
  line-height: 35px;
  font-style: italic;
  color: #544039;
}
.jubilee__bridge_image {
  position: relative;
  width: 100%;
  border: 26px solid #eeefea;
  box-sizing: border-box;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
  margin: 45px 0;
}
.jubilee__bridge_image_wrap {
  border: 1px dashed #d0ccc1;
}
.jubilee__bridge_image_wrap img {
  position: relative;
  display: block;
  border: 14px solid #fff;
  width: 100%;
  box-sizing: border-box;
  filter: saturate(20%) sepia(30%) hue-rotate(-20deg);
}
.jubilee__content h2 {
  font-size: 50px;
  font-family: 'Gabriela', serif;
  font-weight: 400;
  margin: 0 0 30px;
  color: #5c97a8;
  text-shadow: 0 3px rgba(0, 0, 0, 0.2);
}
.jubilee__content h2.m--yellow {
  color: #e6a628;
}
.jubilee__content p {
  font-size: 18px;
  line-height: 35px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  margin-bottom: 40px;
  color: #1e2627;
}
.jubilee .header__middle .menu .sub:before {
  border-color: transparent transparent #80a0a7 transparent;
}
.jubilee__video_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.jubilee__video_wrap:after,
.jubilee__video_wrap:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../img/video_pattern.png);
  z-index: 4;
  opacity: 0.8;
}
.jubilee__video_wrap:before {
  background: url(../img/scratches_video.png);
  box-shadow: inset 0 0 90px rgba(0, 0, 0, 0.7);
  opacity: 0.15;
}
.jubilee__top .wr {
  position: relative;
  z-index: 5;
}
.jubilee__video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100vw;
  height: auto;
  filter: grayscale(100%);
}
.jubilee__content {
  padding: 65px 0 40px;
  background: url(../img/cream_bg.png) repeat;
}
.jubilee__content.m--small {
  padding: 36px 0;
}
.jubilee__content_image {
  position: relative;
  width: 100%;
  border: 1px dashed #d0ccc0;
  padding: 25px;
  max-width: 840px;
  margin: 40px auto 50px;
  box-sizing: border-box;
  background: #fff;
}
.jubilee__content_image img {
  text-align: center;
  width: 100%;
  height: auto;
}
.jubilee__content_image img.m--fade {
  opacity: 0.7;
}
.jubilee__content_image img.zoom {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  width: 50%;
  max-width: 350px;
  border-radius: 100%;
  border: 11px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.jubilee__content_caption,
.jubilee__grayblock_caption {
  text-align: center;
  margin: 25px 0 0 !important;
  font-size: 15px !important;
  line-height: 25px !important;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-style: italic;
  color: #544039;
}
.jubilee__content_caption a,
.jubilee__grayblock_caption a {
  color: #5c97a8;
}
.jubilee__grayblock_caption {
  text-align: left;
}
.jubilee__grayblock {
  padding: 30px 0;
  background: url(../img/gray_pattern.jpg);
  margin-bottom: 50px;
}
.jubilee__grayblock_text {
  width: calc(100% - 285px);
  padding-right: 45px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
}
.jubilee__grayblock .wr {
  max-width: 840px;
}
.jubilee__grayblock_image {
  position: relative;
  width: 280px;
  border: 15px solid #eeefea;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
}
.jubilee__grayblock_image_wrap {
  border: 1px dashed #d0ccc1;
}
.jubilee__grayblock_image_wrap img {
  position: relative;
  display: block;
  border: 10px solid #fff;
  width: 100%;
  box-sizing: border-box;
}
.jubilee__grayblock_text p {
  margin-bottom: 15px;
  color: #1e2627;
}
.jubilee__grayblock_text .jubilee__grayblock_caption {
  margin-top: 20px !important;
}
.jubilee__button {
  position: relative;
  text-align: center;
  background: #e6a628;
  color: #fff;
  font-size: 15px;
  font-family: 'Gabriela', serif;
  border-radius: 100px;
  padding: 5px 20px;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}
.jubilee__button:hover {
  opacity: 0.85;
}
.jubilee__gallery {
  position: relative;
  color: #fff;
  background: url(../img/blue_bg.png);
  padding: 0px 0 30px;
}
.jubilee__gallery:after {
  background: url(../img/jubilee-wrap_blue.png) repeat-x;
  width: 100%;
  position: absolute;
  content: '';
  height: 12px;
  bottom: -2px;
  left: 0;
  z-index: 0;
}
.jubilee__bridge.m--inner:after {
  display: none;
}
.jubilee__gallery.m--inner {
  padding-top: 40px;
}
.jubilee__gallery h2 {
  font-family: 'Gabriela', serif;
  font-weight: 400;
  text-shadow: 0 3px rgba(0, 0, 0, 0.2);
  font-size: 40px;
  line-height: 95px;
  margin: 0;
  padding: 10px 0;
  text-align: center;
}
.jubilee__gallery_list {
  border-bottom: 1px dashed #fff;
  padding-bottom: 35px;
}
.jubilee__gallery_list:after {
  clear: both;
  display: block;
  content: '';
}
.jubilee__gallery_item {
  position: relative;
  width: calc(25% - 8px);
  border: 15px solid #eeefea;
  box-sizing: border-box;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
.jubilee__gallery_item:nth-child(4n) {
  margin-right: 0;
}
.jubilee__gallery_item_wrap {
  border: 1px dashed #d0ccc1;
}
.jubilee__gallery_item_wrap img {
  position: relative;
  display: block;
  border: 9px solid #fff;
  width: 100%;
  box-sizing: border-box;
}
.jubilee__gallery_btn {
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-family: 'Gabriela', serif;
  border-radius: 100px;
  border: 1px solid #c4d7db;
  padding: 5px 20px;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
}
.jubilee__gallery_btn:hover {
  color: #000;
  background: #fff;
}
.jubilee .header__top {
  position: relative;
}
.jubilee .header__top:after {
  content: '';
  border-bottom: 1px dashed #d0ccc1;
  position: absolute;
  bottom: -2px;
  height: 1px;
  width: 100%;
}
