.brige-100_years .breadcrumbs {
  background: none;
  margin-top: 0;
  margin-bottom: 22px;
}

.brige-100_years .breadcrumbs span,
.brige-100_years .breadcrumbs a {
  color: #ffffff;
}
.brige-100_years .breadcrumbs:before {
  background: url(../img/sep5.png) repeat-x;
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  top: auto;
  height: 1px;
  opacity: 0.5;
}

.brige-100_years .f_head {
  font-size: 30px;
  font-family: 'open_sansextrabold', sans-serif;
  margin-bottom: 27px;
  text-transform: uppercase;
}
.brige-100_years .f_head span {
  font-size: 70px;
  color: #27aae0;
  display: block;
}
.brige-100_years .pb_banner {
  padding-bottom: 50px;
  overflow: hidden;
}
.brige-100_years .pb_banner h1 {
  background: #27aae0;
  padding: 16px;
  position: relative;
  border: 3px solid #eee;
  font-size: 50px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: 62px;
  margin-bottom: 141px;
}
.brige-100_years .pb_banner h1::before,
.brige-100_years .pb_banner h1::after {
  content: '';
  width: 81px;
  height: 112px;
  display: block;
  background: url(../img/tape2_l.png);
  left: -59px;
  top: -3px;
  position: absolute;
}
.brige-100_years .pb_banner h1::after {
  right: -64px;
  left: auto;
  transform: rotateY(180deg);
}
.brige-100_years .pb_banner .namber {
  font: 30px 'open_sansextrabold', sans-serif;
  color: #27aae0;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.brige-100_years .pb_banner p {
  color: #fff;
  font-size: 15px;
  line-height: 29px;
  margin-bottom: 41px;
  padding-right: 3px;
}
.brige-100_years .pb_banner .hd {
  font: 45px 'open_sansbold', sans-serif;
  line-height: 60px;
  margin-bottom: 14px;
  padding: 0;
}
.brige-100_years .pb_banner .btn {
  display: inline-block;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-decoration: none;
  padding: 0 15px;
  height: 34px;
  line-height: 35px;
  margin: 8px 0px 10px 0;
  color: white;
  text-transform: uppercase;
  border-radius: 30px;
}
.brige-100_years .m_text img {
  margin-bottom: 20px;
}
.brige-100_years .m_text h3 {
  color: #27aae0;
  font: 35px 'open_sanssemibold', sans-serif;
  text-transform: none;
}
.brige-100_years .m_text .wysiwyg .video_wrap {
  background: #ededed;
}
.brige-100_years .m_text .wysiwyg .video_wrap iframe {
  top: 35px;
  left: 40px;
  width: calc(100% - 80px) !important;
  height: calc(100% - 70px) !important;
  right: 40px;
}
.brige-100_years .m_text .wysiwyg h2 {
  font-size: 18px;
  font-family: 'open_sansextrabold', sans-serif;
  line-height: 150%;
  margin: 30px 0 34px;
  text-transform: uppercase;
}
.brige-100_years .m_text .wysiwyg h2:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 16px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.brige-100_years .wysiwyg p {
  font-size: 17px;
  line-height: 35px;
  margin: 0 0 35px;
}
.brige-100_years .paral-f {
  position: relative;
}
.brige-100_years .paral-f #scene {
  position: absolute;
  top: -360px;
  left: -50px;
}
.brige-100_years .btn {
  display: inline-block;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-decoration: none;
  padding: 0 15px;
  height: 34px;
  line-height: 35px;
  margin: 16px 0px 40px 0;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  border-radius: 30px;
}
.brige-100_years .faces {
  margin-bottom: 27px;
}
.brige-100_years .faces a {
  display: block;
  margin-bottom: 10px;
}
.brige-100_years .faces h4 {
  margin-bottom: 31px;
}
.troj__ribon-text {
  text-align: center;
  color: white;
  font-family: 'open_sansextrabold', sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  position: relative;
  margin: 25px 0 40px;
}
.troj__ribon-text:before {
  width: 240px;
  background: url(../img/ribbon_2.png) no-repeat center;
  position: absolute;
  left: 0;
  right: 0;
  content: '';
  top: -5px;
  z-index: -1;
  display: block;
  height: 62px;
  margin: auto;
}
.brige-100_years.troj-bridge .breadcrumbs:before {
  background: none;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
}

.brige-100_years.troj-bridge .wr.banner-content {
  padding: 88px 0 77px;
}
.troj-bridge__header-col {
  padding-left: 14px;
}
.troj-bridge__header-col a {
  color: #27aae0;
}

.brige-100_years.troj-bridge .pb_banner .hd {
  line-height: 50px;
  margin-bottom: 30px;
}
.brige-100_years.troj-bridge .pb_banner p {
  margin-bottom: 41px;
}

.brige-100_years.troj-bridge .pb_banner .namber {
  font: 28px 'open_sansextrabold', sans-serif;
  text-transform: inherit;
  margin-bottom: 10px;
  margin-top: 11px;
}
