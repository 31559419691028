.fb-body .header__bottom .message,
.fb-body .header__bottom .line_r {
  background: #4acaff;
}
.fb-body .header__bottom p.hd,
.fb-body .header__bottom .line_l,
.fb-body .header__bottom a.btn_close_message {
  background: #0099d8;
}
.fb-body .modal-backdrop {
  background-color: #234fa1;
  opacity: 0.98;
}
.fb-body .header__bottom a.btn_close_message i {
  filter: grayscale(1) contrast(100);
}
.fb_banner {
  background: #000 url(/img/footbal-bg.png) top no-repeat;
  background-size: cover;
  padding: 63px 0 0;
}
.fb-h1-main {
  font-size: 63px;
  color: #fff;
  margin-bottom: 23px;
  text-shadow: 2px 3px 5px #184b86;
  font-weight: bold;
  font-family: 'open_sanssemibold', sans-serif;
}
.fb-h1 {
  font-size: 60px;
  color: #4acaff;
  margin-bottom: 23px;
  line-height: 75px;
  font-weight: bold;
  margin: 0;
  font-family: 'open_sansbold', sans-serif;
  margin-bottom: 30px;
  margin-top: 5px;
}
.path-cat {
  font-size: 20px;
  line-height: 25px;
  font-family: 'open_sansbold', sans-serif;
  margin-top: 5px;
}
.path-img {
  margin: 20px 0 50px;
  width: 100%;
}
.ball-col {
  text-align: right;
}
.ball {
  max-width: 100%;
}
.fb-h1-main span {
  color: #4acaff;
}
.fb-h2 {
  color: #4acaff;
  font-size: 45px;
  font-family: 'open_sansbold', sans-serif;
}
.fb-h2.dark {
  color: #1a338c;
}
.fb-h2.black {
  color: #000;
}
.slider-block {
  margin: 44px 0;
}
.slider-col {
  text-align: center;
  padding: 0 30px;
}
.fb-sign {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 49px;
}

.fb-btn {
  border-radius: 50px;
  color: white !important;
  font-size: 16px;
  font-family: 'open_sansbold', sans-serif;
  position: relative;
  padding: 12px 25px;
  display: inline-block;
  padding-left: 44px;
  text-decoration: none !important;
}
.fb-header {
  background: url(../img/fb-header-bg.png) no-repeat,
    url(../img/fb-header-bg.jpg) no-repeat;
  background-size: cover;
  padding: 20px 0;
}
.fb-header.sml {
  /*padding: 8px 0;*/
}
.fb-header.sml .fb-slide-btn.sml {
  float: right;
  margin-top: 17px;
}
.route-select {
  width: 28%;
  display: inline-block;
  color: white;
  position: relative;
  top: 12px;
  margin-right: 35px;
}
.route-select .select-custom {
  border-radius: 50px;
  font-size: 15px;
  background: #3156a3;
}
.route-select .select-styled {
  background: none;
  padding: 12px 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.route-select .select-styled:after {
  background: url(../img/arr_d.png) no-repeat 95% center;
  filter: grayscale(1) contrast(100);
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  content: '';
  width: 10px;
  height: 10px;
  margin: auto;
}
.fb-obj-block {
  /*    border-radius: 50px;
    background: #4062aa8a;
    position: relative;
    display: inline-block;
    padding: 11px 70px;
    padding-right: 37px;
    color: white;
    width: 35%;
    font-size: 15px;*/

  border-radius: 50px;
  background: #3156a3;
  position: relative;
  display: inline-block;
  /* padding: 11px 70px; */
  /* padding-right: 37px; */
  color: white;
  min-width: 46%;
  font-size: 15px;
}
.fb-obj-block img {
  position: absolute;
  left: 30px;
}
.fb-header label {
  display: inline-block;
  color: white;
  font-size: 15px;
  margin-right: 12px;
  cursor: default;
}
.fb-obj-block:after {
  background: url(../img/arr_d.png) no-repeat 95% center;
  filter: grayscale(1) contrast(100);
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  content: '';
  width: 10px;
  height: 10px;
  margin: auto;
}

.fb-obj-list {
  position: absolute;
  background: white;
  left: 0;
  border-radius: 20px;
  top: -18px;
  padding: 28px 19px;
  padding-bottom: 20px;
  width: 94%;
  z-index: 10;

  display: none;
}
.fb-obj-select {
  color: white;

  border-radius: 50px;
  /* background: #4062aa8a; */
  position: relative;
  display: inline-block;
  padding: 11px 70px;
  padding-right: 30px;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
}
.fb-obj-block span {
  position: relative;
  display: inline-block;
  padding-left: 18px;
}
.fb-obj-block span:after {
  content: '—';
  top: 0;
  left: 0;
  position: absolute;
}
.fb-obj-list__close {
  position: absolute;
  right: -40px;
  left: auto;
  display: none;
  top: 11px;
}
.fb-obj-list__close img {
  left: auto;
}
.fb-obj-list label {
  display: block;
  color: #484848;
  font-size: 15px;
  padding-left: 50px;
  margin-right: 0;
  position: relative;
  cursor: pointer;
  margin-bottom: 32px;
}
.fb-obj-check:not(:checked):hover + label {
  color: #757575;
}
.fb-obj-list .img-cont {
  background: rebeccapurple;
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: -9px;
}
.fb-obj-block-show {
  padding: 7px 25px;
  margin-top: 7px;
  /* margin-bottom: 0; */
  font-family: 'open_sansregular', sans-serif;
}
.fb-obj-list .img-cont.place-gps {
  background: #214a9d;
}
.fb-obj-list .img-cont.place-walk {
  background: #a6aedc;
}
.fb-obj-list .img-cont.place-build {
  background: #f6a9bc;
}
.fb-obj-list .img-cont.place-rest {
  background: #7cb8d4;
}
.fb-obj-list .img-cont.place-ship {
  background: #65c7be;
}
.fb-obj-list .img-cont.place-wc {
  background: #e6c2a5;
}
.fb-obj-list .img-cont.place-shop {
  background: #9ed986;
}
.fb-obj-check:not(:checked) + label {
  color: #b2b2b2;
}
.fb-obj-check:not(:checked) + label .img-cont {
  background: #ebebeb;
}
.fb-obj-check:not(:checked) + label .img-cont img {
  filter: invert(1) contrast(0);
}
.fb-obj-list .img-cont img {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.fb-obj-list input[type='checkbox'] {
  display: none;
}

.fb-map__route-text {
  padding-top: 30px;
}

.fb-map__route-info {
  position: absolute;
  right: 10%;
  border-radius: 5px;
  box-shadow: 0 0 5px lightgray;
  background: white;
  bottom: 20%;
  padding: 20px;
  width: 454px;

  display: none;
}

.fb-map__route-info__close {
  position: absolute;
  right: 10px;
  left: auto;
  filter: invert(1) contrast(0.4);
  top: 11px;
  z-index: 10;
}

.info-label {
  font-size: 13px;
  font-family: 'open_sansregular', sans-serif;
  line-height: 13px;
  margin-bottom: 5px;
}

.info {
  font-size: 15px;
  font-family: 'open_sansbold', sans-serif;
  line-height: 15px;
  margin-bottom: 15px;
  max-width: 210px;
}
.fb-map__route-info-more {
  padding: 7px 25px;
  margin-top: 15px;
  font-family: 'open_sansregular', sans-serif;
}

.fb-slide-btn {
  border-radius: 50px;
  color: white !important;
  font-size: 13px;
  font-family: 'open_sansbold', sans-serif;
  position: relative;
  padding: 12px 25px;
  display: inline-block;
  background: #4acaff;
  text-decoration: none !important;
}
.fb-slide-btn.sml {
  padding: 7px 25px;
  margin-top: 7px;
  /* margin-bottom: 0; */
  font-family: 'open_sansregular', sans-serif;
}
.fb-slide-btn.dark {
  background: #234fa1;
}
.fb-btn:before {
  position: absolute;
  left: 16px;
  content: '';
  background: url(/img/btn-gps.png);
  width: 14px;
  height: 20px;
}
.fb-path-what-look-btns {
  text-align: center;
  margin-top: 28px;
  margin-bottom: 16px;
}
.fb-path-what-look-btns .fb-slide-btn {
  margin-bottom: 5px;
}
.fb-path-what-look-btns .fb-slide-btn.dark {
  margin-left: 5px;
}
.fb_sbornaya {
  padding: 50px 0;
}
.fb_sbornaya .fb-h2 {
  font-size: 40px;
}
.fb_sbornaya-slide__btns {
  margin-top: 67px;
}
.fb_sbornaya-slide__btns .fb-slide-btn {
  font-size: 15px;
}
.fb_sbornaya-slide__info {
  padding-top: 20px;
}
.fb_sbornaya-slide__info p {
  font-size: 16px;
  font-weight: bold;
  font-family: 'open_sansbold', sans-serif;
  margin-bottom: 9px;
}
.fb_sbornaya-slider label {
  font-size: 13px;
  font-weight: 100;
  font-family: 'open_sanslight', sans-serif;
}
.fb_sbornaya .fb-slide-btn.sml {
  padding: 3px 22px;
  font-size: 13px;
  margin-top: 14px;
}
.fb_sbornaya-slide__text {
  padding-top: 20px;
  padding-left: 65px;
  border-left: 1px dashed lightgray;
}
.fb_sbornaya-slide__text p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 26px;
  font-family: 'open_sansregular', sans-serif;
  font-weight: normal;
}
.fb_sbornaya-slide {
  padding: 30px 42px;
}
.fb_sbornaya-slide__text a {
  color: #4acaff;
  font-size: 15px;
  text-decoration: underline;

  line-height: 25px;
}
.fb_sbornaya-nav-slider {
  margin-bottom: 30px;
}
.fb_sbornaya-nav-slider li {
  display: inline-block;
  font-family: 'open_sansextrabold', sans-serif;
  font-size: 24px;
  height: 40px;
  color: #d7d7d7;
  position: relative;
  transition: 0.2s;
}
.fb_sbornaya-nav-slider li a {
  position: absolute;
  bottom: 0;
  opacity: 0.2;
  left: 0;
  right: 0;
}
.fb_sbornaya-nav-slider li:hover a {
  color: #970703;
  opacity: 1;
}
.fb_sbornaya-nav-slider li.active a {
  font-size: 46px;
  line-height: 46px;
  color: #4acaff;
  opacity: 1;
}
.fb_sbornaya-slider {
  position: relative;
}
/*.fb_sbornaya-slide
{
  display: none;
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 }*/
.slide-nav-arrow.left {
  float: left;
  margin-top: 16px;
}
.slide-nav-arrow.right {
  float: right;
  margin-top: 16px;
}

.fb_routes {
  background: #234fa1;
  padding: 80px 0 90px;
}
.routes__header {
  margin-bottom: 100px;
}
.fb_routes p {
  color: white;
}
.routes_title {
  position: relative;
  width: 100%;
}
.fb_routes p.route-title {
  font-family: 'open_sansextrabold', sans-serif;
  font-size: 30px;
  line-height: normal;
  color: #4acaff;
  margin-bottom: 12px;
}
.routes_count {
  font-family: 'open_sansextrabold', sans-serif;
  font-size: 150px;
  line-height: 150px;
  color: #4acaff;
}
.route_title-1 {
  font-family: 'open_sanslight', sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: #fff;
  position: absolute;
  bottom: 50px;
  left: 184px;
}
.routes_info {
  position: absolute;
  bottom: 8px;
  font-size: 15px;
  line-height: 25px;
  padding-left: 5px;
}
.route-info {
  font-size: 15px;
  line-height: 25px;
}
.route_title-2 {
  font-family: 'open_sansextrabold', sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: #4acaff;
  position: absolute;
  bottom: 9px;
  left: 202px;
}
.route-col {
  position: relative;
}

.route__slider-nav {
  position: absolute;
  bottom: 13px;
  right: 15%;
  z-index: 10;
}
.route__slider-nav .pagingInfo {
  font-size: 20px;
  display: inline-block;
  margin: 0 28px;
  color: white;
  font-family: 'open_sansbold', sans-serif;
}
.route__slider-nav .pagingInfo .active-slide__number {
  font-size: 20px;
  font-weight: 400;
  color: #4acaff;
}
.route__slider-nav .slide-nav-arrow.left,
.route__slider-nav .slide-nav-arrow.right {
  margin-top: 0;
}
.route__player-info {
  font-family: 'open_sanslight', sans-serif;
  font-size: 13px;
  margin-bottom: 9px;
}
.route__player-info span {
  font-family: 'open_sansbold', sans-serif;
}
.route__list {
  position: relative;
  width: 90%;
  margin: 50px 0 42px;
}
.route__list li {
  display: inline-block;
  width: 30%;
  color: white;
  font-family: 'open_sansbold', sans-serif;
  font-size: 17px;
  margin-bottom: 20px;
}
.route__list li img {
  display: inline-block;
}
.route__chart {
  padding: 0 50px 22px;
  padding-left: 90px;
}
.route__slide {
  position: relative;
}
.fb_routes p.route_number {
  font-size: 30px;
  font-family: 'open_sansextrabold', sans-serif;
  position: absolute;
  top: 3px;
  left: -72px;
}
.fb_about {
  background: #f4f6fa;
  padding: 50px 0;

  &:has(+ .txt) {
    margin-bottom: 50px;
  }
}
.fb_about .fb-h2 {
  margin-bottom: 30px;
}
.fb_about .fb-slide-btn.sml {
  margin-top: 12px;
}
.about-logo {
  margin-top: 35px;
  max-width: 100%;
}

.fb_about p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 20px;
}
.fb_about p.about-slogan {
  font-size: 17px;
  font-family: 'open_sansbold', sans-serif;
  margin-bottom: 25px;
}
.fb_news {
  padding: 50px 0;
}
.fb_new {
  background: white;
  box-shadow: 0 0 5px lightgray;
  margin-bottom: 30px;
  padding-bottom: 45px;
}
.fb_new .img-container {
  position: relative;
  display: block;
}
.fb_new .new-img {
  width: 100%;
}
.fb-path-what-look .fb-h2 {
  margin-bottom: 53px;
}

.fb_what-look-text {
  padding: 30px;
  /*position: relative;*/
}
.fb_new-text {
  padding: 30px;
}
.fb_what-look-text {
  padding-bottom: 20px;
}
.fb_what-look-icon {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  /*top: -20px;*/
  /*top:53%;*/
  bottom: -20px;
}
.fb_what-look__address {
  font-size: 13px;
  line-height: 20px;
  margin: 10px 0 24px;
}
.fb_what-look__title {
  font-size: 15px;
  font-family: 'open_sansbold', sans-serif;
  margin-bottom: 10px;
}
.fb_new-text p {
  font-size: 14px;
  line-height: 25px;
}
.fb_what-look-text .new-btn {
  margin-top: 30px;
  margin-left: -7px;
}
.fb_new-date {
  color: #234fa1;
  font-size: 14px;
  font-family: 'open_sansbold', sans-serif;
  margin-bottom: 6px;
}
.fb_news .fb-slide-btn {
  margin: auto;
  display: block;
  width: 88px;
  padding: 7px 10px;
  margin-bottom: 20px;
  text-align: center;
}
.txt .fb-h2 {
  margin-top: 0;
}
.face {
  margin-bottom: 10px;
}
.txt .fb-slide-btn {
  margin: auto;
  display: block;
  width: 135px;
  padding: 7px 10px;

  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .txt .fb-slide-btn {
    width: auto;
  }
}
.fb_new .svg {
  position: absolute;
  right: 10px;
  top: 5px;
  background: #234fa1;
  border-radius: 50%;
  padding: 9px;
  transform: rotate(180deg);
  width: 28px;
  height: 28px;
  text-align: center;
}
.fb_new .new-btn {
  margin-top: 13px;
  display: block;
  position: absolute;
  bottom: 50px;
}

.fb-path-intro__info .fb-path-intro__info-block {
  border-right: 1px dashed black;
  margin-right: 24px;
  padding-right: 15px;
}
.fb-path-intro__info .col-md-4:nth-child(3) .fb-path-intro__info-block {
  border-right: none;
}
.fb-path-intro__info-block p {
  font-size: 15px;
  line-height: 25px;
}

.fb-path-intro__info-title {
  font-size: 15px;
  line-height: 20px;
  font-family: 'open_sansbold', sans-serif;
}
.fb-path-intro__info-title.big {
  font-size: 16px;
  margin-bottom: 7px;
}

.fb-path-intro___arr-link {
  position: relative;
  display: inline-block;
  font-size: 15px;
}
.fb-path-intro___arr-link:after {
  content: '';
  position: absolute;
  top: 3px;
  right: -50px;
  background: url(/img/arr-r-b.png);
  width: 40px;
  height: 17px;
}

.fb-path-way,
.fb-path-intro {
  padding: 50px 0;
}
.fb-path-way {
  padding-bottom: 35px;
  padding-top: 90px;
}
.fb-path-what-look {
  background: #f4f6fa;
  padding: 50px 0;
}
.fb-path-length {
  padding: 36px 0 74px;
  background: #f4f6fa;
}
.fb-path-intro {
  padding-bottom: 80px;
}
.fb-path-intro__info {
  padding: 9px 0;
}

.fb-path-length .fb-h2 {
  margin-bottom: 53px;
}
.path-accent {
  font-size: 16px;
  line-height: 20px;
  font-family: 'open_sansbold', sans-serif;
  position: relative;
  margin-bottom: 19px;
}
.path-man {
  font-size: 15px;
  line-height: 25px;
  position: relative;
  margin: 37px 0;
}
.path-man:after {
  width: 14px;
  height: 66px;
  display: block;
  content: '';
  position: absolute;
  left: -46px;
  top: -20px;
  background-image: url('../img/path-man.png');
}
.path-accent:after {
  width: 40px;
  height: 40px;
  display: block;
  content: '';
  position: absolute;
  left: -80px;
  top: -10px;
}
.pause:after {
  background-image: url('../img/path-pause.png');
}
.bus:after {
  background-image: url('../img/path-bus.png');
}
.metro:after {
  background-image: url('../img/path-metro.png');
}
.enter:after {
  background-image: url('../img/path-gps.png');
}
.ball:after {
  background-image: url('../img/path-ball.png');
}
.fb-path-way ul {
  margin-bottom: 37px;
  padding-left: 24px;
}
.fb-path-way ul li {
  position: relative;
  /*list-style-type: disc;*/
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 25px;
}
.fb-path-way ul li:before {
  content: '•';
  color: black;
  position: absolute;
  left: -28px;
  top: -1px;
  font-size: 20px;
}
.pl-col {
  padding-left: 5%;
}
.pl-col-2 {
  padding-left: 5%;
}
.pl-col .path-accent:after,
.pl-col-2 .path-accent:after {
  left: -60px;
}

.fb-modal .modal-body {
  padding: 13px 25px;
}
.fb-modal .modal-header {
  padding: 0;
  margin: 0;
}
.fb-modal-address {
  color: #4acaff;
  font-size: 16px;
  line-height: 20px;
  font-family: 'open_sansbold', sans-serif;
  margin-bottom: 8px;
}
.fb-modal-title {
  font-size: 35px;
  line-height: 35px;
  font-family: 'open_sansbold', sans-serif;
  margin: 0;
  margin-bottom: 55px;
}
.fb-modal-text p {
  font-size: 15px;
  line-height: 25px;
  font-family: 'open_sanslight', sans-serif;
  margin-bottom: 20px;
}
.fb-modal-text li {
  font-size: 15px;
  line-height: 25px;
  font-family: 'open_sanslight', sans-serif;
  margin-bottom: 10px;
  position: relative;
  padding-left: 15px;
}
.fb-modal-text li:after {
  position: absolute;
  left: -3px;
  content: '●';
  top: 0;
  color: #4acaff;
}
.fb-modal-text a {
  text-decoration: underline;
  color: #4acaff;
}
.fb-modal-icon {
  width: 40px;
  height: 40px;
  display: block;
  background: #ebebeb;
  position: absolute;
  left: -6px;
  top: -8px;
  border-radius: 50%;
}
.fb-modal-icon img {
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
}
.fb-modal-text {
  padding-left: 38px;
}
.fb-modal-list {
  margin-top: 29px;
}
.fb-modal-list li {
  font-size: 15px;
  line-height: 25px;
  font-family: 'open_sanslight', sans-serif;
  position: relative;
  margin-bottom: 25px;
  padding-left: 44px;
}
.fb-modal-list li:after {
  content: none;
}
.fb-modal .fb-slide-btn {
  margin-top: 35px;
  padding: 7px 19px;
}
.fb-modal .modal-content {
  padding-bottom: 45px;
  border-radius: inherit;
}
.fb-modal img {
  max-width: 100%;
}
.fb-modal .close {
  right: -13px;
  opacity: 1;
  position: absolute;
  top: -15px;
}
.bridge-img {
  margin-bottom: 38px;
}
.fb-modal .bridge-img {
  width: 100%;
}
.fb-modal.bridgeModal .fb-modal-text {
  padding-left: 0;
}
.fb-modal.bridgeModal .fb-slide-btn {
  margin-top: 25px;
}
.fb-bg_blue {
  background: #4acaff;
}

.fb-body .header__top {
  background: #1a338c;
}
.fb-body footer .footer__top {
  background: #234fa1;
}
.fb-body footer .footer__top p:last-child a {
  color: #4acaff;
}
footer .footer__top p:last-child a:after {
  background: #4acaff;
}
.fb-body footer {
  background: #1a338c;
}

.fb-tooltip-content {
  padding: 5px 10px;
  max-width: 260px;
}
.fb-tooltip-content .type {
  font-size: 13px;
  font-family: 'open_sanslight', sans-serif;
}
.fb-tooltip-content .name {
  font-size: 15px;
  font-family: 'open_sansbold', sans-serif;
}
