.info_blocks {
  background: #eee;
  padding: 40px 0 30px;
  height: 890px;
}
.info_blocks .wr {
  position: relative;
}
.info_blocks .icon.bank {
  width: 30px;
  height: 30px;
  background: url(../img/bank.png) center no-repeat;
}
.info_blocks .widget {
  position: absolute;
}
.info_blocks .widget a,
.info_blocks .widget span.link {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 55px 25px 0;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.info_blocks .widget .rhombus {
  display: block;
  margin: 0 auto;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 28px;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.info_blocks .widget .rhombus.white {
  border: 5px solid white;
}
.info_blocks .widget .rhombus.blue {
  border: 5px solid #27aae0;
}
.info_blocks .widget .rhombus.bg_blue {
  color: white !important;
}
.info_blocks .widget .rhombus i {
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);

  &.red {
    &:before {
      color: #dd2d3a;
    }
  }
}

.info_blocks .widget .rhombus .fa-birthday-cake:before {
  color: #27aae0;
}

.info_blocks .widget .rhombus .fa-birthday-cake.white:before {
  color: #fff;
}
.info_blocks .widget span {
  display: block;
  z-index: 2;
}
.info_blocks .widget .anniversary-logo {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  max-width: 212px;
  width: 100%;
  height: auto;
  max-height: 152px;

  img {
    max-width: 100%;
  }
}
.info_blocks .widget span.details {
  position: absolute;
  left: 0;
  bottom: 37px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;

  &.red {
    color: #dd2d3a !important;
  }
}
.info_blocks .widget span.img {
  margin: 44px 0 -13px;
}
.info_blocks .widget span.s1 {
  font-size: 28px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  margin: 48px 0 0;
}
/* // .info_blocks .widget span.s2 {
                                                                                                                                                                      //   font-size: 15px;
                                                                                                                                                                      //   line-height: 25px;
                                                                                                                                                                      //   margin: 42px 0 0;
                                                                                                                                                                      //   max-height: 50px;
                                                                                                                                                                      //   overflow: hidden; } */
.info_blocks .widget span.s3 {
  font-size: 15px;
  color: black;
  margin-bottom: 20px;
}
.info_blocks .widget span.s3 b {
  font-size: 15px;
  font-family: 'open_sanssemibold', sans-serif;
}
.info_blocks .widget span.s4 {
  font-size: 55px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
  line-height: 55px;
}
.info_blocks .widget span.s5 {
  font-size: 20px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  margin: 27px 0 13px;
}
.info_blocks .widget span.s6,
.info_blocks .widget span.s2 {
  font-size: 20px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
  line-height: 30px;
  text-transform: uppercase;
  margin: 23px 0 0;
  height: 90px;
  overflow: hidden;

  left: 0;
  position: absolute;
  width: 100%;
  bottom: 57px;
  text-align: center;
}
.info_blocks .widget#wd5 span.s6 {
  height: auto;
}
.info_blocks .widget span.s6 {
  bottom: 83px;
  text-shadow: 0px 3px 8px black;
}

.info_blocks .widget span.s6 span {
  font-size: 55px;
  font-family: 'open_sansextrabold', sans-serif;
}
.info_blocks .widget span.s7 {
  font-size: 40px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
}
.info_blocks .widget span.s8 {
  font-size: 35px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
}
.info_blocks .widget span.s9 {
  font-size: 25px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
}
.info_blocks .widget span.s10 {
  font-size: 14px;
  font-family: 'open_sansbold', sans-serif;
}
.info_blocks .widget span.s11 {
  font-size: 25px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
  line-height: 30px;
  text-transform: uppercase;
  margin: 21px -10px 0;
  white-space: nowrap;
}
.info_blocks .widget span.s12 {
  font-size: 20px;
  font-family: 'open_sansbold', sans-serif;
  line-height: 25px;
  color: #fff;
}
.info_blocks .widget span.s13 {
  position: absolute;
  left: 0;
  bottom: 37px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'open_sansextrabold', sans-serif;
}
.info_blocks .widget span.wings {
  position: absolute;
  width: 90px;
  left: 50%;
  margin-left: -45px;
  top: 30px;
}
.info_blocks .widget span.wings:before,
.info_blocks .widget span.wings:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 51px;
  height: 70px;
}
.info_blocks .widget span.wings:before {
  background-image: url(../img/w1.png);
  background-size: 51px 70px;
  left: -50px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .info_blocks .widget span.wings:before {
    background-image: url(../img/w1@2x.png);
  }
}
.info_blocks .widget span.wings:after {
  background-image: url(../img/w2.png);
  background-size: 51px 70px;
  right: -50px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .info_blocks .widget span.wings:after {
    background-image: url(../img/w2@2x.png);
  }
}
.info_blocks #wd1 {
  left: 0;
  top: 0;
  height: 290px;
}
.info_blocks #wd1 a i {
  position: relative;
  left: 2px;
  top: -2px;
}
.info_blocks #wd2 {
  left: 50%;
  top: 0;
  height: 290px;

  a {
    i {
      position: relative;
      left: 1px;
      top: -1px;
    }
  }

  .s2 {
    text-transform: none;
    font-size: 18px;
    padding: 0 12px;
    width: 100%;
    box-sizing: border-box;
    line-height: 1.4;
    bottom: 48px;
  }

  .s3 {
    text-transform: none;
    font-size: 12px;
    padding: 0 12px;
    width: 100%;
    box-sizing: border-box;
    line-height: 1.2;
    position: absolute;
    left: 0;
    top: 210px;
    color: #fff;
  }
}
.info_blocks #wd3 {
  left: 75%;
  top: 0;
  height: 590px;

  // Кантемировский мост 40 лет НАЧАЛО
  // li {
  //   display: inline-block;
  //   margin: 0 4px 29px;

  //   .s8 {
  //     font-size: 33px;
  //   }
  // }

  // ul,
  // li {
  //   margin-bottom: 0 !important;
  // }

  // img {
  //   margin-top: 25px;
  //   margin-bottom: 20px;
  //   max-width: 100%;
  // }

  // .rhombus {
  //   margin-bottom: 33px;
  // }
  // Кантемировский мост 40 лет КОНЕЦ

  // Юбилеи мостов НАЧАЛО
  span.s1 {
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 57px;
  }

  span.s3 {
    position: absolute;
    color: #fff;
    text-align: center;
    left: 0;
    width: 100%;
    padding: 0 20px;
    display: block;
    box-sizing: border-box;
    top: 275px;
    line-height: 1;

    span {
      text-transform: uppercase;
      font-weight: 600;
      display: block;
      margin-bottom: 2px;
    }

    b {
      font-size: 16px;
      line-height: 1.5;
      font-family: 'open_sansbold', sans-serif;
    }
  }

  span.s6 {
    font-family: 'open_sansregular', sans-serif;
    font-size: 12px;
    text-transform: none;
    padding: 0 20px;
    box-sizing: border-box;
    line-height: 1.6;
    bottom: 71px;
  }

  .rhombus {
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
    background-image: url('/img/laureat.png');
    width: 90px;
    height: 88px;

    i {
      -moz-transform: rotate(0);
      -o-transform: rotate(0);
      -ms-transform: rotate(0);
      -webkit-transform: rotate(0);
      transform: rotate(0);
      top: 14px;
      left: 0px;
      position: relative;
      font-size: 34px;

      &:before {
        color: #fff;
      }
    }
  }
}

.info_blocks #wd4 {
  left: 0;
  top: 300px;
  height: 590px;
}
.info_blocks #wd4 .rhombus {
  margin-bottom: 36px;
}
.info_blocks #wd5 {
  left: 25%;
  top: 300px;
  height: 290px;
}
.info_blocks #wd5 .link i {
  position: relative;
  left: 2px;
  top: -2px;
}
.info_blocks #wd5 .link:after {
  background: rgba(0, 0, 0, 0.6);
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.info_blocks #wd6 {
  left: 25%;
  top: 600px;
  height: 290px;
}
.info_blocks #wd6 a:after {
  background: rgba(0, 0, 0, 0.6);
  /* // content: ''; */
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.info_blocks #wd6 a:after i {
  position: relative;
  left: 1px;
  top: -1px;
}
.info_blocks #wd7 {
  left: 50%;
  top: 600px;
  height: 290px;
  a {
    i {
      position: relative;
      left: 1px;
      top: -1px;
    }
  }

  span.s2 {
    font-size: 20px;
    line-height: 30px;
    padding: 0 20px;
    bottom: 102px;
    box-sizing: border-box;
  }
}

.info_blocks #wd8 {
  left: 75%;
  top: 600px;
  height: 290px;

  span.details {
    text-shadow: 0px 3px 8px black;
  }
}
.info_blocks #wd8 a:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.info_blocks #wd8 a:after i {
  position: relative;
  left: 1px;
  top: -3px;
}
