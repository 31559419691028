header {
  position: relative;
  z-index: 4;
}

.header__app {
  display: none;
  background: #27aae0;
  padding: 15px 12px 15px;
  position: relative;
}
.header__app-text {
  margin-left: 10px;
  margin-top: 5px;
}

.header__app-text p {
  font-size: 12px;
}
.header__app-img img {
  max-width: 105px;
}
.header__app-img,
.header__app-text {
  display: inline-block;
  float: left;
}
.header__app-close {
  position: absolute;
  right: 12px;
  top: 10px;
}

.header__top {
  background: #27aae0;
  padding: 12px 0 12px;
}
.header__top .wr:after {
  content: '';
  clear: both;
  display: block;
}
.header__top .header__top-phone {
  float: left;
  font-size: 12px;
  font-family: 'open_sanssemibold', sans-serif;
  color: white;
  margin: 6px 0 0 21px;
}
.header__top .header__top-phone i {
  margin: 0 18px 0 0;
}

.header__top-socials {
  float: right;
  color: white;
  margin: 0 0 0 3%;
}
.header__top-socials a {
  z-index: 20;
  margin: 0;
  width: 30px;
  display: inline-block;
  position: relative;
  text-align: center;
}
.header__top-socials a:hover:after {
  display: block;
}
.header__top-socials a:after {
  display: inline-block;
  border-radius: 50%;
  content: '';
  position: absolute;
  width: 30px;
  left: 0;
  top: -4px;
  z-index: -1;
  background: #52bbe6;
  height: 30px;
  display: none;
}

.header__top .header__top-mail {
  float: left;
  color: white;
  margin: 4px 0 0 2%;
  font-size: 12px;
}
.header__top .header__top-mail a {
  font-family: 'open_sanssemibold', sans-serif;
  color: white;
}
.header__top .header__top-mail i {
  margin: 3px 17px 0 0;
}
.header__top .header__top-links {
  float: right;
  margin-top: 1px;
}
.header__top .header__top-links a {
  color: white;
  font-size: 12px;
  font-family: 'open_sanslight', sans-serif;
  margin-left: 25px;
}
.header__top .header__top-links a:first-child {
  margin: 0 !important;
}
.header__top .header__top-lang {
  float: right;
  margin-left: 90px;
}
.header__top .header__top-lang a {
  display: inline-block;
  color: #fff;
  font: 12px/24px 'open_sansextrabold', sans-serif;
  background: #0099d8;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  border-radius: 24px;
  text-align: center;
  text-transform: uppercase;
}
.header__top .header__top-lang a.current {
  background: #4acaff;
  color: #222a32;
}

.header__middle {
  position: relative;
  z-index: 1;
}
.header__middle .logo {
  background-image: url(../img/logo.png);
  background-size: 90px 86px;
  width: 90px;
  height: 86px;
  display: block;
  margin: 18px 0 0 1px;

  &.anniversary {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: calc(100% + 15px);
      top: -2px;
      width: 123px;
      height: 88px;
      background-size: cover;
      background-image: url(../img/logo-anniversary.png);
    }
  }
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .header__middle .logo {
    background-image: url(../img/logo@2x.png);
  }
}
.header__middle .header__top-lang {
  float: right;
  margin-left: 90px;
  display: none;
}
.header__middle .header__top-lang a {
  display: inline-block;
  color: #fff;
  font: 12px/24px 'open_sansextrabold', sans-serif;
  background: #0099d8;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  border-radius: 24px;
  text-align: center;
  text-transform: uppercase;
}
.jubilee .header__middle .header__top-lang a {
  background: #80a0a7;
}
.jubilee .header__middle .header__top-lang a.current {
  background: #e6a628;
  color: #fff;
}
.header__middle .header__top-lang a.current {
  background: #4acaff;
  color: #222a32;
}
.header__middle .menu {
  float: right;
  padding: 52px 0 0;
}
.header__middle .menu li {
  float: left;
  margin-left: 40px;
  padding-bottom: 20px;
  position: relative;
}
.header__middle .menu li:first-child {
  margin: 0 !important;
}
.header__middle .menu li a,
.header__middle .menu li span {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
}
.header__middle .menu li a i,
.header__middle .menu li span i {
  margin: 7px 0px 0 4px;
}
.header__middle .menu li a.current,
.header__middle .menu li span.current {
  color: #27aae0;
}
.header__middle .menu li:hover .sub {
  display: block;
}
.header__middle .menu .sub {
  background: #27aae0;
  position: absolute;
  left: 4px;
  top: 34px;
  display: none;
  width: 220px;
  padding: 22px 10px 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10;
}
.header__middle .menu .sub:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 4px 6px;
  border-color: transparent transparent #27aae0 transparent;
  position: absolute;
  top: -4px;
  left: 13px;
}
.header__middle .menu .sub a {
  font-size: 12px;
  font-family: 'open_sansregular', sans-serif;
  position: relative;
  text-transform: none;
  color: white;
  display: block;
  padding: 0 0 0 36px;
  margin-bottom: 16px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.header__middle .menu .sub a:before {
  background: white;
  content: '';
  display: block;
  width: 12px;
  height: 3px;
  position: absolute;
  left: 11px;
  top: 7px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.header__middle .menu .sub a:hover {
  color: black;
}
.header__middle .menu .sub a:hover:before {
  background: black;
}

.header__bottom {
  position: relative;
  margin: 16px 0 0;
  min-height: 0;
  overflow: hidden;
}
.header__bottom .line_l {
  background: #ff7200;
  position: absolute;
  width: 50%;
  left: 0;
  height: 100%;
}
.header__bottom .line_r {
  background: #ff9600;
  position: absolute;
  width: 50%;
  right: 0;
  height: 100%;
}
.header__bottom .message {
  background: #ff9600;
  position: relative;
  padding: 0 0 0 85px;
  margin: 0 0 0 15px;
  max-height: 40px;
  overflow: hidden;
}
.header__bottom .wr {
  position: relative;
}
.header__bottom p.hd {
  background: #ff7200;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  height: 40px;
  line-height: 41px;
  padding: 0 15px;
  text-transform: uppercase;
  position: absolute;
  left: -15px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  z-index: 1;
  color: #fff;
}
.header__bottom a.btn_close_message {
  background: #222a32;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  color: white;
  position: absolute;
  right: 0;
  top: 8px;
  height: 24px;
  line-height: 24px;
  padding: 0 27px 0 11px;
  text-transform: uppercase;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}
.header__bottom a.btn_close_message i {
  position: absolute;
  right: 5px;
  top: 4px;
}
.header__bottom .msg_line {
  padding: 14px 10px 11px 0;
}
.header__bottom .str_wrap {
  overflow: hidden;
  width: 100%;
  position: relative;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.header__bottom .anyClass {
  opacity: 0;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.header__bottom .str_move {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  cursor: move;

  p {
    font-size: 90%;
    line-height: 90%;
  }
}
.header__bottom .str_move_clone {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  left: 100%;
  top: 0;
}
.header__bottom .str_vertical .str_move_clone {
  left: 0;
  top: 100%;
}
.header__bottom .str_down .str_move_clone {
  left: 0;
  bottom: 100%;
}
.header__bottom .str_vertical .str_move,
.header__bottom .str_down .str_move {
  white-space: normal;
  width: 100%;
}
.header__bottom .str_static .str_move,
.header__bottom .no_drag .str_move,
.header__bottom .noStop .str_move {
  cursor: inherit;
}
.header__bottom .str_wrap img {
  max-width: none !important;
}

.mob-socials {
  padding: 10px 0;
  position: relative;
  display: none;
}
.mob-socials a {
  margin: 5px;
}
.mob-socials:after {
  background: url(../img/sep_white.png) repeat-x;
  content: '';
  height: 1px;
  width: 100%;
  position: relative;
  bottom: -10px;
  display: block;
  opacity: 0.3;
}

@media screen and (max-width: 817px) {
  .header__app {
    display: inherit;
  }
}
@media screen and (max-width: 402px) {
  .header__app-text {
    width: 54%;
    margin-top: 0;
  }
}

.header__top .header__top-lang {
  margin-left: 40px;
}

/* Версия для слабовидящих */
.header__top-spec {
  float: right;
  border-radius: 100px;
  background: url(../img/eye-blue.png) no-repeat 10px 6px, #222a32;
  line-height: 24px;
  height: 24px;
  padding: 0px 24px 0 36px;
  box-sizing: border-box;
  margin-left: 21px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #e8edf2;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}
.header__top-spec:hover {
  background: url(../img/eye-blue.png) no-repeat 10px 6px, #000;
}
.header__spez_panel {
  background: #f8f8f8;
  padding: 22px 0;
  display: none;
}
.spez__left {
  float: left;
}
.spez__wrap {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
  line-height: 32px;
}
.spez__scale_text {
  background: url(../img/eye.png) no-repeat center left;
  padding-left: 40px;
  font-size: 14px;
  font-family: 'open_sansregular', sans-serif;
  margin-left: 21px;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 12px;
}
.spez__scales {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 22px;
  line-height: 20px;
}
.spez__scale {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  font-family: 'open_sansbold', sans-serif;
  line-height: 1em;
  margin-right: 6px;
}
.spez__scale.m--current:after {
  position: absolute;
  content: '';
  width: 15px;
  height: 3px;
  bottom: -7px;
  left: 50%;
  margin-left: -7px;
  background: #000;
}
.spez__scale[data-scale='1'] {
  font-size: 18px;
}
.spez__scale[data-scale='2'] {
  font-size: 22px;
}
.spez__scale[data-scale='3'] {
  font-size: 26px;
}
.spez__color_text {
  font-size: 14px;
  font-family: 'open_sansregular', sans-serif;
  margin-left: 21px;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 12px;
}
.spez__colors {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-family: 'open_sansregular', sans-serif;
}
.spez__color {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #e2e2e2;
  border-radius: 40px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  box-sizing: border-box;
  font-family: 'open_sansbold', sans-serif;
  font-size: 18px;
  margin-right: 3px;
}
.spez__color.m--current:after {
  position: absolute;
  content: '';
  width: 15px;
  height: 3px;
  bottom: -7px;
  left: 50%;
  margin-left: -7px;
  background: #000;
}
.spez__color[data-color='black'] {
  background: black;
  color: white;
  border-color: black;
}
.spez__back {
  float: right;
  font-size: 14px;
  font-family: 'open_sansregular', sans-serif;
  line-height: 32px;
}
.mob_m .header__top-spec {
  right: auto;
  float: none;
  margin: 15px 0 0;
  display: inline-block;
  width: auto;
  max-width: 100%;
}
