.icon {
  display: inline-block;
  vertical-align: top;
}
.icon.phone {
  background-image: url(../img/icon_phone.png);
  background-size: 20px 13px;
  width: 20px;
  height: 13px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.phone {
    background-image: url(../img/icon_phone@2x.png);
  }
}
.icon.mail {
  background-image: url(../img/icon_mail.png);
  background-size: 15px 12px;
  width: 15px;
  height: 12px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.mail {
    background-image: url(../img/icon_mail@2x.png);
  }
}
.icon.arr_d {
  background-image: url(../img/arr_d.png);
  background-size: 10px 6px;
  width: 10px;
  height: 6px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.arr_d {
    background-image: url(../img/arr_d@2x.png);
  }
}
.icon.arr_l {
  background-image: url(../img/arr_l.png);
  background-size: 6px 10px;
  width: 6px;
  height: 10px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.arr_l {
    background-image: url(../img/arr_l@2x.png);
  }
}
.icon.arr_l_double {
  background-image: url(../img/arr_l_double.png);
  background-size: 12px 10px;
  width: 12px;
  height: 10px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.arr_l_double {
    background-image: url(../img/arr_l_double@2x.png);
  }
}
.icon.arr_r {
  background-image: url(../img/arr_r.png);
  background-size: 6px 10px;
  width: 6px;
  height: 10px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.arr_r {
    background-image: url(../img/arr_r@2x.png);
  }
}
.icon.arr_r_double {
  background-image: url(../img/arr_r_double.png);
  background-size: 12px 10px;
  width: 12px;
  height: 10px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.arr_r_double {
    background-image: url(../img/arr_r_double@2x.png);
  }
}
.icon.close {
  background-image: url(../img/icon_close.png);
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.close {
    background-image: url(../img/icon_close@2x.png);
  }
}
.icon.heart {
  background-image: url(../img/icon_heart.png);
  background-size: 13px 12px;
  width: 13px;
  height: 12px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .icon.heart {
    background-image: url(../img/icon_heart@2x.png);
  }
}

.fa-calendar-check-o:before {
  content: '\f274';
  margin-left: 4px;
}

i.stars {
  display: inline-block;
  background: url(../img/stars.png) center no-repeat;
  width: 80px;
  height: 35px;
  vertical-align: text-top;
  margin: 0 15px;
}

i.star_s {
  display: inline-block;
  background: url(../img/one-star.png) center no-repeat;
  width: 25px;
  height: 23px;
  vertical-align: top;
}
.fa-birthday-cake:before {
  content: '\f1fd';
  color: #f0ad40;
}
