.bloggers .breadcrumbs {
  background: #fff;
  margin-top: 0;
  margin-bottom: 61px;
}
.bloggers .breadcrumbs a {
  color: #000;
}
.bloggers .breadcrumbs:before {
  top: auto;
  bottom: 0;
}
.bloggers .partners a {
  display: inline-block;
  margin-right: 8px;
}
.bloggers .bloggers_list,
.bloggers .bloggers_view {
  padding: 44px 0 10px;
  border-top: 1px solid #d2d6da;
  margin-top: 53px;
}
.bloggers .bloggers_list .img_wrap {
  padding: 10px;
  border: 3px solid #e7ebf0;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}
.bloggers .bloggers_list .img_wrap img {
  width: 100%;
  position: relative;
  z-index: 5;
  border-radius: 5px;
}
.bloggers .bloggers_list .img_wrap:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -30px;
  width: 29px;
  height: 48px;
  left: 25px;
  background: #fff;
  border: 3px solid #e7ebf0;
  transform: skewY(-59deg);
  border-top: none;
  border-right: none;
  border-bottom: 4px solid #e7ebf0;
  transition: all 0.3s;
}
.bloggers .bloggers_list .img_wrap:hover {
  border-color: #27aae0;
}
.bloggers .bloggers_list .img_wrap:hover:after {
  border-color: #27aae0;
}
.bloggers .discr-wrap {
  padding-left: 59px;
  padding-top: 15px;
  height: 105px;
  overflow: hidden;
}
.bloggers .discr-wrap p {
  font-size: 14px;
  margin-bottom: 5px;
  color: #000;
}
.bloggers .bl {
  display: block;
  color: #27aae0 !important;
  font-size: 12px;
}
.bloggers .bl span {
  font-family: 'open_sansextrabold', sans-serif;
}
.bloggers .bloggers_view .blog_item .img_wrap {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  overflow: hidden;
}
.bloggers .bloggers_view .blog_item .img_wrap img {
  width: 100%;
}
.bloggers .bloggers_view .blog_item .name {
  font: 20px/40px 'open_sanssemibold', sans-serif;
  color: #27aae0;
  text-transform: uppercase;
}
.bloggers .bloggers_view .blog_item .txt {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 28px;
  padding-top: 0px;
}
.bloggers .bloggers_view .blog_item .txt p {
  font-size: 100%;
}
.bloggers .bloggers_view .blog_item .txt a {
  color: #27aae0;
  cursor: pointer;
}
.bloggers .bloggers_view {
  padding: 27px 0 10px;
}
.bloggers .bloggers_view .bridge-about img {
  border-radius: 5px;
}
.bloggers .bloggers_view .discr-wrap {
  text-transform: uppercase;
  padding-left: 0;
  margin-top: 27px;
  height: auto;
  margin-bottom: 24px;
}
.bloggers .bloggers_view .discr-wrap > p {
  font: 15px/25px 'open_sansbold', sans-serif;
}
.bloggers .bloggers_view .discr-wrap p.bl {
  text-transform: none;
  margin-top: -8px;
  font: 15px 'open_sansregular';
}
.bloggers .bloggers_view .txt {
  font: 12px/20px 'open_sansregular';
  padding-right: 30px;
  margin-bottom: 25px;
}
.bloggers .bloggers_view .txt p {
  font: 12px/20px 'open_sansregular';
}
.bloggers .button {
  font: 10px/24px 'open_sansbold', sans-serif;
  text-transform: uppercase;
}
.blog_item {
  border: 13px solid #e7ebf0;
  border-radius: 10px;
  padding: 19px;
  padding-bottom: 33px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 65px;
}
.blog_item:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -58px;
  width: 68px;
  height: 58px;
  left: 25px;
  background: url('../img/bloggers_one.png') no-repeat;
}
.blog_item .txt {
  padding-top: 7px;
}
.blog_item p.date {
  font: 12px/22px 'open_sansregular';
  margin-bottom: 5px;
}
.blog_item p.cont {
  font: 15px/20px 'open_sansregular';
  color: #27aae0;
  height: 40px;
  margin-bottom: 17px;
  overflow: hidden;
}
.blog_item .button {
  font: 10px/24px 'open_sansbold', sans-serif;
  text-transform: uppercase;
}
.blog_item .img {
  float: left;
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 20px;
}
.dir .person {
  text-align: center;
  margin-top: -11px;
}
.dir .person .img {
  width: 230px;
  height: 230px;
  border-radius: 2300px;
  overflow: hidden;
  margin: 0 auto 6px;
}
.dir .person .img img {
  width: 100%;
  height: 100%;
}
.dir .person .name {
  font: 13px/20px 'open_sanssemibold', sans-serif;
  text-transform: uppercase;
  color: #27aae0;
  padding: 0 20px 15px;
}
.dir .person .discr {
  font: 12px/15px 'open_sansregular';
  padding: 0 25px 15px;
}
