.error_page {
  position: relative;
  padding: 74px 0 53px;
}
.error_page:before {
  background: url(../img/sep4.png) repeat-x;
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0;
  height: 1px;
}
.error_page .error_icon {
  background-image: url(../img/icon_404.png);
  background-size: 400px 401px;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  width: 401px;
}
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .error_page .error_icon {
    background-image: url(../img/icon_404@2x.png);
  }
}
.error_page h1 {
  font-size: 25px;
  font-family: 'open_sansbold', sans-serif;
  margin: -14px 0 8px;
  text-transform: uppercase;
}
.error_page h1 span {
  display: block;
  font-size: 180px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
}
.error_page p {
  font-size: 14px;
  margin: 0 0 6px;
}
.error_page a {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
}
