.books-list__item {
  padding: 50px 0;
  border-top: 1px dashed lightgrey;
}

.cover-container {
  padding: 62px 40px;
  width: 90%;
  box-sizing: border-box;
  text-align: center;
  background-image: url(../img/books/cover.png);
  /*background-color:#fdfdfd;*/
}

.cover-container img {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  max-width: 100%;
}
.book-text p.book-title {
  font-size: 25px;
  font-family: 'open_sanssemibold', sans-serif;
  margin: 0 0 35px;
}
.book-title:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  margin: 15px 0 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.book-text {
  /*padding-top:60px;*/
}
.book-text p {
  line-height: 25px;
  font-size: 13px;
}
.book-btn {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  display: inline-block;
  margin-top: 37px;
  padding: 0 15px;
  color: white;
  text-transform: uppercase;
  background: #27aae0;
  border-radius: 100px;
  line-height: 24px;
  height: 24px;
}

.book__links {
  margin: 0;
  overflow-y: scroll;
  height: 95%;
  text-align: left;
}
.book__links::-webkit-scrollbar-button {
  background-image: url('');
  background-repeat: no-repeat;
  width: 5px;
  height: 0px;
}

.book__links::-webkit-scrollbar-track {
  background-color: #ecedee;
}

.book__links::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #27aae0;
}

.book__links::-webkit-scrollbar-thumb:hover {
  background-color: #56999f;
}

.book__links::-webkit-resizer {
  background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 0px;
}

.book__links::-webkit-scrollbar {
  width: 4px;
}
.book__links li {
  width: 90%;
  font: 13px/20px 'open_sansregular', sans-serif;
  margin-bottom: 8px;
}
.book__links li.book__oglavl-title {
  font: 18px/20px 'open_sanssemibold', sans-serif;
  margin-bottom: 32px;
}
.book__links li.book__oglavl-subtitle {
  font: 13px/20px 'open_sansbold', sans-serif;
  margin-bottom: 20px;
  margin-top: 30px;
}
.book__links li a {
  width: 87%;
  display: inline-block;
}

.book__links li a:hover {
  text-decoration: underline;
}
.book__oglavl {
  box-sizing: border-box;
  padding: 40px;
  padding-right: 20px;
  position: absolute;
  height: 100%;
  z-index: 1000;
  width: 380px;
  left: -380px;
  background: white;
  box-shadow: inset -2px 9px 29px -9px grey;
}
.book-full.full {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 100%;
}
.book-full.full .book-bg {
  height: 94%;
  overflow: scroll;
}
.zoomed {
  opacity: 0.4;
}
.back_to_books {
  text-transform: uppercase;
  color: white;
  border-radius: 20px;
  padding: 1px;
  background: #27aae0;
  font: 10px/20px 'open_sansextrabold', sans-serif;
  /*margin-top: 59px;*/
  display: inline-block;
  width: 146px;
}
.book-top {
  background: #222a32;
  padding: 15px 0;
}
.book-full__title {
  display: inline-block;
  padding: 6px 0;
  padding-right: 45px;
  border-right: 1px solid white;
}
.book-full__title p {
  font-size: 14px;
  color: white;
}
.book-nav {
  display: inline-block;
}
.book-nav ul {
  margin: 0 0 0 8px;
}
.book-nav ul li {
  display: inline-block;
  margin-left: 27px;
}
.book__right-nav {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 185px;
  bottom: 0;
  margin: auto;
  text-align: center;
  right: 0;
  padding: 18px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #404b55;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.book__left-nav {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 185px;
  bottom: 0;
  margin: auto;
  text-align: center;
  left: 0;
  padding: 18px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #404b55;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.book_first,
.book_last {
  display: block;
  position: relative;
  padding: 3px 0px 15px;
}
.book_first:after,
.book_last:after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 30px;
  left: -6px;
  height: 1px;
  background: #666f77;
}
.book_prev,
.book_next {
  padding-top: 64px;
  display: block;
  padding-bottom: 64px;
}
.book-bg {
  text-align: center;
  background-color: #323c46;
  background-image: url(../img/book-bg.png);
  position: relative;
}
.book-bg.overlayed:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(34, 42, 50, 0.7);
  display: block;
  top: 0;

  z-index: 999;
  left: 0;
}
.t {
  display: table;
  width: 100%;
  height: 100%;
}

.tc {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.rel {
  position: relative;
}
.book {
  margin: 0 auto;
  width: 90%;
  height: 90%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.book .hard img {
  width: 100%;
}
.book .page {
  height: 100%;
}

.book .page img {
  max-width: 100%;
  height: 100%;
}
.sample-flipbook {
  /*width:400px;*/
  /*height:200px;*/
  margin: auto !important;
  -webkit-transition: margin-left 0.2s;
  -moz-transition: margin-left 0.2s;
  -ms-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}
.sample-flipbook img {
  max-width: 100%;
}
.sample-flipbook .page {
  /*width:200px;*/
  /*height:200px;*/
  background-color: white;
  /*line-height:300px;*/
  font-size: 20px;
}

.sample-flipbook .page-wrapper {
  -webkit-perspective: 2000px;
  -moz-perspective: 2000px;
  -ms-perspective: 2000px;
  -o-perspective: 2000px;
  perspective: 2000px;
}

.sample-flipbook .hard {
  background: #ccc !important;
  color: #333;
  -webkit-box-shadow: inset 0 0 5px #666;
  -moz-box-shadow: inset 0 0 5px #666;
  -o-box-shadow: inset 0 0 5px #666;
  -ms-box-shadow: inset 0 0 5px #666;
  box-shadow: inset 0 0 5px #666;
  font-weight: bold;
}

.sample-flipbook .odd,
.sample-flipbook .even {
  background: white;
}
.sample-flipbook .odd:after {
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(0.95, transparent),
    color-stop(1, rgba(0, 0, 0, 0.4))
  );
  background-image: linear-gradient(
    to left,
    transparent 95%,
    rgba(0, 0, 0, 0.4) 100%
  );

  box-shadow: inset 0 0 5px #666;
}

.sample-flipbook .even:after {
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  /* background:-webkit-gradient(linear, left top, right top, color-stop(0.95, transparent), color-stop(1, rgba(0,0,0,0.4)))); */
  background-image: linear-gradient(
    to right,
    transparent 95%,
    rgba(0, 0, 0, 0.4) 100%
  );

  box-shadow: inset 0 0 5px #666;
}
