.main-about {
  position: relative;
  padding: 53px 0 73px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 1190px;
    height: 1px;
    margin: auto;
    background-color: #e8e9ea;
  }

  .block-title {
    margin-bottom: 40px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 59px 50px;
  }

  .item {
    &__head {
      display: flex;
      align-items: center;
      gap: 0 16px;
      margin-bottom: 20px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #27aae0;
        border-radius: 100px;
        width: 46px;
        min-width: 46px;
        height: 46px;

        img {
          max-width: 22px;
          max-height: 22px;
        }
      }

      .name {
        font-family: 'open_sansbold', sans-serif;
        font-size: 17px;
      }
    }

    &__content {
      p {
        font-size: 15px;
        line-height: 1.65;
      }

      .link {
        color: #27aae0;
        font-family: 'open_sanssemibold', sans-serif;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-about {
    padding: 40px 0;
  }
}

@media screen and (max-width: 768px) {
  .main-about {
    &__wrap {
      gap: 40px 30px;
    }

    .item {
      &__head {
        .name {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .main-about {
    &__wrap {
      grid-template-columns: 1fr;
    }
  }
}
