.doc_list {
  padding: 8px 0 0;
  position: relative;
  overflow: hidden;
}
.doc_list a.doc_image {
  display: block;
  text-align: center;
  font-size: 13px;
  margin-bottom: 40px;
}
.doc_list a.doc_image span.img {
  position: relative;
  display: block;
  padding-top: 100%;
}
.doc_list a.doc_image span.img:after {
  border: 2px solid #eeeeee;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.doc_list a.doc_image span.img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  max-width: 90%;
  max-height: 90%;
}
.doc_list a.doc_image span.img i {
  position: absolute;
  right: 16px;
  bottom: 16px;
  font-size: 14px;
  color: #eee;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  z-index: 1;
}
.doc_list a.doc_image span.txt {
  background: #eee;
  display: block;
  height: 50px;
  padding-top: 15px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.doc_list a.doc_image:hover span.img i {
  color: #27aae0;
}

.doc_slide {
  padding: 26px 0 1px;
  position: relative;
  margin: 0 0 48px;
}
.doc_slide:after {
  background: url(../img/sep4.png) repeat-x;
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  display: block;
  opacity: 0.5;
  left: 0;
  bottom: 0;
}
.doc_slide p.hd {
  font-size: 21px;
  font-family: 'open_sanssemibold', sans-serif;
  text-transform: uppercase;
  margin: 0 0 27px;
}
.doc_slide .doc_slide_one {
  position: relative;
}
.doc_slide .doc_slide_one:before {
  background: url(../img/sep4.png) repeat-x;
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  display: block;
  opacity: 0.5;
  left: 0;
  top: 0;
}
.doc_slide p.date {
  font-size: 17px;
  font-family: 'open_sanssemibold', sans-serif;
  cursor: pointer;
  padding: 14px 90px 15px 45px;
  position: relative;
  text-transform: uppercase;
}
.doc_slide p.date .num {
  position: absolute;
  left: 0;
  top: 14px;
  width: 45px;
}
.doc_slide p.date .pr {
  color: #27aae0;
  text-transform: none;
  margin-left: 12px;
}
.doc_slide p.date span {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
  position: absolute;
  right: 27px;
  top: 15px;
}
.doc_slide p.date i {
  position: absolute;
  right: 5px;
  top: 19px;
  width: 13px;
  height: 13px;
}
.doc_slide p.date i:before,
.doc_slide p.date i:after {
  background: #27aae0;
  content: '';
  display: block;
  position: absolute;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.doc_slide p.date i:before {
  width: 13px;
  height: 3px;
  top: 5px;
  left: 0;
}
.doc_slide p.date i:after {
  width: 3px;
  height: 13px;
  top: 0;
  left: 5px;
}
.doc_slide p.date.open i:after {
  height: 0;
  top: 50%;
}
.doc_slide .doc_text {
  padding: 14px 0 0 49px;
  display: none;
}
.doc_slide .doc_text ul {
  margin-bottom: 20px;
}
.doc_slide .doc_text a {
  color: black;
  position: relative;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.doc_slide .doc_text a:after {
  background: #27aae0;
  content: '';
  left: 50%;
  right: 50%;
  height: 0;
  position: absolute;
  bottom: 0;
  display: block;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.doc_slide .doc_text a:hover:after {
  left: 0;
  right: 0;
  height: 1px;
}
.doc_slide .doc_text a:hover {
  text-decoration: none;
  color: #27aae0;
}
