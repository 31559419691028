html {
  font-size: 100%;
  /*overflow-y: scroll;*/
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}

body {
  background: #fff;
  margin: 0;
  line-height: 1.231;
  width: 100%;
  font-size: 100.01%;
  font-family: 'open_sansregular', sans-serif;
  font-size: 100.01%;
  height: auto !important;
  margin: 0;
  min-height: 100%;
  padding: 0;
  position: relative;
}

body,
button,
input,
select,
textarea {
  font-size: 100.01%;
  font-family: 'open_sansregular', sans-serif;
  color: black;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

::-moz-selection {
  background: #27aae0;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #27aae0;
  color: #fff;
  text-shadow: none;
}

a {
  color: black;
  text-decoration: none;
}

a:hover,
a:active {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: '';
  content: none;
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

dd {
  margin: 0 0 0 40px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

table button,
table input {
  *overflow: auto;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
}

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type='submit'] {
  cursor: pointer;
}

input,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid,
textarea:invalid {
  background-color: #f0dddd;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

iframe {
  max-width: 100%;
  width: 100% !important;
}

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.ir br {
  display: none;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

h1 {
  font-weight: normal;
}

#preload {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.button {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.button.round {
  background: #27aae0;
  border: 0;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 6px 20px 4px;
  height: auto;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  border-radius: 34px;
}

.vcenter {
  display: inline-block;
  float: none;

  vertical-align: middle;
}

.sg {
  position: relative;
  margin-bottom: 30px;
}
.sg .hid {
  display: none;
}

.sg_toggle {
  background: #27aae0;
  border: 3px solid white;
  font-size: 13px;
  font-family: 'open_sansbold', sans-serif;
  height: 34px;
  line-height: 35px;
  position: absolute;
  left: 50%;
  bottom: -20px;
  width: 214px;
  margin-left: -107px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: white;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  border-radius: 34px;
}

.mob_m {
  display: none;
}

.top_text {
  padding: 22px 0 32px;
}

.m_text {
  padding: 43px 0 0;
}

.m_text .wysiwyg h2 {
  font-size: 25px;
  font-family: 'open_sansbold', sans-serif;
  margin: 0 0 22px;
  line-height: 150%;
  text-transform: uppercase;
}
.m_text .wysiwyg h2:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 23px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.wysiwyg {
  padding: 0 0 1px;
}
.wysiwyg p {
  font-size: 14px;
  line-height: 150%;
  margin: 0 0 25px;
}
.wysiwyg p.round {
  display: inline-block;
  vertical-align: top;
  width: 32%;
  font-size: 14px;
  font-family: 'open_sansextrabold', sans-serif;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}
.wysiwyg p.round .circle {
  background: #27aae0;
  font-size: 70px;
  font-family: 'open_sansbold', sans-serif;
  width: 170px;
  height: 170px;
  display: block;
  margin: 0 auto 31px;
  line-height: 177px;
  color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.wysiwyg a {
  font-size: 100%;
  color: #27aae0;
  text-decoration: underline;
}
.wysiwyg a:hover {
  text-decoration: none;
}
.wysiwyg a.round {
  background: #27aae0;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 0 20px;
  height: 34px;
  line-height: 34px;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  border-radius: 34px;
}
.wysiwyg ul {
  margin: 0 0 25px;
  padding: 0 0 1px;
}
.wysiwyg ul li {
  padding: 0 0 0 24px;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 25px;
  position: relative;
}
.wysiwyg ul li:before {
  background: #27aae0;
  content: '';
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  left: 1px;
  top: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.wysiwyg ul li p {
  margin: 0;
  font-size: 100%;
}
.wysiwyg ul ul {
  margin: 15px 0 0;
}
.wysiwyg ol {
  list-style: decimal outside;
  margin: 0 0 25px 15px;
  padding: 0;
}
.wysiwyg ol li {
  font-size: 14px;
  line-height: 25px;
  margin: 0 0 15px 0px;
  padding: 0 0 0 10px;
}
.wysiwyg ol li p {
  margin: 0;
  font-size: 100%;
}
.wysiwyg .line:after {
  display: none;
}
.wysiwyg h1 {
  font-size: 30px;
  font-family: 'open_sansbold', sans-serif;
  line-height: 150%;
  text-transform: uppercase;
  margin: 0 0 39px;
}
.wysiwyg h1:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 23px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.wysiwyg h2 {
  font-size: 30px;
  font-family: 'open_sansbold', sans-serif;
  line-height: 150%;
  margin: 0 0 24px;
  text-transform: uppercase;
}
.wysiwyg h2:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 23px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.wysiwyg h3 {
  font-size: 25px;
  font-family: 'open_sansbold', sans-serif;
  margin: 0 0 22px;
  line-height: 150%;
  text-transform: uppercase;
}
.wysiwyg h3:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 23px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.wysiwyg h4 {
  font-size: 18px;
  font-family: 'open_sansextrabold', sans-serif;
  line-height: 150%;
  margin: 0 0 24px;
  text-transform: uppercase;
}
.wysiwyg h4:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 21px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.wysiwyg h5 {
  font-size: 13px;
  font-family: 'open_sansextrabold', sans-serif;
  line-height: 150%;
  margin: 0 0 25px;
  text-transform: uppercase;
}
.wysiwyg h5:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.wysiwyg h5.no_border:after {
  display: none;
}
.wysiwyg h6 {
  font-size: 13px;
  font-family: 'open_sansextrabold', sans-serif;
  line-height: 150%;
  margin: 0 0 8px;
  text-transform: uppercase;
}
.wysiwyg h6.sec {
  color: #27aae0;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
}
.wysiwyg table {
  width: 100%;
}
.wysiwyg table tr:nth-child(odd) td {
  background: #dff2fa;
}
.wysiwyg table tr:first-child td {
  background: #c9eaf7;
  text-transform: uppercase;
}
.wysiwyg table td {
  background: #f4fbfd;
  font-size: 12px;
  padding: 13px 16px 13px;
}
.wysiwyg table td p {
  margin: 0;
  font-size: 100%;
}
.wysiwyg img {
  max-width: 100%;
}
.wysiwyg table.vuz_table {
  margin-bottom: 30px;
}
.wysiwyg table.vuz_table td {
  background: #fff;
  font-size: 14px;
  line-height: 150%;
  padding: 13px 16px 13px;
}
.wysiwyg table.vuz_table tr:first-child td {
  background: #fff;
  text-transform: none;
}

.wysiwyg table.vuz_table tr:nth-child(odd) td {
  background: #fff;
}
.wysiwyg p.book-info {
  line-height: 32px;
  margin: 0 0 25px;
}

.mb10 {
  margin-bottom: 10px;
}

.pdg-rght {
  padding-right: 60px;
}
.mrg-big {
  margin: 60px 0 80px;
}

.title_h2 {
  font-size: 30px;
  font-family: 'open_sansbold', sans-serif;
  line-height: 150%;
  margin: 0 0 24px;
  color: #fff;
  text-transform: uppercase;
}
.title_h2:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin-top: 23px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.content {
  height: auto !important;
  min-height: 100%;
  position: relative;
  width: 100%;
  z-index: 3;
  padding-bottom: 20px;
  transition: 0.3s linear;
}

.content.right {
  left: 300px;
}

span.color-main {
  color: #27aae0 !important;
}

span.page {
  float: right;
}

.main_map {
  margin-bottom: -30px;
}

.activity_links {
  padding: 16px 0 0;
}
.activity_links p.hd {
  font-size: 13px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  margin: 0 0 23px;
}

.video_wrap {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 40px;
}

.video_wrap.short {
  padding-bottom: 40%;
}
.video_wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pag-wr {
  position: relative;
}
.sep_line {
  background: url(../img/sep4.png) repeat-x;
  height: 1px;
  opacity: 0.5;
}
.river-video {
  border-top: 3px solid #27aae0;
  margin-bottom: 30px;
}
.river-video .video_wrap {
  background: #ededed;
}
.river-video .video_wrap iframe {
  top: 40px;
  left: 40px;
  width: calc(100% - 80px) !important;
  height: calc(100% - 80px) !important;
  right: 40px;
}
.river-video h2.hd {
  font-size: 18px;
  font-family: 'open_sansextrabold', sans-serif;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.map_block {
  position: relative;
}
.view_buttons {
  position: absolute;
  z-index: 3;
  top: 7px;
  width: 100%;
  height: 1px;
}
.view_buttons .wr {
  padding: 0 50px;
}
.view_buttons a {
  display: inline-block;
  background: #ffba00;
  height: 40px;
  text-transform: uppercase;
  line-height: 40px;
  padding: 0 17px;
  border-radius: 40px;
  color: #222a32;
  font-family: 'open_sansbold', sans-serif;
  font-size: 13px;
}
.view_buttons a.current {
  background: #222a32;
  color: #ffba00;
}

.audioguide .hd {
  font-family: 'open_sansbold', sans-serif;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  padding: 0 50px;
  margin-bottom: 40px;
}

.show_list p.hd {
  font-size: 13px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.show_list a {
  text-decoration: underline;
}
.show_list .sep {
  background: url(../img/sep4.png) repeat-x;
  height: 1px;
  opacity: 0.5;
  margin: 29px 0 34px !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear {
  clear: both;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.full {
  text-align: justify;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.strike,
del {
  text-decoration: line-through;
}

::-webkit-input-placeholder {
  color: #000;
}

::-moz-placeholder {
  color: #000;
}

/* Firefox 19+ */
:-moz-placeholder {
  color: #000;
}

/* Firefox 18- */
:-ms-input-placeholder {
  color: #000;
}

.bg_blue {
  background: #27aae0;
}

.bg_white {
  background: white;
}

.bg_blue {
  background: #27aae0;
}

.bg_dark {
  background: #222a32;
}

.bg_gray {
  background: #eeeeee;
}

.bg_graydark {
  background: #dbdbdb;
}

.bg_overlay {
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: none;
  position: fixed;
  z-index: 101;
}

.white {
  color: white;
}

.blue {
  color: #27aae0;
}

p {
  font-size: 75%;
  margin: 0;
  line-height: 125%;
}

li p {
  margin: 0;
}

.xx-small {
  font-size: 45% !important;
}

.x-small {
  font-size: 65% !important;
}

.small {
  font-size: 70% !important;
}

.medium {
  font-size: 80% !important;
}

.large {
  font-size: 90% !important;
}

.x-large {
  font-size: 120% !important;
}

.xx-large {
  font-size: 140% !important;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-position: inside;
  zoom: 1;
}

ul li,
ol li {
  color: black;
  zoom: 1;
}

table td table td {
  font-size: 12px;
}

img.left,
img.float-left {
  float: left;
  margin: 5px 31px 20px 0;
}

img.right,
img.float-right {
  float: right;
  margin: 5px 0 20px 31px;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

ul {
  list-style: none;
  margin: 0 0 10px 4px;
  padding: 0;
}

ul li ul {
  margin: 5px 0 5px 0;
}

.popup {
  background: #fff;
  position: absolute;
  width: 700px;
  left: 50%;
  margin: 0 0 0 -350px;
  padding: 29px 30px 23px;
  box-sizing: border-box;
  display: none;
  z-index: 102;
}
.popup .close {
  width: 26px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: url(../img/close.png) no-repeat;
}
.popup .head {
  font-size: 25px;
  font-family: 'open_sansbold';
}

.socials {
  margin-top: 33px;
  text-align: center;
  padding-right: 51px;
}
.socials a {
  margin: 7px;
}
.date-img-wrap a {
  color: white;
}
.date-sep {
  background: url('../img/bridge_sep.png') left no-repeat;
  border: none;
  height: 22px;
  margin: 10px 0 20px;
}

.page-nav_arr {
  position: absolute;
  height: 40px;
  width: 40px;
  background: url(../img/arr_brown.png) center no-repeat, #fffbf4;
  border-radius: 100px;
  border: 1px solid #c8ad98;
  display: inline-block !important;
  /* // left: -15px; */
  /* // margin-right: -4px; */
  vertical-align: middle;
  /* // margin-top: 20px; */
  /* // margin-top: 38px; */
  top: 4px;
}

.page-nav_arr.left {
  left: 68px;
}
.page-nav_arr.right {
  right: 68px;
  background: url(../img/arr_brown_right.png) center no-repeat, #fffbf4;
}
.page-nav_arr.right.double {
  background: url(../img/arr-right-2.png) center no-repeat, #fffbf4;
  right: 8px;
}
.page-nav_arr.left.double {
  background: url(../img/arr-left-2.png) center no-repeat, #fffbf4;
  left: 8px;
}
.pages ul li {
  display: inline-block;
}
.pages ul li a {
  position: relative;
  width: 42px;
  height: 42px;
  background: #fffbf4;
  border: 1px solid #c7ab96;
  border-radius: 100px;
  display: block;
  line-height: 47px;
  font-size: 17px;
  margin: 4px auto;
  font-family: 'Gabriela', serif;
  /*text-shadow: 0 4px rgba(0, 0, 0, 0.2);*/
  color: #544039;
  cursor: pointer;
  margin: 0 16px;
}
.pages ul li a:after {
  content: '';
  position: absolute;
  left: -4px;
  top: -4px;
  width: 48px;
  height: 48px;
  background: #fffbf4;
  border: 1px solid #c7ab96;
  border-radius: 100px;
  z-index: -1;
}
.pages ul li a:hover:after {
  background: #8babb3;
  border: 1px solid #7f9fa6;
}
.pages ul li.active-page a {
  background: #8babb3;
  border: 1px solid #7f9fa6;
  color: white;
}
.pages ul li.active-page a:after {
  border: 1px solid #7f9fa6;
}

.podster_ribbon_link {
  display: none;
}

.tooltip {
  position: absolute;
  z-index: -1;
}

.timer {
  margin: 8px 0 8px;
}
.timer span {
  font-family: 'open_sansextrabold', sans-serif !important;
  font-size: 35px;
}
.timer div {
  min-width: 100% !important;
}

ul#example {
  list-style: none;
  margin: 50px 0;
  padding: 0;
  display: block;
  text-align: center;
}

ul#example li {
  display: inline-block;
}

ul#example li span {
  font-size: 80px;
  font-weight: 300;
  line-height: 80px;
}

ul#example li.seperator {
  font-size: 80px;
  line-height: 70px;
  vertical-align: top;
}

ul#example li p {
  color: #a7abb1;
  font-size: 25px;
}

.social_inner {
  padding: 30px 0;
}

.social_inner .ya-share2 {
  display: inline-block;
  vertical-align: top;
  margin-top: -2px;
}

.select {
  height: 42px;
}
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select-custom {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 13px;
  background: #fff;
  width: 100%;
  border-radius: 4px;
  margin-top: 1px;
  height: 40px;
}
.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 12px 15px;
  background: url(../img/arr_d.png) no-repeat 95% center, #fff;
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  margin-top: -40px;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 4px;
  overflow-y: auto;
  max-height: 250px;
}
.select-options::-webkit-scrollbar-button {
  background-image: url('');
  background-repeat: no-repeat;
  width: 5px;
  height: 0px;
}

.select-options::-webkit-scrollbar-track {
  background-color: #ecedee;
}

.select-options::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #6dc0c8;
}

.select-options::-webkit-scrollbar-thumb:hover {
  background-color: #56999f;
}

.select-options::-webkit-resizer {
  background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 0px;
}

.select-options::-webkit-scrollbar {
  width: 4px;
}
.select-options li {
  margin: 0;
  padding: 12px 0;
  text-indent: 15px;
  border-bottom: 1px solid #ccc;
  transition: all 0.15s ease-in;

  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;
}
.select-options li:last-child {
  border-bottom: 0;
}
.select-options li:hover {
  color: #fff;
  background: #27aae0;
}
.select-options li[rel='hide'] {
  display: none;
}

.right-btns {
  float: right;
}
.article-btns {
  padding: 50px 0;
  position: relative;
  margin: 50px 0 0;
}
.article-btns:before {
  background: url(/img/seige-sep.png);
  height: 3px;
  width: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}
.list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.list__item .fb_new {
  width: 100%;
}
.youth_info-img {
  width: 100%;
  margin-bottom: 30px;
}

.youth__news_list {
  border-top: 1px dashed lightgray;

  padding: 50px 0;
  margin: 20px 0;
}
.youth__bottom-text {
  border-top: 1px dashed lightgray;
  padding-top: 20px;
}
.youth__news-title {
  font-size: 30px;
  font-family: 'open_sansbold', sans-serif;
  margin: 0 0 35px;
  text-transform: uppercase;
  font-weight: normal;
}

.scroll-wr {
  overflow-x: scroll;
  overflow-y: hidden;
}
.scroll-wr::-webkit-scrollbar-button {
  background-image: url('');
  background-repeat: no-repeat;
  width: 5px;
  height: 0px;
}
.scroll-wr::-webkit-scrollbar-track {
  background-color: #ecedee;
}

.scroll-wr::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: #27aae0;
}

.scroll-wr::-webkit-scrollbar-thumb:hover {
  background-color: #56999f;
}

.scroll-wr::-webkit-resizer {
  background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 0px;
}

.scroll-wr::-webkit-scrollbar {
  width: 4px;
}

.block-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 29px;

  h2 {
    font-family: 'open_sansbold', sans-serif;
    font-size: 30px;
    margin: 0;
  }

  .link {
    font-family: 'open_sansbold', sans-serif;
    font-size: 15px;
    color: #27aae0;
    margin-left: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .block-title {
    margin-bottom: 29px !important;
  }
}

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  /* Black prints faster: h5bp.com/s */
  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }

  /* Don't show links for images, or javascript/internal links */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  /* h5bp.com/t */
  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
