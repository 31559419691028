.facts {
  padding-bottom: 17px;
}
.facts h1 {
  color: #222a32;
  text-transform: uppercase;
  font: 30px/55px 'open_sansextrabold', sans-serif;
}
.facts h1 span {
  color: #27aae0;
  display: block;
  font-size: 70px;
}
.facts .p-open {
  display: block;
  padding-top: 70px;
  box-sizing: border-box;
  text-align: center;
  max-width: 290px;
  cursor: pointer;
  overflow: hidden;
}
.facts .p-open b {
  transform: rotate(-45deg);
  display: block;
  font: 30px/55px 'open_sansextrabold', sans-serif;
}
.facts .p-open p {
  text-align: center !important;
  padding: 32px 34px !important;
  line-height: 20px;
}
.facts .p-open:before,
.facts .p-open:after {
  border-color: #dbdbdb;
  width: 0;
  height: 0;
  border-style: solid;
  content: '';
  display: block;
  position: absolute;
  right: -20px;
  top: -20px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.facts .p-open:hover:before {
  border-width: 0 40px 40px 0;
  border-color: transparent white transparent transparent;
  right: 0;
  top: 0;
}
.facts .p-open:hover:after {
  border-width: 40px 0 0 40px;
  border-color: transparent transparent transparent #bababa;
  right: 0;
  top: 0;
}

.fact {
  padding: 50px;
  width: 590px;
  height: 590px;
  margin: 0 0 0 -295px;
  transition: 0.7s linear;
  transition-delay: 0.3s;
}
.fact .rhombus {
  border-radius: 10px;
  display: block;
  margin: 10px 10px 47px;
  position: relative;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 28px;
  border: 5px solid #27aae0;
  color: #27aae0;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 10px;
  transition: 0.7s linear;
  transition-delay: 0.3s;
}
.fact .rhombus.white {
  border: 5px solid white;
  color: white;
}
.fact .rhombus.dark {
  border: 5px solid #222a32;
  color: #222a32;
}
.fact .rhombus .wysiwyg.white {
  color: white;
}
.fact .rhombus b {
  transform: rotate(-45deg);
  display: block;
  font: 30px/55px 'open_sansextrabold', sans-serif;
}
.fact .close {
  top: 25px;
  right: 25px;
  background: none;
  font-size: 19px;
  transition: 0.7s linear;
  transition-delay: 0.3s;
}
.fact .wysiwyg h3 {
  text-transform: none;
  font: 30px 'open_sanssemibold', sans-serif;
  margin: 0 0 30px;
  transition: 0.7s linear;
  transition-delay: 0.3s;
}
.fact .wysiwyg p {
  opacity: 1;
  height: auto;
  transition: 0.5s linear;
  transition-delay: 0.7s;
}
.fact.bg_dark {
  background: #222a32;
}
.fact.bg_blue {
  background: #27aae0;
}
.fact.root {
  width: 290px;
  height: 290px;
  min-height: auto;
  display: none;
  text-align: center;
  transition: 0.7s linear;
  top: auto;
  left: auto;
  transition-delay: 0.3s;
  margin-left: 0;
}
.fact.root .rhombus {
  margin: 17px 61px 47px;
}
.fact.root .close {
  opacity: 0;
  transition: 0.7s linear;
  transition-delay: 0.3s;
}
.fact.root .wysiwyg h3 {
  text-transform: uppercase;
  font: 13px 'open_sansextrabold', sans-serif;
  padding-top: 12px;
  transition: 0.7s linear;
  transition-delay: 0.3s;
}
.fact.root .wysiwyg p {
  height: 0;
  opacity: 0;
}
