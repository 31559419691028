.main-banners {
  padding: 43px 0 50px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 1190px;
    height: 1px;
    margin: auto;
    background-color: #e8e9ea;
  }

  &__gos {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 130px;
    gap: 10px;
  }

  .item {
    border-radius: 10px;
    overflow: hidden;

    & > a,
    & > span {
      display: block;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_contacts {
      background-color: #f4f4f5;
      grid-row: 1/2;
      grid-column: 2/3;
      padding: 24px 33px;

      .item__name {
        font-family: 'open_sansbold', sans-serif;
        font-size: 20px;
        margin-bottom: 11px;
      }

      .item__phone {
        font-size: 17px;
        font-family: 'open_sanssemibold', sans-serif;
        margin-bottom: 5px;

        i {
          margin: 3px 20px 0 1px;
          background-image: url(../img/icon_phone-blue.png);
        }
      }

      .item__mail {
        font-size: 17px;

        a {
          font-family: 'open_sanslight', sans-serif;
        }

        i {
          margin: 4px 26px 0px 2px;
          background-image: url(../img/icon_mail-blue.png);
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .main-banners {
    padding: 40px 0;
  }
}

@media screen and (max-width: 992px) {
  .main-banners {
    &__wrap {
      grid-template-columns: repeat(2, 1fr);
    }

    .item {
      &_contacts {
        padding: 23px 20px;
        grid-row: 1/2;
        grid-column: 1/2;

        .item__phone {
          i {
            margin-right: 10px;
          }
        }

        .item__mail {
          i {
            margin-right: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .main-banners {
    &__wrap {
      grid-template-columns: 1fr;
    }
  }
}
