.photo_list .gallery_link,
.photo_list .gallery_link_sec {
  display: block;
  padding-top: 100%;
  color: #222a32;
  margin-bottom: 10px;
  color: black;
  line-height: 25px;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}
.photo_list .gallery_link img,
.photo_list .gallery_link_sec img {
  max-width: 100%;
}
.photo_list .gallery_link:hover span.back,
.photo_list .gallery_link_sec:hover span.back {
  bottom: 0;
}
.photo_list .gallery_link span.front,
.photo_list .gallery_link_sec span.front {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 100%;
}
.photo_list .gallery_link span.front img,
.photo_list .gallery_link_sec span.front img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.photo_list .gallery_link span.back,
.photo_list .gallery_link_sec span.back {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: -100%;
  padding: 10px;
  width: 100%;
  left: 0;
  color: white;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.photo_list .gallery_link span.back .txt,
.photo_list .gallery_link_sec span.back .txt {
  position: static;
}
.photo_list .gallery_link span span,
.photo_list .gallery_link_sec span span {
  position: absolute;
  padding: 0 7% 0 10%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.photo_list .gallery_link span.name,
.photo_list .gallery_link_sec span.name {
  font-size: 18px;
  font-family: 'open_sansbold', sans-serif;
  color: white;
  line-height: 26px;
  top: 29px;
  height: 130px;
  overflow: hidden;
}
.photo_list .gallery_link span.name h2,
.photo_list .gallery_link_sec span.name h2 {
  display: inline;
  font-size: inherit;
}
.photo_list .gallery_link span.txt,
.photo_list .gallery_link_sec span.txt {
  line-height: 18px;
  /* // top: 60%; */
  top: 0;
  height: 55px;
  overflow: hidden;
}
.photo_list .gallery_link span.txt b,
.photo_list .gallery_link_sec span.txt b {
  display: block;
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
}
.photo_list .gallery_link span.count,
.photo_list .gallery_link_sec span.count {
  font-size: 12px;
  font-family: 'open_sanssemibold', sans-serif;
  text-transform: uppercase;
}
.photo_list .gallery_link span.bg_blue,
.photo_list .gallery_link_sec span.bg_blue {
  display: block;
}
.photo_list .gallery_link.brige_p_l .btn,
.photo_list .gallery_link_sec.brige_p_l .btn {
  border: 2px solid #fff;
  height: 24px;
  line-height: 25px;
  border-radius: 30px;
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  padding: 0 15px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  transition: all 0.3s ease;
}
.photo_list .gallery_link.brige_p_l .btn:hover,
.photo_list .gallery_link_sec.brige_p_l .btn:hover {
  background: #fff;
  color: #000;
}
.photo_list .gallery_link.brige_p_l span span,
.photo_list .gallery_link_sec.brige_p_l span span {
  position: relative;
  display: block;
}
.photo_list .gallery_link.brige_p_l span span.name,
.photo_list .gallery_link_sec.brige_p_l span span.name {
  top: 32px;
  height: 90px;
  font-size: 18px;
}
.photo_list .gallery_link.brige_p_l span span.count,
.photo_list .gallery_link_sec.brige_p_l span span.count {
  margin-bottom: 10px;
}
.photo_list .gallery_link.brige_p_l span span.txt,
.photo_list .gallery_link_sec.brige_p_l span span.txt {
  line-height: 20px;
  height: 80px;
}
.photo_list .gallery_link_sec span.bg_text {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.gallery_list .gallery_link_sec {
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}
.gallery_list .gallery_link_sec span.img {
  display: block;
  position: relative;
  overflow: hidden;
}
.gallery_list .gallery_link_sec span.img img {
  width: 100%;
  display: block;
  transition: transform 0.25s;
  transform: scale(1) translate3d(0, 0, 0);
}
.gallery_list .gallery_link_sec span.img:hover img {
  transform: scale(1.1) translate3d(0, 0, 0);
}
.gallery_list .gallery_link_sec span.img.bg_blue {
  font-size: 25px;
  font-family: 'open_sansbold', sans-serif;
  color: white;
  padding: 29px 30px 0 30px;
  height: 190px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.gallery_list .gallery_link_sec span.img .rhombus {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 28px;
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -30px;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gallery_list .gallery_link_sec span.img .rhombus.white {
  border: 5px solid white;
}
.gallery_list .gallery_link_sec span.img .rhombus i {
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.gallery_list .gallery_link_sec span.txt {
  display: block;
  font-size: 12px;
  line-height: 15px;
  color: #222a32;
  text-transform: uppercase;
  padding: 28px 30px 24px;
  height: 100px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.gallery_list .gallery_link_sec span.txt span.dotdotdot {
  height: 15px;
  overflow: hidden;
  display: block;
  word-wrap: break-word;
}
.gallery_list .gallery_link_sec span.txt b {
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  font-weight: normal;
  display: block;
  margin-top: 3px;
}
