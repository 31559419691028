.prs_center {
  padding-bottom: 20px;
}
.prs_center .cnt {
  position: relative;
  overflow: hidden;
}
.prs_center .cnt_one {
  position: relative;
  padding-top: 100%;
  border-radius: 10px;
}
.prs_center .cnt_one .rhombus {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 28px;
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -30px;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.prs_center .cnt_one .rhombus.dark {
  border: 5px solid #222a32;
}
.prs_center .cnt_one .rhombus i {
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  color: #222a32;
}
.prs_center .cnt_one p {
  color: #222a32;
  font-size: 15px;
}
.prs_center .cnt_one p.hd {
  font-size: 13px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  top: 35px;
  left: 40px;
}
.prs_center .cnt_one p a {
  color: #222a32;
  font-size: 15px;
}
.prs_center .cnt_one .txt_bottom {
  position: absolute;
  width: 100%;
  padding: 0 0 0 40px;
  bottom: 33px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.prs_center .sep {
  background: url(../img/sep4.png) repeat-x;
  height: 1px;
  opacity: 0.5;
  margin: 40px 0;
}
.prs_center .contact_txt .sep {
  margin-bottom: 40px;
}
.prs_center .contact_txt:after {
  content: '';
  clear: both;
  display: block;
}
.prs_center .contact_txt .user_img {
  border: 5px solid #27aae0;
  float: left;
  width: 210px;
  height: 210px;
  position: relative;
  -webkit-border-radius: 210px;
  -moz-border-radius: 210px;
  border-radius: 210px;
  margin-right: 40px;
  margin-bottom: 25px;

  @media (max-width: 500px) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
.prs_center .contact_txt .user_img:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 35px 30px;
  border-color: transparent #27aae0;
  content: '';
  display: block;
  position: absolute;
  right: -26px;
  top: 63px;
  z-index: 1;
}
.prs_center .contact_txt .user_img:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 36px 32px;
  border-color: transparent white;
  content: '';
  display: block;
  position: absolute;
  right: -15px;
  top: 68px;
  z-index: 2;
}
.prs_center .contact_txt .user_img span {
  background: white;
  display: block;
  padding: 10px;
  width: 190px;
  height: 190px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 190px;
  -moz-border-radius: 190px;
  border-radius: 190px;
  z-index: 3;
}
.prs_center .contact_txt .user_img span span {
  padding: 0;
}
.prs_center .contact_txt .wysiwyg {
  padding-top: 9px;
}
.prs_center .contact_txt .wysiwyg p {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
}
.prs_center .contact_txt .wysiwyg p.name {
  font-size: 15px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
  margin: 29px 0 7px;
}
.prs_center .contact_txt .wysiwyg p.status {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  margin: 0;
}
.prs_center .form {
  background: #eee;
  padding: 46px 50px;
  @media (max-width: 500px) {
    padding: 20px;
  }
}
.prs_center .form p {
  color: black;
}
.prs_center .form p.hd {
  color: black;
}
.prs_center .form .input input {
  background: white;
  color: black;
}
.prs_center .form .input i {
  color: #27aae0;
  opacity: 1;
}
.prs_center .form .input p {
  color: black;
}
.prs_center .form .textarea textarea {
  background: white;
  color: black;
}
.prs_center .form .textarea p {
  color: black;
}
.prs_center .form .textarea i {
  color: #27aae0;
  opacity: 1;
}
.prs_center .p_blocks .bl {
  height: 290px;
  position: relative;
  margin-bottom: 10px;
}
.prs_center .p_blocks .bl p.hd {
  font-size: 13px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  padding: 34px 10px 34px 40px;
  text-align: left;
}
.prs_center .p_blocks .bl p.hd:after {
  content: '';
  display: block;
  width: 55px;
  height: 3px;
  margin-top: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.prs_center .p_blocks .bl p.hd.line_blue:after {
  background: #27aae0;
}
.prs_center .p_blocks .bl p.hd.line_white:after {
  background: white;
}
.prs_center .p_blocks .bl p.date {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  padding: 0 0 8px 40px;
}
.prs_center .p_blocks .bl a.show_all {
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  position: absolute;
  width: 100%;
  padding: 0 0 0 40px;
  left: 0;
  bottom: 37px;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.prs_center .p_blocks .bl.center a.show_all {
  padding: 0;
}
.prs_center .p_blocks .bl .rhombus {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  display: block;
  margin: -2px auto 27px;
  position: relative;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 28px;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.prs_center .p_blocks .bl .rhombus.white {
  border: 5px solid white;
}
.prs_center .p_blocks .bl .rhombus.dark {
  border: 5px solid #222a32;
  color: #222a32;
}
.prs_center .p_blocks .bl .rhombus.blue {
  border: 5px solid #27aae0;
}
.prs_center .p_blocks .bl .rhombus.bg_blue {
  color: white !important;
}
.prs_center .p_blocks .bl .rhombus i {
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.prs_center .p_blocks .bl .txt {
  padding: 0 20px 0 40px;
}
.prs_center .p_blocks .bl .txt a.details {
  font-size: 12px;
  text-decoration: underline;
}
.prs_center .p_blocks .bl .txt p {
  line-height: 20px;
  font-size: 12px;
  margin-bottom: 8px;
  height: 60px;
  position: relative;
  overflow: hidden;
}
.prs_center .p_blocks .bl.bg_dark .txt p,
.prs_center .p_blocks .bl.bg_blue .txt p {
  color: white;
}
