.head {
  font-size: 50px;
  font-family: 'open_sanslight', sans-serif;
  margin: 0 0 35px;
  font-weight: normal;
}
.head:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin: 15px 0 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.head.sec {
  margin-bottom: 23px;
}
.head.sec:after {
  display: none;
}
.head.sec_br {
  margin-bottom: 56px;
}
.head.sec_br:after {
  display: none;
}

.heading {
  font-size: 30px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  padding: 35px 0;
  margin: 0;
}
