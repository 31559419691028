.main-projects {
  padding: 22px 0 56px;

  .slider {
    // white-space: nowrap;

    .slick-list {
      margin: 0 -5px;
    }

    .slick-slide {
      padding: 0 5px;
    }

    .slick-prev,
    .slick-next {
      width: 34px;
      height: 34px;
      z-index: 2;

      &:before {
        content: '';
        font-family: none;
        font-size: 0;
        line-height: 0;
        width: 100%;
        height: 100%;
        display: block;
        opacity: 1;
        background-size: 34px 34px;
      }
    }

    .slick-prev {
      left: -63px;

      &:before {
        background-image: url(../img/arr-l-circle.png);

        @media only screen and (-moz-min-device-pixel-ratio: 1.5),
          only screen and (-o-min-device-pixel-ratio: 3 / 2),
          only screen and (-webkit-min-device-pixel-ratio: 1.5),
          only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url(../img/arr-l-circle@2x.png);
        }
      }
    }

    .slick-next {
      right: -63px;

      &:before {
        background-image: url(../img/arr-r-circle.png);

        @media only screen and (-moz-min-device-pixel-ratio: 1.5),
          only screen and (-o-min-device-pixel-ratio: 3 / 2),
          only screen and (-webkit-min-device-pixel-ratio: 1.5),
          only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url(../img/arr-r-circle@2x.png);
        }
      }
    }
  }

  .slide {
    &__image {
      display: block;
      position: relative;
      padding-top: 62%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #e8e9ea;
      padding: 29px 31px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
    }

    &__title {
      font-family: 'open_sansbold', sans-serif;
      font-size: 17px;
      line-height: 1.4;
      display: block;
      margin-bottom: 19px;
    }

    &__links {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 3px;
    }

    &__link {
      font-family: 'open_sanssemibold', sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      background-color: #27aae0;
      color: #ffffff;
      border-radius: 100px;
      padding: 6px 12px;
    }
  }
}

@media screen and (max-width: 1330px) {
  .main-projects {
    .slider {
      .slick-prev {
        left: 10px;
      }

      .slick-next {
        right: 10px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-projects {
    padding: 20px 0 40px;

    .slide {
      &__content {
        padding: 20px;
      }

      &__title {
        font-size: 15px;
      }
    }
  }
}
