.select2-container {
  border: 1px solid transparent;
  border-radius: 3px;
}

.success {
  font-size: 20px;
  font-family: 'open_sanssemibold', sans-serif;
  margin: 0 0 15px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #27aae0 !important;
}

.checkboxContainer {
  display: flex;
  align-items: flex-start;
  user-select: none;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.checkboxContainer input[type='checkbox'] {
  cursor: pointer;
  z-index: 9;
  height: 30px;
  min-width: 24px;
  opacity: 0;
}
.checkboxContainer input[type='checkbox'] + label {
  font-size: 1em;
  display: grid;
  grid-template-columns: auto 3fr;
  margin-left: -27px;
}
.checkboxContainer input[type='checkbox'] + label svg {
  width: 2em;
  stroke: #27aae0;
  stroke-width: 5;
  fill: transparent;
}
.checkboxContainer input[type='checkbox'] + label svg .box {
  stroke-dasharray: 320;
  stroke-dashoffset: 0;
  fill: transparent;
  transition: stroke-dashoffset 0.3s linear;
}
.checkboxContainer input[type='checkbox'] + label svg .check {
  stroke-dasharray: 70;
  stroke-dashoffset: 70;
  fill: none;
  transition: stroke-dashoffset 0.3s linear;
}
.checkboxContainer input[type='checkbox'] + label span {
  padding-top: 0.3em;
  margin-left: 0.3em;
}
.checkboxContainer input[type='checkbox']:checked + label svg * {
  stroke: #27aae0;
}
.checkboxContainer input[type='checkbox']:checked + label .box {
  stroke-dashoffset: 320;
}
.checkboxContainer input[type='checkbox']:checked + label .check {
  stroke-dashoffset: 0;
}
.checkboxContainer input[type='checkbox'].error + label svg .box {
  animation: errorCheckBox 1s infinite linear 1s;
}
.checkboxContainer input[type='checkbox'].error:checked + label svg * {
  stroke: #27aae0;
}
.checkboxContainer .checkboxText {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  margin-left: 10px;
  color: #fff;
  margin-top: 3px;
}
.checkboxContainer .checkboxText a {
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
}
.form_grey .checkboxContainer .checkboxText,
.form_grey .checkboxContainer .checkboxText a {
  color: #000;
}
.form_dark .checkboxContainer input[type='checkbox'] {
  width: 48px;
}
.checkboxContainer .checkboxText a:hover {
  color: #27aae0 !important;
}

.form p {
  font-size: 13px;
  color: white;
  margin: 0;
}
.form p.mandatory {
  font-size: 12px;
}
.form p.mandatory span {
  color: #27aae0;
  font-size: 20px;
  display: inline-block;
  height: 0;
  vertical-align: top;
  margin: 4px 5px 0 0;
}
.form p.hd {
  font-size: 20px;
  font-family: 'open_sansextrabold', sans-serif;
  margin: 0 0 32px;
  text-transform: uppercase;
  color: #27aae0;
}
.form p.success {
  font-size: 20px;
  font-family: 'open_sanssemibold', sans-serif;
  margin: 0 0 15px;
}
.form p.error {
  background: #27aae0;
  position: absolute;
  display: none !important;
  right: 0;
  top: -23px;
  font-size: 12px;
  padding: 5px 6px 7px;
  margin: 0 !important;
  display: none;
  white-space: nowrap;
}
.form p.error:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #27aae0 transparent transparent transparent;
  display: block;
  position: absolute;
  right: 8px;
  bottom: -7px;
}
.form .input {
  position: relative;
  margin: 0 0 21px;
}
.form .input.hid p {
  width: 0;
  font-size: 0;
}
.form .input p {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  padding: 11px 0 0 48px;
  cursor: text;
  overflow: hidden;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.form .input p i {
  position: absolute;
  left: 18px;
  top: 12px;
  font-size: 14px;
  opacity: 0.1;
}
.form .input p span span {
  color: #27aae0;
  display: inline-block;
  vertical-align: top;
  height: 0;
}
.form .input p span b {
  font-weight: normal;
}
.form .input p span i {
  display: none;
  color: black !important;
  font-style: normal;
  position: static;
}
.form .input input {
  background: #2d353c;
  border: 1px solid transparent;
  width: 100%;
  padding: 10px 10px 10px 48px;
  font-size: 13px;
  color: white;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.form .input input.error {
  -webkit-animation: error 1s infinite linear 1s;
  animation: error 1s infinite linear 1s;
}
.form .textarea {
  margin: 0 0 15px;
  position: relative;
}
.form .textarea.hid p {
  width: 0;
  font-size: 0;
}
.form .textarea p {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  padding: 11px 0 0 48px;
  cursor: text;
  overflow: hidden;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.5s linear;
  -moz-transition: 0.5s linear;
  -ms-transition: 0.5s linear;
  -o-transition: 0.5s linear;
  transition: 0.5s linear;
}
.form .textarea p i {
  position: absolute;
  left: 18px;
  top: 12px;
  font-size: 14px;
  opacity: 0.1;
}
.form .textarea p span span {
  color: #27aae0;
  display: inline-block;
  vertical-align: top;
  height: 0;
}
.form .textarea textarea {
  background: #2d353c;
  border: 1px solid transparent;
  width: 100%;
  height: 160px;
  color: white;
  padding: 11px 10px 11px;
  font-size: 13px;
  text-indent: 36px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.form .textarea textarea.error {
  -webkit-animation: error 1s infinite linear 1s;
  animation: error 1s infinite linear 1s;
}
.form input.btn_send {
  background: #27aae0;
  border: 0;
  padding: 6px 15px 5px;
  text-transform: uppercase;
  vertical-align: top;
  float: right;
  color: #121313;
  font-size: 14px;
  font-family: 'open_sansbold', sans-serif;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}
.form input.btn_send:hover {
  background: #e2e2e2;
}
.form .error.select2 {
  -webkit-animation: error 1s infinite linear 1s;
  animation: error 1s infinite linear 1s;
}
.form .captcha p {
  float: left;
  margin: 14px 0 0;
}
.form .captcha img {
  float: left;
  margin: 8px 12px 0 0;
}
.form .captcha .input {
  float: left;
  width: 50px;
  margin: 2px 0 0 16px;
}
.form .captcha .input p {
  padding: 12px 0 0;
  margin: 0;
  text-align: center;
}
.form .captcha .input input {
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}
.form button {
  background: #27aae0;
  border: 0;
  display: inline-block;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-decoration: none;
  padding: 0 12px;
  height: 24px;
  line-height: 24px;
  color: white;
  margin: 8px 0 0;
  float: right;
  text-transform: uppercase;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.form .file {
  position: relative;
  margin: 0 0 21px;
}
.form .file.hid p .input {
  font-size: 0;
}
.form .file p {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  padding: 11px 0 0 48px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  z-index: 1;
}
.form .file p i {
  position: absolute;
  left: 18px;
  top: 12px;
  font-size: 14px;
  opacity: 0.1;
}
.form .file p span span {
  color: #27aae0;
  display: inline-block;
  vertical-align: top;
  height: 0;
}
.form .file p span.button {
  background: #eee;
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}
.form .file input.fileinput {
  font-size: 0;
  opacity: 0;
  position: absolute;
}
.form .file input.input {
  background: #2d353c;
  border: 1px solid transparent;
  width: 100%;
  padding: 10px 10px 10px 48px;
  font-size: 13px;
  color: white;
  margin: 0;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -ms-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.form .file input.input.error {
  -webkit-animation: error 1s infinite linear 1s;
  animation: error 1s infinite linear 1s;
}
.form.light p {
  color: black;
}
.form.light p.hd {
  font-size: 15px;
  font-family: 'open_sanssemibold', sans-serif;
  margin-bottom: 22px;
  padding-top: -10px;
}
.form.light p.hh {
  font-size: 13px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #27aae0;
  margin-top: -10px;
}
.form.light fieldset {
  margin-bottom: 25px;
}
.form.light .input p {
  color: black;
  padding: 11px 0 0 15px;
}
.form.light .input p i {
  opacity: 1;
}
.form.light .input input {
  background-color: white;
  color: black;
}
.form.light .file .input {
  background: white;
  color: black;
}
.form.light .file i {
  color: #27aae0;
  opacity: 1;
}
.form.light .textarea p {
  color: black;
  padding: 11px 0 0 15px;
}
.form.light .textarea p i {
  opacity: 1;
  color: #27aae0;
}
.form.light .textarea textarea {
  background-color: white;
  color: black;
}

.light.big_form {
  margin-bottom: -16px;
}
.light.big_form .col-xs-1,
.light.big_form .col-ss-1,
.light.big_form .col-s-1,
.light.big_form .col-md-1,
.light.big_form .col-lg-1,
.light.big_form .col-xs-2,
.light.big_form .col-ss-2,
.light.big_form .col-s-2,
.light.big_form .col-sm-2,
.light.big_form .col-md-2,
.light.big_form .col-lg-2,
.light.big_form .col-xs-3,
.light.big_form .col-ss-3,
.light.big_form .col-s-3,
.light.big_form .col-sm-3,
.light.big_form .col-md-3,
.light.big_form .col-lg-3,
.light.big_form .col-xs-4,
.light.big_form .col-ss-4,
.light.big_form .col-s-4,
.light.big_form .col-sm-4,
.light.big_form .col-md-4,
.light.big_form .col-lg-4,
.light.big_form .col-xs-5,
.light.big_form .col-ss-5,
.light.big_form .col-s-5,
.light.big_form .col-sm-5,
.light.big_form .col-md-5,
.light.big_form .col-lg-5,
.light.big_form .col-xs-6,
.light.big_form .col-s-6,
.light.big_form .col-ss-6,
.light.big_form .col-sm-6,
.light.big_form .col-md-6,
.light.big_form .col-lg-6,
.light.big_form .col-xs-7,
.light.big_form .col-ss-7,
.light.big_form .col-s-7,
.light.big_form .col-sm-7,
.light.big_form .col-md-7,
.light.big_form .col-lg-7,
.light.big_form .col-xs-8,
.light.big_form .col-ss-8,
.light.big_form .col-s-8,
.light.big_form .col-sm-8,
.light.big_form .col-md-8,
.light.big_form .col-lg-8,
.light.big_form .col-xs-9,
.light.big_form .col-ss-9,
.light.big_form .col-s-9,
.light.big_form .col-sm-9,
.light.big_form .col-md-9,
.light.big_form .col-lg-9,
.light.big_form .col-xs-10,
.light.big_form .col-ss-10,
.light.big_form .col-s-10,
.light.big_form .col-sm-10,
.light.big_form .col-md-10,
.light.big_form .col-lg-10,
.light.big_form .col-xs-11,
.light.big_form .col-ss-11,
.light.big_form .col-s-11,
.light.big_form .col-sm-11,
.light.big_form .col-md-11,
.light.big_form .col-lg-11,
.light.big_form .col-xs-12,
.light.big_form .col-ss-12,
.light.big_form .col-s-12,
.light.big_form .col-sm-12,
.light.big_form .col-md-12,
.light.big_form .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.light.big_form a.button {
  height: 24px;
  line-height: 24px;
  padding: 4px 12px 4px 30px;
  background: #27aae0;
  font: 12px 'open_sansregular', sans-serif;
  color: #fff;
  text-transform: none;
  margin: 0 0 5px;
  position: relative;
  box-sizing: border-box;
}
.light.big_form a.button:before,
.light.big_form a.button:after {
  background: #fff;
  content: '';
  display: block;
  position: absolute;
  border-radius: 3px;
}
.light.big_form a.button:before {
  width: 10px;
  height: 2px;
  top: 11px;
  left: 12px;
}
.light.big_form a.button:after {
  width: 2px;
  height: 10px;
  top: 7px;
  left: 16px;
}
.light.big_form a.button.remove-exp-block:after {
  display: none;
}
.light.big_form p.hd {
  padding-top: 5px;
}
.light.big_form .input {
  margin-bottom: 10px;
}
.light.big_form .textarea textarea {
  height: 120px;
  text-indent: 5px;
}
.light.big_form .button.b-file {
  color: #000;
  margin-top: 0;
}
.light.big_form .input input {
  padding-left: 15px;
}
.light.big_form .doc_slide {
  margin-bottom: 35px;
}
.light.big_form .doc_slide .doc_text {
  padding-left: 0;
}
.light.big_form .doc_slide p.date {
  padding: 28px 87px 28px 0px;
  font-size: 15px;
}
.light.big_form .doc_slide p.date span {
  color: #222a32;
  top: 26px;
}
.light.big_form .doc_slide p.date i {
  top: 28px;
}
.light.big_form .doc_slide p.date i:before,
.light.big_form .doc_slide p.date i:after {
  background: #222a32;
}
.light.big_form .input p span span,
.light.big_form .textarea p span span {
  color: #231f20;
}
.light.big_form .ez-checkbox {
  background: transparent url(../img/check_out.png);
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 6px 0 0 !important;
  padding: 0 !important;
  position: relative;
  cursor: pointer;
  vertical-align: top;
  border-radius: 8px;
}
.light.big_form .checkbox > .ez-checkbox {
  border-radius: 8px;
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  position: absolute;
  left: 0;
}
.light.big_form .checkbox > .ez-checkbox.error {
  -webkit-animation: error 1s infinite linear 1s;
  animation: error 1s infinite linear 1s;
}
.light.big_form .ez-checked {
  background: transparent url(../img/check_in.png);
}
.light.big_form .ez-checkbox input {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.light.big_form .ez-hide {
  opacity: 0;
  filter: alpha(opacity=0);
}
.light.big_form .checkbox span {
  font-size: 12px;
  vertical-align: middle;
}
.light.big_form .checkbox {
  line-height: 39px;
  margin-bottom: 45px;
  display: block;
  margin-top: -8px;
  padding-left: 50px;
}
.light.big_form .doc_slide.mtb {
  margin-bottom: 44px;
  margin-top: 44px;
}

@keyframes error {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #27aae0;
  }
  100% {
    border-color: transparent;
  }
}
@-webkit-keyframes error {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #27aae0;
  }
  100% {
    border-color: transparent;
  }
}
.bg_gray .light.big_form {
  margin-bottom: 0px;
}

@keyframes errorCheckBox {
  0% {
    stroke: transparent;
  }
  50% {
    stroke: #27aae0;
  }
  100% {
    stroke: transparent;
  }
}
@-webkit-keyframes error {
  0% {
    stroke: transparent;
  }
  50% {
    stroke: #27aae0;
  }
  100% {
    stroke: transparent;
  }
}
