.plate-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(270px, auto);
  gap: 10px;
  font-size: 13px;

  &__item {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    padding: 43px;
    line-height: 1.5;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 7px;
      height: 100%;
      background-color: #27aae0;
    }

    &-link {
      &_doc {
        &:after {
          content: '';
          position: absolute;
          right: 24px;
          bottom: 21px;
          background-image: url(../img/download.png);
          background-size: 16px 21px;
          width: 16px;
          height: 21px;

          @media only screen and (-moz-min-device-pixel-ratio: 1.5),
            only screen and (-o-min-device-pixel-ratio: 3 / 2),
            only screen and (-webkit-min-device-pixel-ratio: 1.5),
            only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(../img/download@2x.png);
          }
        }
      }
    }
  }
}
