.regiment {
  background: #efecdb;
}
.regiment .header__middle .menu .sub,
.regiment .mob_m {
  background: #224a05;
}
.regiment .header__middle .menu .sub:before,
.regiment .mob_m:before {
  border-color: transparent transparent #224a05 transparent;
}
.regiment .header__middle .header__top-lang a.current {
  background: #459400 !important;
}
.regiment .header__top {
  background: #224a05;
}
.regiment .header__middle .menu li a.current,
.regiment .header__middle .menu li span.current {
  color: #f61d0c;
}
.regiment .header__bottom {
  display: none;
}
.regiment .header__top .header__top-lang a,
.regiment .header__middle .header__top-lang a {
  background: #142b00;
}
.regiment .header__top .header__top-lang a.current,
.regiment .header__middle .header__top-lang a.current {
  background: #efecdb;
}
.regiment .breadcrumbs {
  background: #142b00;
  margin: 17px 0 0px;
}
.regiment .breadcrumbs span {
  color: #ffffff;
}
.regiment .breadcrumbs span a {
  font-size: 12px;
  color: #efecdb;
}
.regiment .icon.arr_d {
  background-image: url(../img/arr_d_black.png);
}
.regiment .main-content {
  padding-top: 35px;
  padding-bottom: 50px;
}
.regiment .head {
  font-family: 'open_sanssemibold';
}
.regiment .head:after {
  background: #f7210f;
}
.regiment .head.polk_list {
  text-align: center;
  font-size: 50px;
}
.regiment .head.polk_list:after {
  margin: 15px auto 0;
}
.regiment .head.polk_list i.star:first-of-type {
  transform: rotateY(180deg);
}
.regiment i.star {
  content: '';
  display: inline-block;
  width: 82px;
  height: 44px;
  background: url(../img/star@2x.png) no-repeat;
  margin: 0 15px;
}
.regiment h3 {
  font-size: 25px;
  position: relative;
  margin-bottom: 50px;
}
.regiment .red {
  color: #f7210f;
  font-size: 24px;
}
.regiment a.button {
  font-family: 'open_sansextrabold';
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 15px;
  margin: 0 auto;
  background: #f7210f;
  color: #fff;
}
.regiment footer {
  background: #142b00;
}
.regiment footer .footer__top {
  background: #b1aa9b;
}
.regiment footer .footer__top p.hd {
  color: #000;
}
.regiment footer p {
  color: rgba(224, 218, 206, 0.82);
}
.regiment footer p span,
.regiment footer p a {
  color: #fff !important;
}
.regiment footer hr {
  background: none;
  border-bottom: 1px dotted rgba(239, 236, 219, 0.3);
}
.regiment .person-title h1 {
  font-size: 40px;
  margin: 0;
  line-height: 1.1em;
}
.regiment .person-title span {
  display: block;
  font-size: 24px;
  font-family: 'open_sanssemibold', sans-serif;
}
.regiment .person-title + p {
  line-height: 239%;
  margin-bottom: 55px;
  font-family: 'open_sanssemibold', sans-serif;
}
.regiment .person-diskr {
  font-size: 17px;
  line-height: 35px;
}
.regiment .red.bold {
  font-size: 23px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.regiment .wysiwyg {
  margin: 30px 0;
}
.regiment .wysiwyg p {
  line-height: 220%;
}
.regiment .wysiwyg.centr p {
  font-size: 17px;
  line-height: 34px;
}
.regiment .wysiwyg.centr:after {
  background: #f7210f;
  content: '';
  display: block;
  width: 90px;
  height: 5px;
  margin: 15px auto 0;
  border-radius: 5px;
}
.regiment .wysiwyg img {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: 100px;
  margin-bottom: 40px;
  float: right;
}
.regiment .to-video {
  background: rgba(49, 25, 9, 0.08);
  padding: 40px 0;
  margin-bottom: -50px;
  margin-top: 40px;
  border-top: 1px dotted rgba(130, 132, 132, 0.48);
}
.regiment .to-video .caption {
  background: #f3eee4;
  display: inline-block;
  font-family: 'open_sansextrabold';
  font-size: 18px;
  text-transform: uppercase;
  padding: 6px 10px;
  border: 3px solid #b1aa9b;
  border-radius: 90px;
  position: relative;
  z-index: 5;
  margin-bottom: 40px;
}
.regiment .to-video .caption:after {
  content: '';
  position: absolute;
  display: block;
  right: -37px;
  background: url(../img/tape.png);
  width: 42px;
  height: 41px;
  top: -2px;
  z-index: 3;
}
.regiment .to-video .caption:before {
  content: '';
  position: absolute;
  display: block;
  left: -36px;
  background: url(../img/tape2.png);
  width: 46px;
  height: 43px;
  top: -3px;
  z-index: 3;
}
.regiment .to-video iframe {
  height: 675px;
}
@media screen and (max-width: 1200px) {
  .regiment .to-video iframe {
    height: 550px;
  }
}
@media screen and (max-width: 992px) {
  .regiment .to-video iframe {
    height: 420px;
  }
}

@media screen and (max-width: 768px) {
  .regiment .to-video iframe {
    height: 350px;
  }
}
.regiment .honors {
  background: rgba(49, 25, 9, 0.08);
  margin-bottom: -50px;
  padding: 5px 0;
  margin-top: 40px;
}
.regiment .honors .col-sm-3 {
  min-height: 375px;
}
.regiment .honors h3 {
  text-transform: uppercase;
  text-align: center;
  font-family: 'open_sansbold', sans-serif;
  font-size: 30px;
  margin-bottom: 30px;
}
.regiment .honors img {
  border: 15px solid #f4efe5;
  box-shadow: 0 0 0px 1px #d9d2c4;
  margin: 5px 5px 20px;
  max-width: 100%;
  box-sizing: border-box;
}
.regiment .honors p {
  font-family: 'open_sanssemibold', sans-serif;
  margin-bottom: 25px;
  padding: 0 3em;
}
.regiment .siluet i {
  background: url(../img/siluet-white.png);
}
.regiment .pr_link:before {
  background-image: url(../img/pr_logo-white.png);
}
.regiment .copyright span {
  color: #fff;
}
.regiment .persons {
  display: none;
}
.regiment .img-wrap {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  max-width: 250px;
  height: auto;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  margin: 5px 10px;
  position: relative;
}
.regiment .img-wrap:after {
  content: '';
  display: inline-block;
  position: absolute;
  background: url(../img/corner-bottom.png);
  width: 50px;
  height: 50px;
  bottom: -3px;
  left: -3px;
  z-index: 3;
}
.regiment .img-wrap:before {
  content: '';
  display: inline-block;
  position: absolute;
  background: url(../img/corner-top.png) no-repeat;
  width: 50px;
  height: 50px;
  top: -3px;
  right: -3px;
  z-index: 3;
}
.regiment .img-wrap.person-wrap {
  max-height: 570px;
  max-width: 420px;
  padding: 13px;
  box-sizing: border-box;
  margin-left: 0;
  border-radius: 7px;
}
.regiment .img-wrap img {
  width: 100%;
  height: 100%;
}
.persons-open {
  transition: all 0.3s ease;
}
.persons-open.off {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.slider-polk,
.regiment .persons {
  margin-bottom: 45px;
}
.slider-polk p,
.regiment .persons p {
  font-size: 14px;
  margin-bottom: 5px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.slider-polk h4,
.regiment .persons h4 {
  font-size: 20px;
  margin: 15px auto 10px;
  letter-spacing: 1px;
}
.slider-polk em,
.regiment .persons em {
  font-size: 12px;
  font-family: 'open_sansitalic';
}
.slider-polk li,
.regiment .persons li {
  text-align: center;
  min-height: 510px;
}
.slider-polk .slick-arrow,
.regiment .persons .slick-arrow {
  height: 55px;
  width: 55px;
  border-radius: 55px;
  font-size: 1em;
  margin-top: -60px;
  opacity: 1;
  transition: all 0.3s ease;
  background: #d0cabd url(../img/arr_r@2x.png) center no-repeat;
}
.slider-polk .slick-arrow.off,
.regiment .persons .slick-arrow.off {
  opacity: 0;
}
.slider-polk .slick-next,
.regiment .persons .slick-next {
  right: -50px;
  background-position-x: 55%;
}
.slider-polk .slick-prev,
.regiment .persons .slick-prev {
  left: -50px;
  background: #d0cabd url(../img/arr_l@2x.png) center no-repeat;
  background-position-x: 45%;
}

.regiment .header__middle .logo {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: calc(100% + 33px);
    bottom: -3px;
    width: 49px;
    height: 101px;
    pointer-events: none;
    background-image: url('../img/80.png');
    background-size: cover;
  }
}

@media screen and (max-width: 992px) {
  .regiment .header__middle .logo {
    position: relative;

    &:after {
      height: 63px;
      width: 30px;
      left: calc(100% + 10px);
      bottom: 0;
    }
  }
}
