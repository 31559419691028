.history-day .header__middle .logo {
  background-image: url(../img/history-logo.png);
}
.history-day .mob_m {
  background: #8aaeb7;
}
.history-day .header__middle .menu .sub {
  background: #89aeb6;
}
.history-day .header__middle .menu .sub:before {
  border-color: transparent transparent #89aeb6 transparent;
}
.history-day .icon.arr_d {
  filter: saturate(46%);
}
.history-day .header__middle .menu li a.current,
.history-day .header__middle .menu li span.current {
  color: #89aeb6;
}
.history-day .menu li {
  color: #54413a;
}
.history-day .header__top {
  background: url(../img/blue_bg.png) repeat;
}
.history-day .icon.mail {
  background-image: url(../img/phone.png);
}
.history-day .icon.phone {
  background-image: url(../img/mail.png);
}
.history-day .content {
  padding-bottom: 0;
}
.history-day .header__bottom {
  display: none;
}
.history-day .header__middle {
  padding-bottom: 20px;
  position: relative;
}
.history-day .header__middle:after {
  background: url(../img/jubilee-wrap.png) repeat-x;
  content: '';
  width: 100%;
  position: absolute;
  bottom: -4px;
  height: 20px;
  left: 0;
  z-index: 1;
}
.history-day .breadcrumbs {
  margin: 0;
  background: #e0dbd0;
}
.history-day .header__top-lang a.current {
  background: #e6a628;
}
.history-day .header__top-lang a {
  background: #8db3bc;
}

.history-day footer .footer__top {
  background: #181f20;
}
.history-day footer .footer__top p:last-child a,
.history-day .copyright span {
  color: #8db3bc;
}
.history-day footer p span,
.history-day footer p span a {
  color: #8db3bc !important;
}
.history-day footer .footer__top p:last-child a:after {
  background: #8db3bc;
}
.history-day .pr_link:before,
.history-day .siluet i {
  filter: grayscale(70%);
}
.history-day .header__top-spec {
  filter: hue-rotate(184deg);
  background: url(../img/eye-blue.png) no-repeat 10px 6px, #585858;
}
.history__head {
  position: relative;
  padding: 20px 0 73px;
}
.history__head:after {
  background: url(../img/jubilee-wrap.png) repeat-x;
  content: '';
  width: 100%;
  position: absolute;
  bottom: -4px;
  height: 20px;
  left: 0;
  z-index: 1;
}

.history__current-day {
  background: url(../img/calendar_bg.jpg) repeat;
  position: relative;
  padding: 50px 0 46px;
}
.history__current-day:after {
  background: url(../img/history-border-2.png) repeat-x;
  content: '';
  width: 100%;
  position: absolute;
  bottom: -3px;
  height: 9px;
  left: 0;
  z-index: 10;
}
.history__current-day .img-wrap {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  /* // max-width: 250px; */
  height: auto;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  margin: 5px 10px;
  position: relative;
}
.history__current-day .img-wrap img,
.history__one-day__description .history__image_wrap img {
  width: 100%;
  height: 100%;
  filter: saturate(20%) sepia(30%) hue-rotate(-20deg);
}
.history__current-day .img-wrap:after,
.history__one-day__description .history__image_wrap:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url(../img/scratches.png);
}

.history__current-day__description {
  padding-bottom: 30px;
  padding-left: 20px;
}

.history__current-day p.history__date {
  font-family: 'Gabriela', serif;
  font-size: 35px;
  line-height: 45px;
  color: #544039;
}

.history-btn {
  background: #e5a629;
  color: white;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Georgia', serif;
  padding: 8px 20px;
  position: relative;
  margin-top: 26px;
  display: inline-block;
}
.history-btn:after {
  position: absolute;
  border-radius: 50px;
  content: '';
  width: 95%;
  height: 84%;
  border: 1px solid white;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}

.history__last-messages:after {
  background: url(../img/jubilee-wrap_blue.png) repeat-x;
  width: 100%;
  position: absolute;
  content: '';
  height: 12px;
  bottom: -2px;
  left: 0;
  z-index: 0;
}

.history__calendar {
  padding: 60px 0;
  background: url(../img/history-bg.png) repeat;
}
.history__calendar-year {
  font-family: 'Gabriela', serif;
  font-size: 58px;
  line-height: 57px;
  margin-bottom: 10px;
  color: #544039;
  margin-top: 35px;
}
.history__calendar-year.history__calendar__title {
  margin-top: 0;
}
.history-calendar__month-wrap {
  padding: 18px;
  background: #eeefea;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 8px;
  /* // margin-left: 5px; */
  height: 300px;
}
.history-calendar__month {
  padding: 20px;
  background: #f5f6f2;
  position: relative;
  border: 18px solid #eeefea;
  border: 1px dashed #d0ccc1;
  height: 87%;
}
@media screen and (max-width: 650px) {
  .history-calendar__month-wrap {
    height: auto;
  }
  .history-calendar__month {
    height: 100%;
    padding: 20px 7px;
  }
}
.history-calendar__month:after {
  border: 1px dashed #d0ccc1;
  /* // content: ""; */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.history-calendar__month-name {
  font-family: 'Gabriela', serif;
  font-size: 25px;
  color: #544039;
  margin-bottom: 25px;
}

.history__calendar .slick-arrow {
  background: url(../img/arr_brown_right.png) no-repeat center, white;
  position: absolute;
  top: -38px;
  z-index: 10;
  right: 12px;
  left: auto;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  border: 1px solid #c8ad98;
}
.history__calendar .slick-prev.slick-arrow {
  right: 56px;
  background: url(../img/arr_brown.png) no-repeat center, white;
}

.history__calendar-table {
  width: 100%;
}

.history__calendar-table td:nth-last-child(1),
.history__calendar-table td:nth-last-child(2),
.history__calendar-table th:nth-last-child(1),
.history__calendar-table th:nth-last-child(2) {
  /*color: red;*/
  color: #ff0000a6;
}
.history__calendar-table td:nth-last-child(1) a,
.history__calendar-table td:nth-last-child(2) a {
  color: red;
}
.history__calendar-table td:hover {
  background: #8db3bc;
  color: white;
}
.history__calendar-table td:hover a {
  color: white;
  text-decoration: underline;
}
.history__calendar-table td a {
  color: black;
}
.history__calengar-link {
  /*opacity: 0.5;*/
  /*display: block;*/
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
}
.history__calengar-current-day {
  background: #8db3bc !important;
  color: white !important;
}
.history__calendar-table td {
  background: white;
  height: 16px;
  border: 1px beige solid;
  text-align: center;
  font-weight: 500;
  position: relative;
  color: #9e9c95;
  font-size: 13px;
  font-family: 'open_sanssemibold';
  padding: 7px;
}
.history__calendar-table th {
  font-weight: 300;
  font-size: 14px;
}
.history__current-day p {
  font-size: 18px;
  line-height: 35px;
  font-family: 'Georgia', serif;
}
.history-day .header__middle,
.history-day .history__head {
  background: url('../img/history-bg.png') repeat;
}

.history__title {
  font-family: 'Gabriela', serif;
  font-size: 55px;
  line-height: 95px;
  margin-bottom: 10px;
  color: #544039;
}

.history-day h2 {
  font-family: 'Gabriela', serif;
  font-weight: 400;
  margin: 0;
  font-size: 40px;
  line-height: 1.2em;
  margin-bottom: 35px;
  text-align: center;
  text-align: center;
  color: #fff;
  text-shadow: 0 3px rgba(0, 0, 0, 0.2);
}

.history__image {
  position: relative;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  /* // width: 490px; */
  border: 16px solid #eeefea;
  box-sizing: border-box;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
}
.history__image img,
.history__current_image img {
  position: relative;
  display: block;
  border: 13px solid #fff;
  width: 100%;
  box-sizing: border-box;
  /* // filter: saturate(20%) sepia(30%) hue-rotate(-20deg); */
}
.history__image_wrap {
  border: 1px dashed #d0ccc1;
}
.history__title:after {
  content: none;
}
.history__info {
  font-size: 20px;
  line-height: 35px;
  color: #544039;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-style: italic;
}
.history__last-messages {
  position: relative;
  padding: 40px 0 25px;
  text-align: center;
  background: url(../img/blue_bg.png) repeat;
  z-index: 1;
  border-top: 4px solid white;
  border-bottom: 2px solid white;
}
.history__messages {
  position: relative;
  padding-bottom: 23px;
}
.history__messages:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px dashed #fff;
  opacity: 0.5;
}
.history__last-messages-btn {
  background: transparent;
  color: white;
  font-size: 15px;
  border-radius: 50px;
  font-family: 'Gabriela', serif;
  padding: 6px 17px;
  border: 1px solid #b7ccd1;
  margin-bottom: 7px;
  position: relative;
  cursor: pointer;
  margin-top: 30px;
  display: inline-block;
}
.history__slogan {
  background: url(../img/footer-bg.png) repeat;
  padding: 54px 0 42px;
  position: relative;
  box-shadow: 0px 16px 9px -12px #aeaeae inset;
}

.history__slogan .slogan-text {
  padding-right: 70px;
  padding-bottom: 25px;
}
.history__slogan .slogan {
  font-size: 50px;
  line-height: 65px;
  margin-left: 40px;
  margin-bottom: 35px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  position: relative;
}

.slogan.quote:before {
  position: absolute;
  content: '«';
  left: -35px;
} // } */
.history__slogan .author {
  text-align: right;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-size: 20px;
  color: #544039;
}
.image-sign {
  text-align: center;
  font-size: 15px;
  font-style: italic;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  color: #544039;
  margin-top: 30px;
  line-height: 25px;
}
.history-day .history__last-messages img {
  position: relative;
  /* // padding: 40px 0 25px; */
  background: url(../img/blue_bg.png) repeat;
  z-index: 1;
  border: 8px solid white;
}

.history-day .history__last-messages .date {
  position: absolute;
  color: white;
  font-family: 'Gabriela', serif;
  bottom: 20px;
  /* // text-align: right; */
  right: 20px;
  z-index: 10;
  font-size: 19px;
}

.history__current-day .jubilee__image {
  width: auto;
  border: 15px solid #eeefea;
}
.history__one-day__description {
  background: url(../img/calendar_bg.jpg) repeat;
  position: relative;
  padding: 50px 0 60px;
  text-align: center;
}
.history__one-day__events .history__image,
.history__one-day__events .img-wrap:first-of-type {
  width: auto;
  max-width: 100%;
  margin: 20px 0 25px;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
  border: 32px solid #eeefea;
}
.history__one-day__events .img-wrap:first-of-type img {
  filter: saturate(20%) sepia(30%) hue-rotate(-20deg);
}

.history__one-day__events .img-wrap:first-of-type:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url(../img/scratches.png);
}
.history__one-day__events .history__title {
  line-height: 65px;

  margin-bottom: 70px;
  position: relative;
}
.history__one-day__events .history__title:after {
  background: url(../img/bridge_sep.png) left no-repeat;
  content: '';
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -45px;
  height: 22px;
}

.history__one-day__description p {
  text-align: left;
  margin: 5px 0 10px;
}
.history__one-day__description h3,
.history__one-day__events h3:first-of-type {
  text-align: left;
  font-size: 20px;
  color: #544039;
  line-height: 35px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-weight: bold;
  font-style: italic;
}
.history__one-day__events {
  text-align: center;
  /* // padding: 96px 0 35px; */
  padding: 50px 0 35px;
}

.history__one-day__event {
  margin-bottom: 25px;
}
.history__one-day__event h2 {
  color: #55423a;
}
.history__one-day__events h3 {
  text-align: left;
  font-size: 18px;
  line-height: 35px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-weight: bold;
  font-style: italic;
  margin-top: 40px;
}
.history__one-day__events .img-wrap img {
  width: 100%;
}
.history__one-day__events .img-wrap p {
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-style: italic;
  margin: 20px 0 5px;
}
.history__one-day__events p {
  text-align: left;
  font-size: 18px;
  margin: 10px 0 5px;
  line-height: 35px;
  font-family: 'Georgia', 'Tahoma', sans-serif;
}
.history__one-day__events .img-wrap {
  padding: 22px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  height: auto;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  margin: 30px 10px 35px;
  position: relative;
}

.history__one-day__description p {
  font-size: 20px;
  line-height: 35px;
  color: #544039;
  font-family: 'Georgia', 'Tahoma', sans-serif;
  font-style: italic;
}
.history__one-day__events {
  background: url(../img/history-bg.png) repeat;
}
.history-navigation {
  text-align: center;
  text-align: center;
  background: url(../img/history-bg.png) repeat;
  border-top: 2px white solid;
  padding: 35px 0;
}
.history-navigation .prev-btn,
.history-navigation .next-btn {
  height: 40px;
  width: 40px;
  background: url(../img/arr_brown.png) center no-repeat, #fff;
  border-radius: 100px;
  border: 1px solid #c8ad98;
  display: inline-block !important;
  float: left;
}
.history-navigation .next-btn {
  background: url(../img/arr_brown_right.png) center no-repeat, #fff;
  right: -15px;
  float: right;
}
.history-navigation .back-btn.history-btn {
  margin-top: 2px;
  font-size: 16px;
}
.history-navigation .back-btn.history-btn:after {
  content: none;
}
.history__archieve {
  border: none;
}

.history__archieve .history__messages:after {
  content: none;
}
.history__archieve.history__last-messages {
  padding: 48px 0 36px;
}
.history__archieve:before {
  background: url(../img/jubilee-wrap_blue.png) repeat-x;
  width: 100%;
  position: absolute;
  content: '';
  height: 12px;
  top: -2px;
  left: 0;
  z-index: 0;
}
.history__archieve-head {
  text-align: center;
  background: #efe8e1;
  position: relative;
  padding: 50px 0;
}
.history__archieve-head h1 {
  font-family: 'Gabriela', serif;
  font-size: 55px;
  line-height: 55px;
  position: relative;
  display: inline;
  margin: 0;
  color: #544039;
}
.history__archieve-head h1:before {
  background: url(../img/bridge_sep.png) left no-repeat;
  position: absolute;
  left: -70px;
  display: block;
  top: 41%;
  width: 59px;
  content: '';
  height: 21px;
}
.history__archieve-head h1:after {
  background: url(../img/bridge_sep.png) left no-repeat;
  position: absolute;
  right: -70px;
  display: block;
  top: 41%;
  width: 59px;
  content: '';
  height: 21px;
}
@media screen and (max-width: 448px) {
  .history__archieve-head h1 {
    font-size: 45px;
    line-height: 45px;
  }
}
@media screen and (max-width: 650px) {
  .history__archieve-head h1:before {
    content: none;
  }
  .history__archieve-head h1:after {
    content: none;
  }
}
.history-paginate {
  background: url(../img/history-bg.png) repeat;
  text-align: center;
  padding: 50px 0 31px;
  position: relative;
}
.history-paginate:after {
  width: 100%;
  position: absolute;
  content: '';
  height: 1px;
  border-top: 1px solid #eadfd4;
  left: 0;
  z-index: 0;
  top: 74px;
}
.history-paginate .wr {
  position: relative;
  z-index: 1;
}
.history-paginate__btn {
  background: #fffbf4;
  color: #5d4a43;
  font-size: 15px;
  border-radius: 50px;
  font-family: 'Gabriela', serif;
  padding: 9px 20px;
  border: 1px solid #c7ab96;
  margin-bottom: 7px;
  position: relative;
  cursor: pointer;
  margin-top: 25px;
  display: inline-block;
}
.show-archive {
  background: #8db3bc;
  color: white;
  font-size: 15px;
  border-radius: 50px;
  font-family: 'Gabriela', serif;
  padding: 10px 20px;
  margin: auto;
  display: block;
  /*border: 1px solid #b7ccd1;*/
  margin-bottom: 7px;
  position: relative;
  cursor: pointer;
  width: 189px;
  margin-top: 30px;
}
