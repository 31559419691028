.main-seo {
  padding: 43px 0 50px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 1190px;
    height: 1px;
    margin: auto;
    background-color: #e8e9ea;
  }

  &__wrap {
    position: relative;
    padding-right: 310px;
  }

  .content {
    padding: 0;
  }

  &__text {
    height: 420px;
    transition: 0.3s;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 40px;
      width: 100%;
      background: linear-gradient(to bottom, transparent, #fff);
      transition: 0.3s;
      opacity: 1;
    }

    &.open {
      &:after {
        opacity: 0;
      }
    }
  }

  .image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 133px;
    right: 0;
    width: 280px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .wysiwyg {
    overflow: hidden;
    position: relative;
    margin-bottom: 31px;

    h2 {
      text-transform: initial;
    }
  }

  .button {
    font-family: open_sanssemibold, sans-serif;
    text-transform: uppercase;
    font-size: 10px;
    cursor: pointer;
    border: none;
    padding: 0px 14px;
  }
}
@media screen and (max-width: 1200px) {
  .main-seo {
    padding: 40px 0;
  }
}

@media screen and (max-width: 992px) {
  .main-seo {
    &__wrap {
      padding-right: 0;
      position: relative;
    }

    .image {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 281px;
      height: 269px;
      opacity: 0.5;
    }
  }
}

.main_seo {
  padding: 40px 0;

  .m_text {
    padding: 0;
    height: 195px;
    overflow: hidden;
    transition: 0.3s;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 40px;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
      transition: 0.3s;
      opacity: 1;
    }

    &.open {
      &:after {
        opacity: 0;
      }
    }
  }

  .show-btn {
    text-align: center;
    margin-top: 40px;
  }

  .button {
    font-family: open_sansbold, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
