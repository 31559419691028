.main-news {
  padding: 54px 0;

  &__wrap {
    display: grid;
    grid-template-columns: 290px 290px 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 'news_1 news_2 news_3' 'news_1 news_2 news_4' 'news_1 news_2 news_5';
    gap: 10px 110px;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px 30px;

    &__image {
      display: block;
      position: relative;
      padding-top: 100%;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: #e8e9ea;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.default {
          width: 50%;
          height: auto;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
    }

    &__date {
      font-family: 'open_sansbold', sans-serif;
      font-size: 15px;
    }

    &__name {
      font-size: 15px;
      line-height: 1.7;
    }

    &:nth-child(1) {
      grid-area: news_1;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: -56px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #e8e9ea;
      }
    }

    &:nth-child(2) {
      grid-area: news_2;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: -56px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #e8e9ea;
      }
    }

    &:nth-child(3) {
      grid-area: news_3;
      display: flex;
      align-items: flex-start;
      flex-direction: row;

      .item__image {
        width: 130px;
        min-width: 130px;
        padding-top: 130px;
      }

      .item__content {
        padding-top: 6px;
      }
    }

    &:nth-child(4) {
      grid-area: news_4;
      display: flex;
      align-items: flex-start;
      flex-direction: row;

      .item__image {
        width: 130px;
        min-width: 130px;
        padding-top: 130px;
      }

      .item__content {
        padding-top: 6px;
      }
    }

    &:nth-child(5) {
      grid-area: news_5;
      display: flex;
      align-items: flex-start;
      flex-direction: row;

      .item__image {
        width: 130px;
        min-width: 130px;
        padding-top: 130px;
      }

      .item__content {
        padding-top: 6px;
      }
    }
  }

  &_two-cols {
    .main-news__wrap {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-template-areas: none;
      gap: 10px 130px;
    }

    .item {
      grid-area: auto !important;
      display: flex;
      align-items: flex-start;
      flex-direction: row !important;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: -66px !important;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #e8e9ea;
      }

      &:nth-child(even) {
        &:after {
          display: none;
        }
      }

      &__image {
        min-width: 130px;
        padding-top: 130px;
        width: 130px;
      }
    }
  }

  &_thin-title {
    .block-title {
      h2 {
        font-family: 'open_sanslight', sans-serif;
        font-weight: 400;
        font-size: 40px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-news {
    padding: 40px 0;

    &__wrap {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: 'news_1 news_2 news_3' 'news_4 news_5 .';
      grid-template-rows: auto;
      gap: 40px 15px;
    }

    .item {
      &:nth-child(1) {
        &:after {
          display: none;
        }
      }

      &:nth-child(2) {
        &:after {
          display: none;
        }
      }

      &:nth-child(3) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .item__image {
          padding-top: 100%;
          width: 100%;
        }

        .item__content {
          padding: 0;
        }
      }

      &:nth-child(4) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .item__image {
          padding-top: 100%;
          width: 100%;
        }

        .item__content {
          padding: 0;
        }
      }

      &:nth-child(5) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .item__image {
          padding-top: 100%;
          width: 100%;
        }

        .item__content {
          padding: 0;
        }
      }
    }

    &_two-cols {
      .main-news__wrap {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-template-areas: none;
        gap: 10px 30px;
      }

      .item {
        grid-area: auto !important;
        display: flex;
        align-items: flex-start;
        flex-direction: row !important;
        position: relative;

        &:after {
          display: none;
        }

        &__image {
          min-width: 130px !important;
          padding-top: 130px !important;
          width: 130px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .main-news {
    &_two-cols {
      .main-news__wrap {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-news {
    &__wrap {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'news_1 news_2' 'news_3 news_4' 'news_5 .';
    }
  }
}

@media screen and (max-width: 576px) {
  .main-news {
    &__wrap {
      grid-template-columns: 1fr;
      grid-template-areas: 'news_1' 'news_2' 'news_3' 'news_4' 'news_5';
    }

    &_two-cols {
      .main-news__wrap {
        gap: 40px 0;
      }

      .item {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;

        .item__image {
          padding-top: 100% !important;
          width: 100% !important;
        }

        .item__content {
          padding: 0;
        }
      }
    }
  }
}
