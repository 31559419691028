@media only screen and (max-width: 1300px) {
  .seige_jumb {
    background-size: cover;
  }
}
@media only screen and (max-width: 1200px) {
  .seige_days-days {
    margin-bottom: 10px;
    font-size: 179px;
  }
  .seige_days-title {
    font-size: 46px;
  }
}
@media only screen and (max-width: 1200px) {
  .book__right-nav {
    position: absolute;
    top: 0;
    height: 27px;
    bottom: auto;
    right: 0;
    padding: 10px;
  }
  .book__left-nav {
    position: absolute;
    top: 0;
    height: 27px;
    bottom: auto;
    left: 0;
    padding: 10px;
  }
  .book_first,
  .book_last,
  .book_prev,
  .book_next {
    display: inline-block;
  }
  .book_prev,
  .book_next {
    padding-top: 0;
    display: inline-block;
    padding-left: 22px;
    padding-bottom: 0;
  }
  .book_last {
    float: right;
  }
  .book_next {
    padding-left: 0;
    padding-right: 22px;
    padding-top: 2px;
  }

  .book_first:after,
  .book_last:after {
    top: 0;
    content: '';
    position: absolute;
    bottom: auto;
    width: 1px;
    left: auto;
    left: -10px;
    height: 30px;
  }
  .book_first:after {
    right: -9px;
    left: auto;
  }
  .book-nav ul li {
    /*display: none;*/
  }
  .book-nav ul li:first-child {
    display: inline-block;
  }

  .plate-links {
    grid-auto-flow: minmax(200px, auto);
    &__item {
      padding: 25px;
    }
  }
}
@media only screen and (max-width: 950px) {
  .seige-header-cat {
    vertical-align: top;
  }
  .seige-header-title {
    width: 50%;
  }
}
@media only screen and (max-width: 816px) {
  .seige_star-list {
    margin-bottom: 20px;
  }
  .seige_thanks .seige-h4 {
    width: auto;
  }
  .seige_days {
    padding: 20px 0 0;
  }
  .seige_days-container {
    text-align: center;
  }
  .seige_days-sep {
    height: 1px;
    width: 100%;
    background: #24262e;
    /* text-align: center; */
    margin: 20px 0;
  }
  .seige_podvig-orden {
    margin: auto;
    margin-bottom: 20px;
  }
  .seige_dark-block-col,
  .seige-col {
    padding-right: initial !important;
    border-right: none !important;
    padding-left: initial !important;
    padding: initial;
    text-align: center;
  }
  .seige-sep-col {
    border-left: none;
    padding: 0 16px;
  }
}
@media only screen and (max-width: 768px) {
  .seige-text-block p.seige-note {
    font-size: 16px;
    line-height: 22px;
    padding-left: 21px;
    width: 91%;
  }

  .seige-h1 {
    font-size: 9vw;
    line-height: 10vw;
  }
  .seige-text-block p.seige-footnote,
  .seige-text-block p.seige-starnote {
    width: auto;
  }

  .seige-title {
    margin: 20px 0px;
  }
  .seige_jumb-title {
    font-size: 68px;
    line-height: 75px;
  }
  .seige_docs .seige-h2 {
    line-height: 40px;
  }
  .seige_jumb {
    height: auto;
  }
  .seige_jumb .wr {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .plate-links {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 580px) {
  .seige-docs-item {
    padding-left: 33px;
    padding-right: 10%;
  }
  .seige-doc-title {
    font-size: 16px;

    line-height: 23px;
  }
  .seige-text-block p.seige-footnote {
    padding-left: 20px;
  }
  .seige-h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .seige-article .seige-h2 {
    margin-bottom: 20px;
  }
  .seige-col:first-child,
  .seige_dark-block-col:first-child {
    border-right: none;
    padding-right: initial;
  }
  .article-btns .back-btn,
  .seige-star-btn {
    padding-left: 83px;
    display: block;
    margin-bottom: 5px;
  }
  .right-btns {
    float: inherit;
  }
  .seige-header-title {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .seige_jumb-title {
    font-size: 15vw;
    line-height: 16vw;
  }
  .book__oglavl {
    width: 300px;
    left: -300px;
  }
  .book-nav ul li:first-child {
    margin-left: 0;
  }
  .book-nav {
    display: block;
  }
  .book-full__title {
    margin-bottom: 9px;
    border-bottom: 1px solid white;
    border-right: navajowhite;
  }

  .plate-links {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}

@media (max-width: 992px) {
  .cover-container {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .photo-col {
    width: 25%;
  }
}

@media screen and (max-width: 991px) {
  .logo-col {
    text-align: center;
  }
  .fb-path-intro__info .fb-path-intro__info-block {
    border-right: none;
    border-bottom: 1px dashed black;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .pl-col,
  .pl-col-2 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 768px) {
  .fb_sbornaya-slide__btns .fb-slide-btn {
    margin-bottom: 5px;
  }
  .fb-h1 {
    font-size: 36px;
    line-height: 40px;
  }
  .fb-h2 {
    font-size: 32px;
    line-height: 36px;
  }
  .fb_news .fb-slide-btn {
    width: auto;
  }
  .fb_routes {
    padding: 40px 0 70px;
  }
  .routes_info {
    position: inherit;
  }

  .path-man:after {
    top: 0;
    bottom: 0;
    margin: auto;
    left: -38px;
  }
  .path-accent:after,
  .pl-col-2 .path-accent:after {
    left: -50px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .fb-h1-main,
  .fb-h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .fb-btn {
    width: 100%;
    box-sizing: border-box;
  }
  .fb_sbornaya-slide {
    padding: 0;
  }
  .photo-col {
    text-align: center;
  }
  .photo-col img {
    display: inline-block;
  }
  .fb_sbornaya-slide__text {
    padding: 0;
    border: none;
  }
}

@media only screen and (max-width: 1199px) {
  .fb_banner {
    padding: 20px 0 40px;
  }
  .route_title-2,
  .route_title-1 {
    font-size: 32px;
  }
  .fb-header label.show-label {
    padding-left: 22px;
  }
  .route-select {
    display: block;
    margin-bottom: 24px;
  }
  .fb-obj-block {
    /*width: auto;*/
  }

  .bridge_list.embankments .bridge_wrap {
    margin-top: 10px;
  }

  body.spez .bridge_list .search_b {
    position: relative;
    left: 0;
  }
  body.spez .bridge_list .categories {
    padding-top: 0;
  }

  body.spez .content .container {
    padding-bottom: 415px;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 817px) {
  .troj-banner {
    padding-right: 0;
    padding-left: 0;
  }
  .timer {
    margin-bottom: -5px;
  }

  .timer span {
    font-size: 30px !important;
  }
  .timer span:nth-child(2n) {
    font-size: 12px !important;
  }

  .troj__ribon-text:before {
    width: 90%;
    background: url(../img/ribbon_2.png) no-repeat;
    background-size: contain;
  }
  .troj__ribon-text {
    font-size: 22px;
  }
}
@media only screen and (max-width: 860px) and (min-width: 817px) {
  .fb-body .content .container {
    padding: 0 0 0 !important;
  }
  .timer-separator {
    padding: 0 2px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .bridge_list.embankments .bridge_wrap {
    margin-top: 10px;
  }
  .bridge_list.piers-map .bridge_wrap {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .troj-logo {
    width: 90%;
    display: block;
    margin: auto;
  }
  .brige-100_years.troj-bridge .pb_banner .hd {
    line-height: 35px;
  }
  .troj-bridge__header-col {
    padding-left: 27px;
    padding-right: 20px;
  }
  .brige-100_years.troj-bridge .wr.banner-content {
    padding: 20px 0;
  }
  .history__current-day .img-wrap {
    margin-bottom: 30px;
  }
  .bridge_list.piers-map .bridge_wrap {
    margin-top: 40px;
  }
  .form.light p.hh {
    position: absolute;
    margin-top: -20px;
  }
  body.spez .form.light p.hh {
    position: absolute;
    margin-top: -27px;
  }
}
@media only screen and (max-width: 818px) {
  .spez__scale_text,
  .spez__scales,
  .spez__color_text,
  .spez__colors {
    display: block;
    margin-left: 0;
  }
  .spez__scale_text,
  .spez__color_text {
    display: none;
  }
  .spez__left,
  .spez__back {
    float: none;
  }
  .spez__back {
    margin-top: 10px;
    display: block;
  }
  body.spez .bridge_list .search_b {
    margin-top: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .fb-map__route-info {
    /*display: block;*/
    width: 84%;
    right: auto;
    left: 10px;
    box-sizing: border-box;
  }
  .fb-header label.show-label {
    display: block;
    margin-bottom: 10px;
  }
  .route-select {
    width: 100%;
  }
  .fb-obj-block {
    width: 100%;
    display: block;
    box-sizing: inherit;
  }
  .fb-obj-list__close {
    right: 39px;
    filter: invert(0.5);
    z-index: 20;
  }
}
@media only screen and (max-width: 700px) {
  .troj-bridge__header-col {
    width: 100%;
  }
}

@media only screen and (max-width: 529px) {
  body.spez .bridge_list .search_b {
    position: relative;
    left: 0;
  }
  body.spez .bridge_list .search_b .select {
    top: 0;
    width: 250px;
  }
  body.spez .bridge_list .search_b .select#search-form {
    width: 250px;
  }
  body.spez .bridge_list .categories {
    padding-top: 0;
  }
  body.spez.scale-3 .head {
    font-size: 35px;
  }
}
/* ==================== Версия для слабовидящих всё ===================== */
.blog_item__date {
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 20px;
}
.blog_item .button {
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .wr {
    padding: 0 20px;
  }

  .bloggers_view .bridge-about {
    padding-left: 30px;
  }
  .header__top {
    padding-bottom: 42px;
  }
  .header__top .header__top-links {
    position: absolute;
    right: 20px;
    top: 45px;
  }
  .header__top .header__top-phone,
  .header__top .header__top-mail {
    position: relative;
    top: 15px;
  }
  .bridge_view .boggers-flag,
  .bridge_view .raising-flag {
    top: 65px;
  }

  .header__middle .menu {
    margin-left: -40px;
  }
  .header__middle .menu li {
    margin-left: 20px;
  }

  .view_buttons .wr {
    padding: 0 10px;
  }

  .bridge_list .categories {
    padding: 30px 0 30px;
    margin: 3px 0 0;
    float: none;
  }
  .bridge_list .search_b {
    top: 0;
    margin: -14px 0 10px;
    left: 20px;
  }
  .bridge_list.piers .search_b {
    top: 50px;
  }
  .bridge_list.piers .buttons_geay {
    margin-bottom: 50px;
    position: relative;
    float: none;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    position: relative;
    width: calc(100% - 60px);
    margin: 0 auto;
  }
  .fb-modal .close {
    right: 7px;
    top: 10px;
  }
  .fb-modal .modal-dialog {
    width: calc(100% - 18px);
  }
  .fb-modal .modal-body {
    padding: 15px;
  }
  .fb-modal .modal-content {
    padding: 0;
  }
  .modal .prev {
    left: -30px;
    font-size: 25px;
  }
  .modal .next {
    right: -26px;
    font-size: 25px;
  }

  .info_blocks .widget span.s11 {
    font-size: 23px;
  }
}
@media only screen and (max-width: 1175px) {
  .header__top .header__top-links a {
    margin-left: 20px;
  }
  .header__top .header__top-mail,
  .header__top .header__top-lang {
    margin-left: 2%;
  }
  .header__top .header__top i {
    margin-right: 7px;
  }
  .header__top .header__top-phone {
    margin: 5px 0 0 0px;
  }

  .regiment i.star {
    display: none;
  }
}
@media only screen and (max-width: 1145px) {
  .header__middle .menu li a,
  .header__middle .menu li span {
    font-size: 10px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec_wrapper:before {
    left: 20px;
    right: 20px;
  }
  .timetable .bridge .line_wrapper .line_cur span {
    font-size: 13px;
    width: 40px;
    top: -7px;
  }
  .timetable .bridge .line_wrapper .line_cur span.s1 {
    left: -20px;
  }
  .timetable .bridge .line_wrapper .line_cur span.s2 {
    right: -20px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec span {
    font-size: 13px;
    width: 40px;
    top: -7px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec span.s1 {
    left: -20px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec span.s2 {
    right: -20px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec .line.l_left:before {
    left: 20px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec .line.l_right:before {
    right: 20px;
  }
  .timetable .bridge .line_wrapper .line.l_left:before {
    right: 20px;
  }
  .timetable .bridge .line_wrapper .line.l_right:before {
    left: 20px;
  }

  .info_blocks .widget span.s8 {
    font-size: 28px;
  }

  .news_list a.news_link span {
    padding: 0 7% 0 10%;
  }
  .news_list a.news_link span.date {
    top: 8%;
  }
  .news_list a.news_link span.txt {
    top: 37.5%;
  }
  .news_list a.news_link span.name {
    top: 18%;
  }

  .brige-100_years .pb_banner h1 {
    font-size: 35px;
    line-height: 62px;
  }

  .brige-100_years .pb_banner .hd {
    font-size: 35px;
    line-height: 45px;
  }

  .contest a.bloggers {
    display: block;
  }
}
@media only screen and (max-width: 1100px) {
  .info_blocks .widget a {
    padding: 55px 10px 0;
  }
  .info_blocks .widget span.s4 {
    font-size: 50px;
  }

  .contacts .inf_block_one .rhombus {
    top: 70px;
  }

  .info_blocks .widget span.s11 {
    font-size: 20px;
  }

  .piers-btn {
    float: none;
    margin-bottom: 20px;
  }
  .bridge_list.embankments h1.head {
    margin-bottom: 10px !important;
  }
}
@media only screen and (max-width: 1050px) {
  .contacts .inf_block_one p.hd {
    left: 20px;
  }
  .contacts .inf_block_one .txt_bottom {
    left: 20px;
  }
  .contacts .inf_block_one .rhombus {
    -moz-transform: scale(0.7) rotate(45deg);
    -o-transform: scale(0.7) rotate(45deg);
    -ms-transform: scale(0.7) rotate(45deg);
    -webkit-transform: scale(0.7) rotate(45deg);
    transform: scale(0.7) rotate(45deg);
  }

  .dir .pagination_index .pag {
    width: 250px;
  }
}
@media only screen and (max-width: 1045px) {
  .header__middle .menu li {
    margin-left: 12px;
  }

  .bridge_list h1 {
    margin-bottom: 20px !important;
  }
  .bridge_list .buttons_geay,
  .bridge_list .buttons_geay.static {
    float: none;
  }
  .bridge_list.embankments .search_b,
  .bridge_list.piers .search_b {
    top: 50px;
  }
  .bridge_list.embankments .buttons_geay,
  .bridge_list.piers .buttons_geay {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .routes__header {
    margin-bottom: 30px;
  }
  .slider-col {
    width: 90%;
  }
  .fb_sbornaya-slide__text {
    padding-left: 0;
    border: none;
  }
  .route__chart {
    padding: 0 100px;
    margin-top: 40px;
  }
  .route__slider-nav {
    position: absolute;
    bottom: -40px;
    margin: auto;
    text-align: center;
    /*width: 100%;*/
    right: auto;
  }
}
@media only screen and (max-width: 1020px) {
  .timetable .time_list {
    margin-left: 122px;
  }
  .timetable .time_tracker {
    margin-left: 122px;
  }
  .timetable .bridge .name {
    width: 100px;
  }
  .timetable .bridge .line_wrapper {
    margin: 0 18px 0 130px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec_wrapper {
    min-width: 40px;
  }

  .info_blocks .widget span.s1 {
    margin-top: 30px;
  }
  .info_blocks .widget span.s2 {
    margin-top: 30px;
  }
  .info_blocks .widget span.details {
    bottom: 20px;
  }
  .info_blocks #wd3 {
    // Юбилеи мостов НАЧАЛО
    span.s6 {
      bottom: 50px;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .jubilee__bridge h1 {
    font-size: 35px;
    line-height: 50px;
  }
  .jubilee__bridge p {
    font-size: 16px;
    line-height: 30px;
  }
  .jubilee__main {
    padding: 35px 0;
  }
  .jubilee__head {
    font-size: 70px;
  }
  .jubilee__text h2,
  .jubilee__current h2 {
    font-size: 35px;
  }
  .jubilee__item {
    display: flex;
    flex-wrap: wrap;
  }
  .jubilee__text {
    width: 100%;
    float: none;
    order: 1;
  }
  .jubilee__item:nth-child(even) .jubilee__text {
    padding: 0;
  }
  .jubilee__image {
    float: none;
    order: 2;
    width: 100%;
    max-width: 490px;
    margin: 40px auto;
  }
  .jubilee__top p {
    font-size: 15px;
    line-height: 25px;
  }
  .contacts .inf_block_one .txt_bottom {
    bottom: 20px;
  }
  .contacts .inf_block_one .rhombus {
    left: auto;
    top: 12px;
    right: 6px;
  }
  .contacts .info_w .inf_block_one .rhombus {
    right: auto;
    top: 77px;
    left: 50%;
    margin-top: 0;
  }

  .left_menu a {
    font-size: 12px !important;
  }

  .news_list a.news_link span.txt {
    height: 76px;
    overflow: hidden;
  }
  .news_list a.news_link span.name {
    height: 20px;
  }

  .chief .banner .name {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 990px) {
  .fb-map__route-info {
    width: auto;
    right: auto;
  }
  .fb-map__route-info__close {
    z-index: 2;
  }
  .header__middle .logo {
    &.anniversary {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 100%;
        width: 50px;
        height: 36px;
      }
    }
  }
  .header__middle .menu li a,
  .header__middle .menu li span {
    font-size: 10px;
  }

  .contest .winner .win_person {
    margin: 0 auto 15px;
  }

  .regiment .head + .col-md-5 {
    text-align: center;
  }
  .regiment .person-title {
    text-align: center;
    margin-top: 15px;
  }
  .regiment .person-title + p {
    text-align: center;
  }

  .bloggers .img {
    margin-top: -20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .bloggers .img img {
    max-width: 100%;
  }

  .bloggers_view .bridge-about {
    padding-left: 0px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .bloggers_view .bridge-about .discr-wrap {
    text-align: center;
  }

  .info_blocks .widget span.s11 {
    font-size: 19px;
  }

  .winner .col-md-5 {
    text-align: center !important;
  }
}
@media only screen and (max-width: 950px) {
  .header__top .header__top-phone i {
    margin: 0 3px 0 0;
  }
  .header__top .header__top-mail {
    margin-left: 1%;
  }
  .header__top .header__top-mail i {
    margin: 2px 3px 0 0;
  }
  .header__top .header__top-links a {
    margin-left: 9px;
  }

  .header__middle .menu li a,
  .header__middle .menu li span {
    font-size: 10px;
  }
  .info_blocks #wd2 {
    .s2 {
      font-size: 16px;
    }
  }
  .info_blocks #wd3 li {
    margin: 0;
    margin-bottom: 20px;
  }
  // .info_blocks #wd3 span.s1 {
  //   font-size: 22px !important;
  // }

  .prs_center .cnt_one p.hd {
    left: 20px;
    top: 20px;
  }
  .prs_center .cnt_one .txt_bottom {
    bottom: 20px;
    padding: 0 0 0 20px;
  }
  .prs_center .cnt_one .rhombus {
    -moz-transform: scale(0.7) rotate(45deg);
    -o-transform: scale(0.7) rotate(45deg);
    -ms-transform: scale(0.7) rotate(45deg);
    -webkit-transform: scale(0.7) rotate(45deg);
    transform: scale(0.7) rotate(45deg);
  }
  .prs_center .cnt_one .rhombus i {
    -moz-transform: scale(1.2) rotate(-45deg);
    -o-transform: scale(1.2) rotate(-45deg);
    -ms-transform: scale(1.2) rotate(-45deg);
    -webkit-transform: scale(1.2) rotate(-45deg);
    transform: scale(1.2) rotate(-45deg);
  }

  .timetable {
    height: 600px;
  }
  .timetable .wr {
    padding: 0 5px;
  }

  .jubilee__current_item {
    width: 33%;
  }
  .jubilee__current_item:nth-child(4n) {
    border-right: 1px dashed #fff;
  }
  .jubilee__current_item:nth-child(3n) {
    border-right: none;
  }
}
@media only screen and (max-width: 880px) {
  .header__middle .menu li {
    margin-left: 15px;
  }
  .header__middle .menu li a i,
  .header__middle .menu li span i {
    margin-top: 8px;
    margin-left: 2px;
  }

  .head {
    font-size: 40px;
  }

  .project_list .img a .title {
    top: 20px;
    padding-left: 20px;
  }
  .project_list .img a .details {
    bottom: 20px;
    padding-left: 20px;
  }

  .error_page .error_icon {
    background-size: 300px 301px;
    height: 300px;
    width: 301px;
  }
  .error_page h1 span {
    font-size: 160px;
  }

  .regiment .head.polk_list {
    text-align: center;
    font-size: 43px;
  }
  .regiment .head.polk_list i {
    margin: 0;
  }

  .info_blocks .widget span.s11 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 860px) {
  .header__middle .menu li {
    margin-left: 10px;
  }
  .header__middle .menu li a,
  .header__middle .menu li span {
    font-size: 10px;
  }

  .info_blocks .widget span.s4 {
    font-size: 30px;
  }
  .info_blocks .widget span.s5 {
    font-size: 15px;
  }

  .slider .slide_one .time {
    font-size: 6vw;
  }
  .slider .slide_one .time span {
    font-size: 4vw;
  }
  .slider .slide_one .p2 {
    font-size: 6vw;
  }
}
@media only screen and (max-width: 840px) {
  .header__top-mail {
    margin-left: 15px;
  }
  .header__top-mail i {
    margin-right: 5px;
  }

  .header__top-links a {
    margin-left: 5px;
  }

  .bridge_list .hidden_search {
    display: none;
  }
  .bridge_list .hidden_search input {
    left: -2px;
  }

  .bridge_list .hidden_search button,
  .bridge_list .btn_search {
    top: 1px;
    left: 453px;
  }

  .doc_slide p.date {
    font-size: 15px;
  }
  .doc_slide p.date .pr {
    margin-left: 0;
    display: block;
  }
  .jubilee__grayblock_text {
    width: 100%;
    display: block;
  }
  .jubilee__grayblock_image {
    display: block;
    margin: 30px auto 0;
  }
  .jubilee__content h2 {
    font-size: 35px;
  }
  .jubilee__content p {
    font-size: 17px;
    line-height: 30px;
  }
  .jubilee__gallery_item {
    width: calc(50% - 5px);
  }
  .jubilee__gallery_item:nth-child(4n) {
    margin-right: 10px;
  }
  .jubilee__gallery_item:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 835px) {
  .contacts .inf_block_one .rhombus {
    right: 0;
    -moz-transform: scale(0.5) rotate(45deg);
    -o-transform: scale(0.5) rotate(45deg);
    -ms-transform: scale(0.5) rotate(45deg);
    -webkit-transform: scale(0.5) rotate(45deg);
    transform: scale(0.5) rotate(45deg);
  }
}
@media only screen and (max-width: 820px) {
  .regiment .persons li {
    margin-bottom: 20px;
  }

  .pagination a.btn_prev {
    left: 0px;
    padding: 0 18px 0 30px;
  }

  .pagination a.btn_next {
    right: 0px;
    padding: 0 30px 0 18px;
  }

  .regiment .content .container {
    padding: 0 0 0px;
  }

  .facts .p_blocks {
    max-width: 599px;
    margin: 0 auto;
  }
  .facts .p_blocks .bl {
    margin: 0 auto 10px;
  }

  .facts h1 {
    text-align: center;
  }
}
@media only screen and (max-width: 800px) {
  .header__top .header__top-links a {
    font-size: 10px;
  }

  .timetable .time_list {
    margin-left: 67px;
    margin-right: 69px;
  }
  .timetable .bridge .name {
    float: none;
    width: auto;
    margin: 25px 0 10px 10px;
  }
  .timetable .bridge .line_wrapper {
    margin-left: 0px;
    top: 0;
  }

  .bridge_wrapper {
    margin-right: 48px;
    margin-left: 48px;
  }

  .time_tracker .tracker {
    margin-left: -53px;
  }

  .info_blocks .widget span.s1 {
    font-size: 20px;
  }
  .info_blocks .widget span.s2 {
    font-size: 12px;
    line-height: 20px;
  }
  .info_blocks .widget span.s3 {
    font-size: 14px;
  }
  .info_blocks .widget span.details {
    font-size: 12px;
  }
  .info_blocks .widget#wd1 .details {
    bottom: 55px;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
@media only screen and (max-width: 817px) {
  .popup {
    width: calc(100% - 20px);
    margin-left: -10px;
    left: 20px;
    top: 100px;
  }
  .jubilee .content .container,
  .history-day .content .container {
    padding-bottom: 0 !important;
  }

  .jubilee .header__middle .logo {
    width: 62px;
    height: 60px;
    background-size: contain;
  }
  .popup.fact {
    margin-left: calc(-50% + 10px);
  }

  .news_list a.news_link span.txt {
    height: 140px;
    overflow: hidden;
  }
  .news_list a.news_link span.name {
    height: 80px;
  }

  .wr {
    padding: 0 20px;
  }

  // .main-banners {
  //   padding: 60px 0 0;
  //   text-align: center;
  // }

  .header__top,
  .header__middle .menu {
    display: none;
  }

  .header__middle .logo {
    background-size: 57px 55px;
    width: 57px;
    height: 55px;
    float: left;
    margin-top: 13px;
  }
  .header__middle .header__top-lang {
    display: block;
    position: absolute;
    right: 80px;
    top: 29px;
  }

  .header__top-mail {
    margin: 0;
  }

  .header__bottom {
    margin-top: 11px;
  }

  .btn_mob {
    float: right;
    width: 20px;
    height: 16px;
    cursor: pointer;
    margin: 36px 15px 0 0;
    top: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 34px;
    -moz-border-radius: 34px;
    border-radius: 34px;
  }
  .btn_mob span {
    display: block;
    height: 3px;
    margin: 0 0 2px;
    position: relative;
    top: 0;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
  }
  .btn_mob span:after {
    content: '';
    display: block;
    background: black;
    height: 3px;
    width: 100%;
    opacity: 1;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .btn_mob.open span:nth-child(1) {
    top: 6px;
  }
  .btn_mob.open span:nth-child(1):after {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .btn_mob.open span:nth-child(2):after {
    opacity: 0;
  }
  .btn_mob.open span:nth-child(3) {
    top: -4px;
  }
  .btn_mob.open span:nth-child(3):after {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mob_m {
    background: #27aae0;
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    padding: 17px 20px 23px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    transition: top 0.5s linear;
    z-index: 200;
  }
  .mob_m .menu {
    display: block;
  }
  .mob_m .menu ul {
    margin: 0;
  }
  .mob_m .menu li {
    display: block;
  }
  .mob_m .menu li:after {
    background: url(../img/sep_white.png) repeat-x;
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    opacity: 0.3;
  }
  .mob_m .menu li a,
  .mob_m .menu li span {
    background: none !important;
    font-size: 12px;
    font-family: 'open_sansextrabold', sans-serif;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding: 13px 0 12px;
    display: block;
  }
  .mob_m .menu li a i,
  .mob_m .menu li span i {
    background-image: url(../img/arr_d_white.png);
    background-size: 10px 6px;
    content: '';
    display: block;
    width: 10px;
    height: 6px;
    position: absolute;
    right: 4px;
    top: 17px;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
  }
}
@media only screen and (max-width: 817px) and (-moz-min-device-pixel-ratio: 1.5),
  only screen and (max-width: 817px) and (-o-min-device-pixel-ratio: 3 / 2),
  only screen and (max-width: 817px) and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (max-width: 817px) and (min-device-pixel-ratio: 1.5) {
  .mob_m .menu li a i,
  .mob_m .menu li span i {
    background-image: url(../img/arr_d_white@2x.png);
  }
}
@media only screen and (max-width: 817px) {
  .mob_m .menu li a.open i,
  .mob_m .menu li span.open i {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .mob_m .menu li .sub {
    position: relative;
    padding: 0 0 5px;
    display: none;
  }
  .mob_m .menu li .sub:before {
    background: url(../img/mob_sep.png) repeat-x;
    content: '';
    height: 1px;
    display: block;
    position: absolute;
    left: 20px;
    right: 20px;
    top: 0;
  }
  .mob_m .menu li .sub:after {
    background: url(../img/mob_sep.png) repeat-x;
    content: '';
    height: 1px;
    display: block;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
  }
  .mob_m .menu li .sub a {
    font-size: 12px;
    font-family: 'open_sansregular', sans-serif;
    text-decoration: none;
    text-transform: none;
    position: relative;
    color: black;
    padding: 9px 0 8px 29px;
    margin: 0;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
  }
  .mob_m .menu li .sub a:hover {
    color: #fff;
  }
  .mob_m .menu li .sub a:before {
    background: white;
    content: '';
    width: 12px;
    height: 3px;
    display: block;
    position: absolute;
    left: 0;
    top: 15px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .mob_m .header__top-phone {
    padding-left: 40px;
    margin: 24px 0 0;
    position: relative;
    font-size: 12px;
    font-family: 'open_sanssemibold', sans-serif;
    color: #fff;
  }
  .mob_m .header__top-phone i {
    margin-right: 8px;
    position: absolute;
    left: 4px;
    top: 0;
  }
  .mob_m .header__top-phone a {
    font-size: 12px;
    font-family: 'open_sanssemibold', sans-serif;
    text-decoration: none;
    color: #fff;
  }
  .mob_m .header__top-mail {
    padding: 10px 0 25px;
    font-size: 12px;
  }
  .mob_m .header__top-mail a {
    font-size: 12px;
    font-family: 'open_sanslight', sans-serif;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    vertical-align: top;
  }
  .mob_m .header__top-mail i {
    margin: 2px 17px 0 4px;
  }
  .mob_m .header__top-links:before {
    background: url(../img/sep_white.png) repeat-x;
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    opacity: 0.3;
    margin: 0 0 15px;
  }
  .mob_m .header__top-links a {
    display: block;
    font-size: 12px;
    font-family: 'open_sanslight', sans-serif;
    color: white;
    padding: 6px 0 6px 3px;
  }

  .info_blocks {
    height: 1490px;
  }
  .info_blocks #wd1 {
    height: 290px;
  }
  .info_blocks #wd2 {
    left: 50%;
    height: 290px;
  }
  .info_blocks #wd3 {
    left: 0;
    height: 590px;
    top: 300px;
  }
  .info_blocks #wd4 {
    top: 300px;
    left: 50%;
    height: 590px;
  }
  .info_blocks #wd5 {
    left: 0;
    top: 900px;
    height: 290px;
  }
  .info_blocks #wd6 {
    left: 50%;
    top: 900px;
    height: 290px;
  }
  .info_blocks #wd7 {
    left: 0%;
    top: 1200px;
    height: 290px;
  }
  .info_blocks #wd7 span.s5 {
    margin: 27px 20px 13px;
  }
  .info_blocks #wd8 {
    left: 50%;
    top: 1200px;
    height: 290px;
  }

  .m_news {
    padding: 33px 0 38px;
  }
  .m_news p {
    line-height: 20px;
  }
  .m_news p.hd {
    font-size: 15px;
  }
  .m_news p.date {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .m_news p.date:after {
    margin-top: 9px;
    height: 3px;
    width: 53px;
  }
  .m_news .m_news_one {
    padding-bottom: 39px;
  }
  .m_news .m_news_one:before {
    background: url(../img/sep_white.png) repeat-x;
    content: '';
    height: 1px;
    width: 100%;
    display: block;
    margin: 25px 0 23px;
    opacity: 0.2;
  }
  .m_news .col-xs-12.col-sm-4:nth-child(1) .m_news_one:before {
    display: none;
  }

  .content .container,
  .bloggers .content .container {
    padding: 0 0 30px !important;
  }

  .fb-body .content .container {
    padding: 0 0 0 !important;
  }

  .pr_link {
    margin: 40px 52px 0;
  }
  .pr_link:before {
    left: 0px;
  }

  .job_list {
    margin-bottom: 0;
  }

  .prs_center {
    padding-bottom: 0px;
  }

  .content {
    padding-bottom: 0;
  }

  //   footer {
  //     position: relative;
  //     height: auto;
  //     padding-bottom: 31px;
  //     top: 0;
  //   }
  //   footer .footer__top {
  //     min-height: 1px;
  //     padding-top: 0px;
  //   }
  //   footer .footer__top .col-xs-12.col-sm-4:nth-child(1) p.hd:before {
  //     display: none;
  //   }
  //   footer .footer__top p {
  //     display: none;
  //   }
  //   footer .footer__top p.hd {
  //     display: block;
  //     position: relative;
  //     font-size: 12px;
  //     padding: 17px 0 15px;
  //     margin: 0;
  //     cursor: pointer;
  //     -webkit-transition: 0.3s linear;
  //     -moz-transition: 0.3s linear;
  //     -ms-transition: 0.3s linear;
  //     -o-transition: 0.3s linear;
  //     transition: 0.3s linear;
  //   }
  //   footer .footer__top p.hd:before {
  //     background: url(../img/sep_white.png) repeat-x;
  //     content: '';
  //     height: 1px;
  //     width: 100%;
  //     display: block;
  //     margin: 0;
  //     position: absolute;
  //     top: 0;
  //     opacity: 0.2;
  //   }
  //   footer .footer__top p.hd:after {
  //     background-image: url(../img/arr_d_white.png);
  //     background-size: 10px 6px;
  //     content: '';
  //     display: block;
  //     width: 10px;
  //     height: 6px;
  //     position: absolute;
  //     right: 4px;
  //     top: 21px;
  //     -webkit-transition: 0.3s linear;
  //     -moz-transition: 0.3s linear;
  //     -ms-transition: 0.3s linear;
  //     -o-transition: 0.3s linear;
  //     transition: 0.3s linear;
  //   }
  // }
  // @media only screen and (max-width: 817px) and (-moz-min-device-pixel-ratio: 1.5),
  //   only screen and (max-width: 817px) and (-o-min-device-pixel-ratio: 3 / 2),
  //   only screen and (max-width: 817px) and (-webkit-min-device-pixel-ratio: 1.5),
  //   only screen and (max-width: 817px) and (min-device-pixel-ratio: 1.5) {
  //   footer .footer__top p.hd:after {
  //     background-image: url(../img/arr_d_white@2x.png);
  //   }
}
@media only screen and (max-width: 817px) {
  .jubilee__calendar h2.jubilee__stars:after,
  .jubilee__calendar h2.jubilee__stars:before {
    display: none;
  }
  .jubilee__calendar h2.jubilee__stars {
    font-size: 35px;
    line-height: 50px;
  }
  // footer .footer__top p.hd.open {
  //   margin-bottom: 15px;
  // }
  // footer .footer__top p.hd.open:after {
  //   -moz-transform: rotate(180deg);
  //   -o-transform: rotate(180deg);
  //   -ms-transform: rotate(180deg);
  //   -webkit-transform: rotate(180deg);
  //   transform: rotate(180deg);
  // }

  .contacts .inf_block_one .rhombus {
    right: auto;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    -moz-transform: scale(1) rotate(45deg);
    -o-transform: scale(1) rotate(45deg);
    -ms-transform: scale(1) rotate(45deg);
    -webkit-transform: scale(1) rotate(45deg);
    transform: scale(1) rotate(45deg);
  }

  .info_w .inf_block_one {
    width: 250px;
  }

  .head.polk_list {
    font-size: 40px;
  }

  .bridge_view .heading {
    margin: 0;
  }
  .bridge_view .params p {
    margin: 0 10px 17px;
  }
  .bridge_view .wysiwyg {
    margin: 0 0 21px;
  }
  .bridge_view .audioguide .hd {
    font-size: 20px;
    padding: 0;
  }

  .bloggers footer {
    bottom: 0px;
  }

  .slider .slide_one .completion img {
    margin: 25px auto 30px;
  }
  .slider .slide_one .completion p {
    font-size: 14px;
    line-height: 23px;
    text-transform: none;
  }
}
@media only screen and (max-width: 680px) {
  .bridge_list.embankments h1.head {
    margin-bottom: 10px !important;
  }
  .piers-btn {
    font-size: 12px;
    padding: 0 15px 0 36px;
    height: 30px;
    line-height: 30px;
    float: left;
    margin-bottom: 10px;
  }
  .piers-btn i.anchor {
    top: 3px;
    left: 7px;
  }
  .jubilee__top {
    padding: 40px 0;
  }
  .jubilee__image {
    border: 0;
  }
  .jubilee__top {
    height: auto;
  }
  .jubilee__text {
    padding-right: 0;
  }
  .jubilee__head {
    padding-bottom: 25px;
    font-size: 50px;
    line-height: 70px;
  }
  .jubilee__current_item {
    width: 50%;
  }
  .jubilee__current_item:nth-child(3n) {
    border-right: 1px dashed #fff;
  }
  .jubilee__current_item:nth-child(2n) {
    border-right: none;
  }
  .error_page {
    padding: 20px 0;
  }
  .error_page .error_icon {
    background-size: 240px 241px;
    background-position: center;
    height: 240px;
    width: 241px;
  }
  .error_page h1 span {
    font-size: 140px;
  }

  .prs_center .cnt_one .txt_bottom {
    bottom: 11px;
    padding: 0 0 0 15px;
  }

  .news_list a.news_link span.name {
    height: 40px;
  }

  .news_list a.news_link span.txt {
    top: 45.5%;
  }

  .slider .slide_one p {
    padding: 0 10px;
  }

  .news_list a.news_link span.txt {
    height: 80px;
    overflow: hidden;
  }

  .prs_center .cnt_one {
    margin-bottom: 10px;
  }
  .prs_center .cnt_one p {
    font-size: 12px;
  }
  .prs_center .cnt_one p.hd {
    font-size: 12px;
  }
  .prs_center .cnt_one p a {
    font-size: 100%;
  }

  .head {
    font-size: 30px;
  }

  .bridge_list .buttons_geay {
    margin-top: 15px;
  }

  .bridge_list .buttons_geay a {
    font-size: 12px;
    padding: 0 15px 0 36px;
    height: 30px;
    line-height: 30px;
  }

  .bridge_list .buttons_geay i {
    top: 5px;
    left: 8px;
  }
  .wysiwyg table.vuz_table td {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .wysiwyg p {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .wysiwyg h1,
  .wysiwyg h2 {
    font-size: 25px;
  }
  .wysiwyg h3 {
    font-size: 20px !important;
  }
  .wysiwyg h4 {
    font-size: 16px;
  }
  .wysiwyg ul li,
  .wysiwyg ol li {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .timetable .time_list {
    display: none;
  }
  .timetable .wr {
    padding: 0;
  }
  .timetable .bridge_wrapper {
    margin-right: 40px;
    margin-left: 40px;
  }
  .timetable .bridge .name {
    float: none;
    width: auto;
    margin: 25px 0 10px 10px;
  }
  .timetable .bridge .line_wrapper {
    margin: 0;
    margin-left: 0px;
    top: 0;
    min-height: 1px;
  }
  .timetable .bridge .line_wrapper .line_cur:before {
    left: 13px;
    right: 13px;
  }
  .timetable .bridge .line_wrapper .line_cur span {
    font-size: 12px;
    width: 26px;
    top: -5px;
  }
  .timetable .bridge .line_wrapper .line_cur span.s1 {
    left: -13px;
  }
  .timetable .bridge .line_wrapper .line_cur span.s2 {
    right: -13px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec:before {
    left: 13px;
    right: 13px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec .line_cur_sec_wrapper:before {
    left: 13px;
    right: 13px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec span {
    font-size: 12px;
    width: 26px;
    top: -5px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec span.s1 {
    left: -13px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec span.s2 {
    right: -13px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec .line.l_left:before {
    left: 13px;
  }
  .timetable .bridge .line_wrapper .line_cur_sec .line.l_right:before {
    right: 13px;
  }
  .timetable .bridge .line_wrapper .line.l_left:before {
    right: 13px;
  }
  .timetable .bridge .line_wrapper .line.l_right:before {
    left: 13px;
  }

  .team {
    text-align: center;
  }
  .team img {
    width: auto;
    max-width: 100%;
  }

  .regiment .wysiwyg img {
    display: block;
    margin: 20px auto;
    float: none;
  }

  .contest--head {
    font-size: 30px;
  }
  .contest--title {
    font-size: 25px;
  }
  .contest--title:after {
    margin-top: 15px;
  }
  .contest .likes {
    font-size: 12px;
    height: 20px;
    line-height: 21px;
    padding: 0 8px;
  }
  .contest .likes i {
    font-size: 12px;
  }
}
@media screen and (max-width: 700px) {
  .pages ul li a {
    margin: 0 10px;
  }
}
@media screen and (max-width: 600px) {
  .page-nav_arr {
    top: 55px;
  }
  .history-paginate__btn {
    margin-top: 60px;
  }
  .pages ul li a {
    margin: 0 3px;
  }
  .history-paginate__btn {
    margin-top: 65px;
  }
}
@media only screen and (max-width: 640px) {
  .brige-100_years .m_text .wysiwyg .video_wrap iframe,
  .river-video .video_wrap iframe {
    top: 0px;
    left: 0px;
    width: 100% !important;
    height: 100% !important;
    right: 0px;
  }

  .bridge_list.piers .buttons_geay {
    margin-bottom: 100px;
  }

  .staff.view .img {
    margin-top: 10px;
  }

  .regiment .head {
    font-size: 45px;
  }
  .regiment i.star,
  .regiment i.stars {
    display: none;
  }
  .regiment .slick-next {
    right: 0;
  }
  .regiment .slick-prev {
    left: 0;
  }

  .info_blocks #wd7 span.s5 {
    margin: 27px 10px 13px;
  }

  .wr.info_w {
    padding: 0;
  }
  .wr.info_w .inf_block_one {
    position: relative;
    top: 0;
    width: 100%;
    margin-bottom: 0px;
  }

  .brige-100_years .f_head {
    font-size: 25px;
  }
  .brige-100_years .f_head span {
    font-size: 55px;
  }

  .bridge_view .boggers-flag,
  .bridge_view .raising-flag {
    height: 145px;
    padding: 0;
    width: 140px;
  }
  .bridge_view .boggers-flag .discr,
  .bridge_view .raising-flag .discr {
    display: none;
  }

  .brige-100_years .pb_banner h1 {
    font-size: 25px;
    margin-bottom: 30px;
  }

  .brige-100_years .pb_banner .hd {
    font-size: 35px;
    line-height: 38px;
  }

  .modal .name {
    text-align: center;
  }
  .modal .right {
    text-align: center;
    padding-top: 10px;
  }

  /* // .bridge_list.piers .search_b
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                //   text-align: center;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                //   width: 90%;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // .bridge_list.piers .search_b form.select
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  //   margin: auto;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // }

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                } */
}

@media only screen and (max-width: 560px) {
  .history__one-day__events {
    padding-top: 35px;
  }
  .dir .blog_item .img {
    float: none;
    margin: 0 auto 10px;
  }

  .news_list a.news_link span.txt {
    height: auto;
    overflow: hidden;
  }

  .head {
    font-size: 25px;
    line-height: 40px;
  }
  .history__title {
    font-size: 30px;
    line-height: 40px !important;
  }

  .contacts .inf_block_one .rhombus {
    right: auto;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    -moz-transform: scale(0.7) rotate(45deg);
    -o-transform: scale(0.7) rotate(45deg);
    -ms-transform: scale(0.7) rotate(45deg);
    -webkit-transform: scale(0.7) rotate(45deg);
    transform: scale(0.7) rotate(45deg);
  }

  .chief .banner img {
    max-width: 100%;
    height: auto;
  }

  .chief .banner .name {
    font-size: 24px;
    margin-left: 20px;
  }

  .prs_center .cnt_one p {
    font-size: 15px;
  }
  .prs_center .cnt_one p.hd {
    font-size: 13px;
    top: 20px;
    left: 0px;
    padding: 0 0 0 20px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .prs_center .cnt_one .txt_bottom {
    padding: 0 0 0 20px;
    bottom: 10px;
  }
  .prs_center .cnt_one .txt_bottom p {
    font-size: 12px;
  }
  .prs_center .cnt_one .rhombus {
    -moz-transform: scale(0.7) rotate(45deg);
    -o-transform: scale(0.7) rotate(45deg);
    -ms-transform: scale(0.7) rotate(45deg);
    -webkit-transform: scale(0.7) rotate(45deg);
    transform: scale(0.7) rotate(45deg);
  }
  .prs_center .contact_txt .user_img {
    float: none;
    margin: 0 auto;
  }
  .prs_center .contact_txt .wysiwyg {
    margin: 0;
  }

  .pagination_index .pag {
    width: 230px;
    margin: 0 auto 10px;
    float: none;
  }
  .pagination_index .pag_select {
    float: none;
    width: 168px;
    margin: 10px auto;
  }
}
@media only screen and (max-width: 529px) {
  .jubilee__current_item {
    width: 100%;
    border-right: none;
  }
  .jubilee__current_item:nth-child(3n) {
    border-right: none;
  }
  .jubilee__current_item:nth-child(2n) {
    border-right: none;
  }
  .light.big_form .checkbox {
    line-height: 20px;
  }

  .error_page {
    text-align: center;
  }
  .error_page .error_icon {
    background-size: 200px 201px;
    background-position: center;
    height: 200px;
    width: 201px;
    margin: 0 auto;
  }
  .error_page h1 span {
    font-size: 100px;
  }

  .pagination a.round {
    font-size: 12px;
  }
  .pagination a.btn_prev {
    padding: 0 10px 0 18px;
  }
  .pagination a.btn_prev i {
    left: 9px;
  }
  .pagination a.btn_next {
    padding: 0 18px 0 10px;
  }
  .pagination a.btn_next i {
    right: 9px;
  }
  .pagination a.btn_back {
    padding: 0 10px;
  }

  .breadcrumbs {
    margin-bottom: 15px;
  }

  .head.sec_br {
    margin-bottom: 35px;
  }

  .header__bottom p.hd {
    font-size: 0;
    padding: 0 15px;
  }
  .header__bottom p.hd:after {
    content: '!!!';
    font-size: 14px;
    font-family: 'open_sansextrabold', sans-serif;
  }
  .header__bottom a.btn_close_message {
    font-size: 0;
    padding: 0 24px 0 0;
    right: 5px;
  }
  .header__bottom a.btn_close_message i {
    right: 4px;
  }
  .header__bottom .message {
    padding: 0 0 0 13px;
    margin: 0 0 0 8px;
  }
  .header__bottom .wr {
    padding: 0;
  }
  .header__bottom .brige-100_years .pb_banner h1 {
    font-size: 21px;
  }

  .prs_center .cnt_one {
    margin: 0 25px 10px;
  }
  .prs_center .cnt_one p {
    font-size: 15px;
  }
  .prs_center .cnt_one p.hd {
    font-size: 13px;
    top: 20px;
    left: 0px;
    padding: 0 0 0 40px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .prs_center .cnt_one .txt_bottom {
    padding: 0 0 0 40px;
    bottom: 20px;
  }
  .prs_center .cnt_one .txt_bottom p {
    font-size: 15px;
  }
  .prs_center .cnt_one .rhombus {
    -moz-transform: scale(1) rotate(45deg);
    -o-transform: scale(1) rotate(45deg);
    -ms-transform: scale(1) rotate(45deg);
    -webkit-transform: scale(1) rotate(45deg);
    transform: scale(1) rotate(45deg);
  }
  .prs_center .form {
    padding: 26px 25px;
  }

  .job_list .bg_gray {
    padding: 20px;
  }

  .contacts .inf_block_one .rhombus {
    margin-top: 0;
    left: auto;
    top: 12px;
    right: 6px;
  }

  .bg_gray .light.big_form {
    margin-bottom: 0px;
  }

  .light.big_form .doc_slide p.date {
    padding-top: 26px;
  }

  .job_list a.button {
    font-size: 12px;
  }

  .info_blocks {
    height: auto;
    padding: 20px 0 10px;
  }
  .info_blocks .widget {
    position: static !important;
    margin: 0 auto 10px;
    width: 100%;
  }
  .info_blocks .widget span.s2 {
    font-size: 15px;
    line-height: 25px;
  }
  .info_blocks .widget span.s4 {
    font-size: 55px;
  }
  .info_blocks .widget span.s5 {
    font-size: 20px;
  }
  .info_blocks .widget span.details {
    font-size: 12px;
  }
  .info_blocks .widget a,
  .info_blocks .widget .link {
    margin: 0 auto;
    width: 290px !important;
  }
  .info_blocks #wd1 {
    height: 290px;
  }
  .info_blocks #wd2 {
    left: 0;
    height: 290px;
  }
  .info_blocks #wd3 {
    left: 0;
    height: 590px;
    top: 300px;
  }
  .info_blocks #wd4 {
    top: 300px;
    left: 0;
    height: 590px;
  }
  .info_blocks #wd5 {
    left: 0;
    top: 900px;
    height: 290px;
  }
  .info_blocks #wd6 {
    left: 0;
    top: 900px;
    height: 290px;
  }
  .info_blocks #wd7 {
    left: 0%;
    top: 1200px;
    height: 290px;
  }
  .info_blocks #wd8 {
    left: 0;
    top: 1200px;
    height: 290px;
  }

  .copyright {
    padding-top: 31px;
  }
  .copyright span {
    margin-right: 19px;
  }

  .siluet {
    margin: 6px 0 10px;
    padding-left: 40px;
  }
  .siluet i {
    left: 1px;
    top: -4px;
  }

  .pr_link {
    margin: 10px 0 10px 40px;
  }
  .pr_link:before {
    left: -9px;
    top: 1px;
  }
}
@media only screen and (max-width: 816px) {
  .socials {
    text-align: left;
  }
  .mob-socials {
    display: block;
  }
  .modal-img-col {
    text-align: center;
    margin-bottom: 20px;
  }
  .fb-modal-text {
    padding-left: 0;
  }
}
@media only screen and (max-width: 529px) {
  .socials {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 0;
    text-align: left;
  }
  .news_list a.news_link {
    padding: 0 0 8%;
  }
  .news_list a.news_link span {
    position: relative;
    display: block;
  }
  .news_list a.news_link span.date {
    top: 0;
    padding: 8% 7% 0 10%;
  }
  .news_list a.news_link span.name {
    height: auto;
    top: 0;
    margin: 10px 0 0;
  }
  .news_list a.news_link span.details {
    bottom: 0;
  }
  .news_list a.news_link span.txt {
    top: 0;
    margin: 10px 0;
  }

  .bridge_list .search_b p {
    float: none;
    padding: 9px 0 4px 29px;
  }
  .bridge_list .search_b p br {
    display: none;
  }
  .bridge_list.embankments .search_b {
    margin-top: 10px;
  }
  .bridge_list.embankments .search_b form.select {
    display: block;
    top: auto;
  }
  .bridge_list.embankments .buttons_geay {
    margin-bottom: 100px;
  }

  .facts h1 {
    line-height: 40px;
  }
  .facts h1 span {
    font-size: 55px;
    margin-bottom: 10px;
  }

  .bridge_list .search_b {
    margin-top: 45px;
    left: 20px;
  }

  .bridge_list hr {
    margin-bottom: 15px;
  }

  .bridge_list .search_b form.select {
    top: -80px;
    z-index: 10;
    left: 0;
  }

  .bridge_list .categories {
    padding-top: 95px;
  }

  .bridge_view .boggers-flag,
  .bridge_view .raising-flag {
    float: right;
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    margin: 0;
  }
  .bridge_view .boggers-flag {
    background: #e1e6ed;
  }
  .bridge_view .raising-flag {
    background: #2e3339;
  }
  .bridge_view .raising-flag.last-flag {
    width: 100%;
  }

  .brige-100_years .pb_banner h1 {
    font-size: 20px;
  }

  .brige-100_years .pb_banner .namber {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .jubilee__button {
    /* 
  margin: 0 5%;
  left: auto;
  transform: none; */
  }
}
@media only screen and (max-width: 506px) {
  .bridge_list.piers .search_b {
    margin-top: 45px;
  }
}

@media only screen and (max-width: 470px) {
  .brige-100_years .pb_banner .hd {
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .brige-100_years .pb_banner p {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media only screen and (max-width: 450px) {
  .history__one-day__events .img-wrap {
    padding: 8px;
  }
  .jubilee__gallery_item {
    width: 100%;
  }
  .jubilee__gallery_item {
    margin-right: 0;
  }
  .info_blocks .widget span.s2 {
    margin-top: 27px;
  }
  .info_blocks .widget span.details {
    bottom: 20px;
  }

  .timetable,
  .slider .slide_one {
    height: 600px;
  }

  .timetable .bridge .line_wrapper .line_cur_sec_wrapper {
    min-width: 24px;
  }

  .contacts .inf_block_one .rhombus {
    right: auto;
    left: 50%;
    top: 50%;
    margin-top: -50px;
  }

  .bridge_view .pagination .wr {
    padding: 0 5px;
  }
  .bridge_view .pagination .wr a {
    font-size: 12px;
  }
  .bridge_view .pagination .wr a.btn_back {
    padding: 0 5px;
  }
  .bridge_view .pagination .wr a.btn_prev {
    left: 5px;
  }
  .bridge_view .pagination .wr a.btn_next {
    right: 5px;
  }
  .bridge_view .pagination .wr a i {
    top: 10px !important;
  }

  .form .input p span b {
    display: none;
  }
  .form .input p span i {
    display: inline;
  }
}
@media only screen and (max-width: 420px) {
  .history-navigation .back-btn.history-btn {
    width: 50%;
    margin-top: -9px;
  }
  .info_w .inf_block_one {
    width: 240px;
    height: 240px;
  }
  .info_w .inf_block_one .rhombus {
    top: 55px !important;
  }

  .slider-polk .img-wrap {
    width: 180px;
  }
  .slider-polk .slick-arrow {
    height: 40px;
    width: 40px;
  }

  .photo_list .gallery_link.brige_p_l span span.txt,
  .photo_list .gallery_link_sec.brige_p_l span span.txt {
    height: 60px;
  }

  .regiment .to-video .caption {
    font-size: 17px;
    padding: 6px 5px;
  }

  .persons .img-wrap {
    width: 180px;
  }

  .brige-100_years .pb_banner h1 {
    line-height: 30px;
  }

  .facts h1 span {
    font-size: 45px;
  }

  .info_blocks .wr {
    padding: 0;
  }

  .bridge_list.embankments .search_b {
    margin-top: 45px;
  }

  .cont_fin.contest .contest--head {
    font-size: 25px;
  }
  .cont_fin.contest .contest--title,
  .cont_fin.contest .winner--name {
    font-size: 20px;
  }
  .cont_fin.contest .contest--title span:not(.likes),
  .cont_fin.contest .winner--name span:not(.likes) {
    font-size: 14px;
  }
}
@media only screen and (max-width: 330px) {
  .brige-100_years .pb_banner h1 {
    line-height: 20px;
  }
}
.regiment footer .footer__top p:last-child a {
  color: #222a32 !important;
}
/* .regiment .container{
                                                                      // padding-bottom: 350px;
                                                                    } */

.bridge__flags {
  position: absolute;
  right: 0;
  top: 15px;
}
.bridge__flags:after {
  content: '';
  clear: both;
  display: block;
}
.bridge_view .boggers-flag,
.bridge_view .raising-flag {
  position: relative;
  float: right;
  right: 0;
  margin-right: 20px;
}
@media only screen and (max-width: 640px) {
  .bridge__flags {
    position: relative;
  }
  .bridge_view .boggers-flag,
  .bridge_view .raising-flag {
    float: left;
    margin-right: 0;
    top: auto;
    width: 33.3333%;
  }
}
@media only screen and (max-width: 529px) {
  .bridge_view .boggers-flag.jubilee-flag {
    background: #32aee2 !important;
    width: 100%;
  }
  .bridge_view .boggers-flag,
  .bridge_view .raising-flag {
    width: 100%;
  }
  a.boggers-flag.jubilee-flag.m--offset {
    right: auto;
  }
}

@media screen and (max-width: 768px) {
  .history__slogan .slogan-text {
    padding-right: 0;
  }
  .history__slogan .slogan {
    font-size: 40px;
    line-height: 45px;
  }
}
@media screen and (max-width: 425px) {
  .fb-obj-select {
    padding: 11px 44px;
  }
  .fb-obj-select img {
    left: 15px;
  }
  .fb-obj-select span {
    overflow: hidden;
    width: 54%;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
    white-space: nowrap;
  }
  .fb_banner .fb-btn {
    margin-bottom: 20px;
  }
  .slider-col {
    width: 98%;
    margin-left: 1%;
  }
  .slide-nav-arrow.left {
    z-index: 10;
    left: 10px;
    position: absolute;
  }
  .slide-nav-arrow.right {
    position: absolute;
    right: 11px;
    z-index: 10;
  }
  .fb_banner .fb_banner {
    margin-bottom: 35px;
  }
  .fb-obj-list {
    width: 100%;
    box-sizing: border-box;
  }
  .fb-path-what-look-btns .fb-slide-btn.dark {
    margin-left: 0;
  }
  .fb-obj-list label {
    font-size: 13px;
  }
  .history__slogan .slogan {
    font-size: 30px;
    line-height: 35px;
  }
  .routes_count {
    font-size: 100px;
    line-height: 100px;
  }
  .route_title-2,
  .route_title-1 {
    font-size: 7vw;
  }
  .route_title-1 {
    left: 124px;
  }
  .route_title-2 {
    left: 145px;
  }
  .routes__header {
    margin-bottom: 50px;
  }
  .route__list {
    width: 100%;
  }
  .fb-slide-btn {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
  }

  .route__chart {
    padding: 0;
    margin-top: 40px;
  }
  .route__slider-nav {
    position: absolute;
    bottom: -40px;
    margin: auto;
    text-align: center;
    width: 100%;
    right: auto;
  }

  .fb_news,
  .fb_sbornaya {
    padding: 0;
    padding-bottom: 20px;
  }
  .routes_title {
    margin-bottom: 20px;
  }
  /*.fancybox-open {
  top: 10% !important;
  }*/
}
