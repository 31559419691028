body.spez .header__top-socials img {
  filter: invert(1);
}
body.spez .header__top-socials {
  margin: 3px 20px 0 8%;
}

body.spez {
  .main-intro {
    &__plate {
      & > .item {
        .item__title {
          color: #000;
        }

        .item__content {
          .date {
            color: #000;
          }

          .text {
            color: #000;
            p {
              color: #000;
            }
          }
        }

        .item__btns {
          a {
            border-color: #000;
            background-color: #000;
            color: #fff;

            &.map {
              &:before {
                filter: invert(1);
              }
            }
          }
        }

        .item__select {
          .select__head {
            .select2-container--default {
              .select2-selection--single {
                border: 1px solid #000;
              }
            }
          }
        }

        &.item_time {
          background-color: #fff;

          .item__btns {
            a {
              color: #fff;
            }
          }
        }

        &.item_today {
          background-color: #fff;

          .item__content {
            .text {
              color: #000;
            }
          }
        }

        &.item_app {
          background-color: #fff;
        }
      }
    }
  }

  .main-about {
    .item {
      &__head {
        .icon {
          border-color: #000;
        }
      }

      &__content {
        .link {
          color: #000;
        }
      }
    }
  }

  .main-projects {
    .slider {
      .slick-prev {
        &:before {
          filter: brightness(0);
        }
      }
    }

    .slide {
      &__content {
        border: 1px solid #000;
        background-color: #fff;
      }

      &__links {
        a {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }

  .main-banners {
    filter: grayscale(1);

    .item {
      &_contacts {
        .item__phone {
          i {
            filter: brightness(0);
          }
        }

        .item__mail {
          i {
            filter: brightness(0);
          }
        }
      }
    }
  }

  .main-map {
    &__tabs {
      button {
        filter: grayscale(100%);
      }
    }

    &__contents {
      filter: grayscale(100%);
    }
  }

  .news_list_fullwidth {
    .news_elem {
      &__img {
        filter: grayscale(100%);
      }
      &__content {
        .date {
          color: #000;
        }
      }
    }
  }

  .block-title {
    .link {
      color: #000;
    }
  }

  .content-with-map {
    .btn {
      background-color: #fff;
      border: 2px solid #000;
      color: #000;

      i {
        filter: grayscale(1) invert(0) brightness(0);
      }
    }
  }

  &.color-black {
    .main-intro {
      &__plate {
        & > .item {
          .item__title {
            color: #fff;
          }

          .item__content {
            .date {
              color: #fff;
            }

            .text {
              color: #fff;
              p {
                color: #fff;
              }
            }
          }

          .item__btns {
            a {
              border-color: #fff;
              background-color: #fff;
              color: #000;

              &.map {
                &:before {
                  filter: invert(0);
                }
              }
            }
          }

          .item__select {
            .select__head {
              margin-bottom: 12px;
              position: relative;

              .select2-container--default {
                .select2-selection--single {
                  border: 2px solid #fff;
                  border-radius: 100px;
                }
              }
            }
          }

          &.item_time {
            background-color: #000;

            .item__btns {
              a {
                color: #000;
              }
            }
          }

          &.item_today {
            background-color: #000;

            .item__content {
              .text {
                color: #fff;
              }
            }
          }

          &.item_app {
            background-color: #000;
          }
        }
      }
    }

    .main-news {
      .item {
        &__name {
          color: #fff;
        }
      }
    }

    .main-about {
      .item {
        &__head {
          .icon {
            border-color: #fff;

            img {
              filter: invert(1);
            }
          }
        }

        &__content {
          .link {
            color: #fff;
          }
        }
      }
    }

    .main-projects {
      .slider {
        .slick-prev {
          &:before {
            filter: brightness(0) invert(1);
          }
        }
      }

      .slide {
        &__content {
          border: 1px solid #fff;
          background-color: #000;
        }

        &__title {
          color: #fff;
        }

        &__links {
          a {
            background-color: #fff;
            color: #000;
          }
        }
      }
    }

    .main-banners {
      filter: grayscale(1);

      .item {
        &_contacts {
          .item__name {
            color: #000;
          }
          .item__phone {
            color: #000;
            i {
              filter: brightness(0) invert(0);
            }
          }

          .item__mail {
            color: #000;
            i {
              filter: brightness(0) invert(0);
            }
          }
        }
      }
    }

    .main-seo {
      &__text {
        &:after {
          display: none;
        }
      }
    }

    .news_list_fullwidth {
      .news_elem {
        &__content {
          .name,
          .date,
          .txt p {
            color: #fff;
          }
        }
      }
    }

    .content-with-map {
      .title_h2 {
        color: #fff;
      }

      .btn {
        background-color: #000;
        border: 2px solid #fff;
        color: #fff;

        i {
          filter: grayscale(1) invert(1) brightness(100);
        }
      }
    }

    .block-title {
      .link {
        color: #fff;
      }
    }
  }

  &.scale-2 {
    .main-intro {
      &__plate {
        & > .item {
          .item__title {
            font-size: 19px;
          }

          .item__content {
            .date {
              font-size: 52px;
            }

            .text {
              font-size: 16px;

              p {
                font-size: 16px;
              }
            }
          }

          .item__btns {
            a {
              font-size: 12px;
            }
          }

          .item__select {
            .select__head {
              .select2-container--default {
                .select2-selection--single {
                  font-size: 27px;
                }
              }
            }

            .select__content {
              .elem {
                .key {
                  font-size: 19px;
                }

                .value {
                  font-size: 22px;

                  p {
                    font-size: 22px;
                  }

                  &_timer {
                    font-size: 37px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .main-about {
      .item {
        &__head {
          .name {
            font-size: 19px;
          }
        }

        &__content {
          p {
            font-size: 17px;
          }
        }
      }
    }

    .main-news {
      .item {
        &__date {
          font-size: 17px;
        }

        &__name {
          font-size: 17px;
        }
      }
    }

    .main-projects {
      .slide {
        &__title {
          font-size: 19px;
        }

        &__links {
          a {
            font-size: 12px;
          }
        }
      }
    }

    .main-banners {
      .item {
        &_contacts {
          .item__name {
            font-size: 22px;
          }
          .item__phone {
            font-size: 19px;
          }

          .item__mail {
            font-size: 19px;
          }
        }
      }
    }

    .main-map {
      &__tabs {
        button {
          font-size: 12px;
        }
      }

      &__content {
        .list {
          &__item {
            .key {
              font-size: 19px;
            }

            .value {
              p {
                font-size: 22px;
              }
            }
          }
        }
      }
    }

    .news_list_fullwidth {
      .news_elem {
        &__content {
          .date {
            font-size: 17px;
          }

          .name {
            font-size: 22px;
          }

          .txt p {
            font-size: 17px;
          }
        }
      }
    }

    .block-title {
      h2 {
        font-size: 32px;
      }
      .link {
        font-size: 17px;
      }
    }
  }

  &.scale-3 {
    .main-intro {
      &__plate {
        & > .item {
          .item__title {
            font-size: 21px;
          }

          .item__content {
            .date {
              font-size: 54px;
            }

            .text {
              font-size: 18px;

              p {
                font-size: 18px;
              }
            }
          }

          .item__btns {
            a {
              font-size: 14px;
            }
          }

          .item__select {
            .select__head {
              .select2-container--default {
                .select2-selection--single {
                  font-size: 29px;
                }
              }
            }

            .select__content {
              .elem {
                .key {
                  font-size: 21px;
                }

                .value {
                  font-size: 24px;

                  p {
                    font-size: 24px;
                  }

                  &_timer {
                    font-size: 39px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .main-about {
      .item {
        &__head {
          .name {
            font-size: 21px;
          }
        }

        &__content {
          p {
            font-size: 19px;
          }
        }
      }
    }

    .main-news {
      .item {
        &__date {
          font-size: 19px;
        }

        &__name {
          font-size: 19px;
        }
      }
    }

    .main-projects {
      .slide {
        &__title {
          font-size: 21px;
        }

        &__links {
          a {
            font-size: 14px;
          }
        }
      }
    }

    .main-banners {
      .item {
        &_contacts {
          .item__name {
            font-size: 24px;
          }
          .item__phone {
            font-size: 21px;
          }

          .item__mail {
            font-size: 21px;
          }
        }
      }
    }

    .main-map {
      &__tabs {
        button {
          font-size: 14px;
        }
      }

      &__content {
        .list {
          &__item {
            .key {
              font-size: 21px;
            }

            .value {
              p {
                font-size: 24px;
              }
            }
          }
        }
      }
    }

    .news_list_fullwidth {
      .news_elem {
        &__content {
          .date {
            font-size: 19px;
          }

          .name {
            font-size: 24px;
          }

          .txt p {
            font-size: 19px;
          }
        }
      }
    }

    .block-title {
      h2 {
        font-size: 34px;
      }
      .link {
        font-size: 19px;
      }
    }
  }
}

body.spez .header__top .header__top-phone i {
  background-image: url(../img/icon_phone.png);
}
body.spez .header__top .header__top-mail i {
  background-image: url(../img/icon_mail.png);
}
body.spez.history-day .history__title {
  color: #000;
  font-size: 60px;
  margin-bottom: 70px;
  line-height: initial;
}
body.spez.history-day.scale-2 .history__title {
  font-size: 65px;
  line-height: initial;
}
body.spez.history-day.scale-3 .history__title {
  font-size: 70px;
  line-height: initial;
}

body.spez.history-day .history__info {
  line-height: 40px;
  font-style: normal;
  font-size: 22px;
}
body.spez.history-day.scale-2 .history__info {
  font-size: 24px;
}
body.spez.history-day.scale-3 .history__info {
  font-size: 26px;
}
body.spez.history-day .history__last-messages h2 {
  font-size: 55px;
  color: #000;
}
body.spez.history-day .scale-2 .history__last-messages h2,
body.spez.history-day .header__middle .logo {
  background-image: url(../img/logo.png);
  background-size: contain;
  display: block;
  margin: 18px 0 0 1px;
}
body.spez.jubilee .jubilee__current_item h3,
body.spez.jubilee .jubilee__current_date,
body.spez.jubilee .jubilee__current_time {
  color: #000;
}
body.spez.jubilee.color-black .jubilee__current_item h3,
body.spez.jubilee.color-black .jubilee__current_date,
body.spez.jubilee.color-black .jubilee__current_time,
body.spez.jubilee.color-black .jubilee__icon {
  color: #fff;
}
body.spez.history-day .history__current-day p.history__date {
  font-size: 35px !important;
}
body.spez.history-day .history__current-day:after,
body.spez.history-day .history__last-messages:after,
body.spez.history-day .history__head:after,
body.spez.history-day .history__archieve:before {
  content: none;
}
body.spez.history-day.scale-2 .history__current-day p.history__date {
  font-size: 37px !important;
}
body.spez.history-day.scale-3 .history__current-day p.history__date {
  font-size: 40px !important;
}
body.spez.history-day .history__current-day p,
body.spez.history-day .image-sign,
body.spez.history-day .history-paginate__btn,
body.spez.history-day .history-btn,
body.spez.history-day .history__last-messages-btn,
body.spez.history-day .history__one-day__events p,
body.spez.history-day .pages ul li a {
  font-size: 18px !important;
  font-style: normal;
  text-shadow: none;
}
body.spez.history-day.scale-2 .history__current-day p,
body.spez.history-day.scale-2 .image-sign,
body.spez.history-day.scale-2 .history-btn,
body.spez.history-day.scale-2 .history-paginate__btn,
body.spez.history-day.scale-2 .history__last-messages-btn,
body.spez.history-day.scale-2 .history__one-day__events p,
body.spez.history-day.scale-2 .pages ul li a {
  font-size: 19px !important;
  font-style: normal;
}
body.spez.history-day.scale-3 .history__current-day p,
body.spez.history-day.scale-3 .image-sign,
body.spez.history-day.scale-3 .history-btn,
body.spez.history-day.scale-3 .history-paginate__btn,
body.spez.history-day.scale-3 .history__last-messages-btn,
body.spez.history-day.scale-3 .history__one-day__events p,
body.spez.history-day.scale-3 .pages ul li a {
  font-size: 21px !important;
  font-style: normal;
}
body.spez.history-day .history__slogan .author,
body.spez.history-day .history__last-messages .date {
  font-size: 22px;
}
body.spez.history-day.scale-2 .history__slogan .author,
body.spez.history-day.scale-2 .history__last-messages .date {
  font-size: 24px;
}
body.spez.history-day.scale-3 .history__slogan .author,
body.spez.history-day.scale-3 .history__last-messages .date {
  font-size: 26px;
}
body.spez.history-day.color-black .header__middle,
body.spez.history-day.color-black .history__head,
body.spez.history-day.color-black .history__one-day__events,
body.spez.history-day.color-black .history__current-day,
body.spez.history-day.color-black .history-navigation,
body.spez.history-day.color-black .history__slogan,
body.spez.history-day.color-black .history__last-messages,
body.spez.history-day.color-black .history__calendar,
body.spez.history-day.color-black .history__archieve-head,
body.spez.history-day.color-black .history-paginate {
  background: none;
}
body.spez.history-day.color-black .header__middle:after {
  content: none;
}

body.spez.history-day.color-black .pages ul li a,
body.spez.history-day.color-black .history-paginate__btn {
  background: #000;
  color: #fff;
}
body.spez.history-day.color-black .history-btn {
  border: 2px solid white;
  background: black;
}
body.spez.jubilee .jubilee__grayblock {
  border: 1px solid #000;
}
body.spez.jubilee.color-black .jubilee__grayblock {
  border: 1px solid #fff;
}
body.spez.jubilee.color-black header,
body.spez.jubilee.color-black .jubilee__bridge,
body.spez.jubilee.color-black .jubilee__content,
body.spez.jubilee.color-black .jubilee__grayblock,
body.spez.jubilee.color-black .jubilee__gallery,
body.spez.jubilee.color-black .jubilee__main {
  background: #000;
}

body.spez.history-day.color-black .history__title,
body.spez.history-day.color-black .history__info,
body.spez.history-day.color-black .history__current-day p.history__date,
body.spez.history-day.color-black .history__slogan p,
body.spez.history-day.color-black .history__calendar-year,
body.spez.history-day.color-black .history-calendar__month-name,
body.spez.history-day.color-black .history__archieve-head h1 {
  color: #fff !important;
}
body.spez.history-day.color-black .history-calendar__month,
body.spez.history-day.color-black .history__calendar-table td {
  background: #000;
}
body.spez.jubilee.color-black .jubilee__gallery_btn {
  color: #000;
  border-color: #000;
  background: #fff;
}

body.spez .mob_m {
  top: 200px;
  background: #000;
}
body.spez .mob_m .header__top-phone,
body.spez .mob_m .header__top-mail a,
body.spez .mob_m .header__top-links a,
body.spez .mob_m .menu li a,
body.spez .mob_m .menu li span {
  font-size: 14px;
}
body.spez .header__middle .header__top-lang a {
  background: #000;
}
body.spez .header__middle .header__top-lang a.current {
  background: #545454;
  color: #fff;
}
body.spez .header__top {
  background: #c5c5c5;
}
body.spez span.color-main {
  font-weight: 700;
  color: inherit !important;
}
body.spez .news_list a.news_link {
}
body.spez footer p span,
body.spez footer p span a {
  color: #fff !important;
}
body.spez .header__middle .menu {
  text-align: right;
}
body.spez .header__middle .menu li {
  float: none;
  display: inline-block;
}
body.spez .content .container {
  /* // padding-bottom: 395px; */
}
body.spez .header__middle .menu .sub {
  left: auto;
  right: 4px;
  text-align: left;
  width: 250px;
}
body.spez .header__middle .menu .sub:before {
  right: 13px;
  left: auto;
}
body.spez .header__top .header__top-phone,
body.spez .header__top .header__top-mail a,
body.spez .header__top .header__top-links a {
  font-size: 15px;
  font-family: 'open_sansregular', sans-serif;
  line-height: 0.9em;
  color: #000;
  vertical-align: middle;
}
body.spez .header__top .header__top-phone i,
body.spez .header__top .header__top-mail i {
  filter: invert(100%);
}
body.spez .header__top-spec {
  display: none;
}
body.spez .header__top .header__top-lang a {
  background: white;
  color: black;
  height: 28px;
  width: 28px;
  line-height: 28px;
}
body.spez .header__top .header__top-lang a.current {
  background: black;
  color: white;
}
body.spez .header__middle .menu li a.current,
body.spez .header__middle .menu li span.current {
  text-decoration: underline;
  color: black;
}
body.spez .header__middle .menu li a i,
body.spez .header__middle .menu li span i {
  filter: grayscale(100%);
}
body.spez .header__middle .menu li {
  margin-left: 25px;
}
body.spez .header__middle .menu li a,
body.spez .header__middle .menu li span {
  font-size: 14px;
}
body.spez .slider .slide_one .p2 {
  color: white;
}
body.spez .slider .slide_one .img {
  filter: grayscale(100%);
}
body.spez .slider .slide_one .time span {
  color: white;
}
body.spez .slider .slide_one .completion .btn {
  font-size: 15px;
  background: white;
  color: black;
  border: 2px solid black;
  padding: 6px 16px;
}
body.spez .left_menu a:first-child {
  font-size: 16px;
  color: black;
}
body.spez .left_menu a {
  font-size: 16px !important;
  color: black;
}
body.spez .left_menu a:before {
  background: black;
}
body.spez .head {
  font-family: 'open_sansregular', sans-serif;
}
body.spez .head:after {
  background: black;
}
body.spez .wysiwyg p {
  font-size: 16px !important;
}
body.spez .m_text .wysiwyg h2 {
}
body.spez .m_text .wysiwyg h2:after {
  background: black;
}
body.spez .wysiwyg h4:after {
  background: black;
}
body.spez .wysiwyg h5:after {
  background: black;
  font-size: 14px;
}
body.spez .wysiwyg ul li {
  font-size: 16px;
}
body.spez .wysiwyg ul li:before {
  background: black;
}
body.spez .player .title header h1 {
  font-size: 25px;
}
body.spez .player .title header h1 a {
  color: #000;
}
body.spez footer .footer__top {
  background: #000;
}
body.spez footer .footer__top p.hd {
  font-size: 18px;
}
body.spez footer .footer__top p a {
  font-size: 15px;
  color: white;
  font-family: 'open_sansregular', sans-serif;
}
body.spez .copyright,
body.spez .pr_link,
body.spez .siluet,
body.spez .siluet a {
  font-size: 14px;
}
body.spez .copyright span,
body.spez .pr_link:before,
body.spez .siluet i {
  filter: grayscale(100%) brightness(200%);
}
body.spez footer p {
  font-size: 13px;
  color: white;
}
body.spez footer p span {
  color: white;
  font-family: 'open_sansbold', sans-serif;
}
body.spez .header__middle .menu .sub {
  background: #d0d0d0;
}
body.spez .header__middle .menu .sub:before {
  border-color: transparent transparent #d0d0d0 transparent;
}
body.spez .header__middle .menu .sub a {
  color: #000;
}
body.spez .header__middle .menu .sub a:before {
  background: #000;
}
body.spez .breadcrumbs li,
body.spez .breadcrumbs a {
  font-size: 14px;
}
body.spez .slider .slide_one .current_time {
  color: white;
}
body.spez .slider .slide_one .completion img {
  filter: grayscale(100%);
}
body.spez .slider .slide_one .current_time.pm:after {
  filter: grayscale(100%);
}
body.spez .slick-dots li {
  width: 18px;
  height: 18px;
}
body.spez .slick-dots li.slick-active button {
  border-color: white;
}
body.spez .sg_toggle {
  font-size: 15px;
  background: black;
  color: white;
  width: 254px;
  margin-left: -127px;
  height: 40px;
  line-height: 40px;
  bottom: -24px;
}
body.spez .timetable .bg {
  background: #d6d6d6;
}
body.spez .timetable .bridge {
  margin-bottom: 25px;
}
body.spez .timetable .bridge .name {
  font-size: 16px;
  width: 220px;
  color: black;
  font-family: 'open_sansregular', sans-serif;
}
body.spez .timetable .time_list {
  margin-left: 218px;
}
body.spez .timetable .bridge .line_wrapper {
  margin-left: 222px;
}
body.spez .timetable .time_list span.t1,
body.spez .timetable .time_list span.t2 {
  font-size: 20px;
  color: black;
  font-family: 'open_sansbold', sans-serif;
  width: auto;
}
body.spez .timetable .time_list span.t1:after,
body.spez .timetable .time_list span.t2:after {
  background: black;
  width: 3px;
  height: 10px;
}
body.spez .timetable {
  height: auto;
}
body.spez .timetable .bridge .line_wrapper .line_cur_sec span,
body.spez .timetable .bridge .line_wrapper .line_cur span {
  color: black;
}
body.spez .timetable .bridge .line_wrapper .line.l_left:before,
body.spez .timetable .bridge .line_wrapper .line.l_right:before,
body.spez .timetable .bridge .line_wrapper .line_cur_sec_wrapper:before {
  background: rgba(0, 0, 0, 0.2);
}
body.spez .timetable .bridge .line_wrapper .line_cur_sec .line.l_left:before,
body.spez .timetable .bridge .line_wrapper .line_cur_sec .line.l_right:before,
body.spez .timetable .bridge .line_wrapper .line_cur:before {
  background: #000000;
}
body.spez .timetable .wysiwyg * {
  color: black;
}
body.spez m_news {
  background: #393939;
}
body.spez .m_news .m_news_one {
  color: #fff;
}
body.spez .m_news .col-sm-4:nth-of-type(2n + 1) .head {
  color: #fff;
}
body.spez .m_news .head {
  font-size: 20px;
  font-family: 'open_sansbold', sans-serif;
}
body.spez .m_news .head::after {
  background: white;
}
body.spez .m_news p {
  font-size: 16px;
}
body.spez .button {
  font-size: 14px;
  font-family: 'open_sansbold', sans-serif;
  padding: 4px 15px;
  line-height: 24px;
}
body.spez .bg_blue {
  background: black;
}
body.spez .m_news .button {
  background: white;
  color: black;
}
body.spez .m_news .m_news_one {
  padding-bottom: 65px;
}
body.spez .info_blocks .widget a,
body.spez .info_blocks .widget span.link {
  background: black !important;
  color: white;
}
body.spez .info_blocks .widget img {
  filter: grayscale(100%);
}
body.spez .info_blocks .widget .s5 img {
  filter: grayscale(100%) brightness(650%);
}
body.spez .info_blocks .widget span.s3,
body.spez .info_blocks .widget span.s4,
body.spez .info_blocks .widget span.details {
  color: white;
}
body.spez .info_blocks .widget .rhombus.blue {
  color: white;
  border-color: white;
}
body.spez .info_blocks .widget .rhombus i,
body.spez .info_blocks .widget span.s1,
body.spez .info_blocks .widget span.details {
  color: white !important;
}
body.spez .bridge_view .img img,
body.spez .main_map,
body.spez .project_list .img a {
  filter: grayscale(100%);
}
body.spez .project_list .img a .title:after {
  background: white;
}
body.spez .project_list .img a .title,
body.spez .project_list .img a .details {
  font-size: 16px;
}
body.spez .wysiwyg a {
  color: black;
}
body.spez .wysiwyg .bg_blue {
  color: white;
}
body.spez .brige-100_years .m_text h3 {
  color: black;
}
body.spez .wysiwyg h3:after {
  background: #000;
}
body.spez .brige-100_years .pb_banner h1 {
  background: #929292;
}
body.spez .brige-100_years .pb_banner h1:before,
body.spez .brige-100_years .pb_banner h1:after {
  filter: grayscale(100%);
}
body.spez .brige-100_years .pb_banner {
  filter: grayscale(100%);
}
body.spez .brige-100_years .pb_banner .btn {
  border: 2px solid white;
}
body.spez .prs_center .contact_txt .user_img {
  border-color: #000;
}
body.spez .prs_center .contact_txt .user_img::before {
  border-color: transparent #000;
}
body.spez .news_list a.news_link {
  background: white;
  border: 2px solid #000;
  color: white;
}
body.spez .news_list a.news_link span.date,
body.spez .news_list a.news_link span.name,
body.spez .news_list a.news_link span.txt {
  font-size: 14px;
  color: black;
}
body.spez .news_list a.news_link span.name {
  font-size: 20px;
}
body.spez .news_list a.news_link span.txt {
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 73px;
}
body.spez .news_list a.news_link span.details,
body.spez .prs_center .p_blocks .bl a.show_all,
body.spez .prs_center .p_blocks .bl.center a.show_all {
  background: white;
  border-radius: 100px;
  padding: 4px 12px;
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-family: 'open_sansbold', sans-serif;
  display: inline-block;
  margin-left: 14%;
  width: auto;
  color: black !important;
  border: 2px solid black;
}
body.spez .bridge_list .search_b .select .options span:hover {
  background: black;
  color: white;
}
body.spez .bridge_list .buttons_geay a.current i {
  filter: grayscale(100%) brightness(20%);
}
body.spez .prs_center .p_blocks .bl.center a.show_all {
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
}
body.spez .prs_center .p_blocks .bl a.show_all {
  bottom: 20px;
}
body.spez .news_list a.news_link:hover span.details {
  background: black !important;
  color: white !important;
}
body.spez .pagination_index .pag,
body.spez .pagination_index .pag_select {
  border-color: #000;
}
body.spez .pagination_index .pag_select {
  width: 180px;
}
body.spez .pagination_index .pag_select .select_wrap a:hover {
  background: #000;
}
body.spez .prs_center .p_blocks .bl p.hd.line_blue:after {
  background: white;
}
body.spez .prs_center .p_blocks .bl p.date,
body.spez .prs_center .p_blocks .bl .txt a.details,
body.spez .prs_center .p_blocks .bl p.hd,
body.spez .prs_center .p_blocks .bl .txt {
  color: black !important;
}
body.spez .prs_center .p_blocks .bl {
  background: white;
  border: 2px solid black;
}
body.spez .fancybox-image,
body.spez #fancybox-thumbs ul li img,
body.spez .brige-100_years .m_text img {
  filter: grayscale(100%);
}
body.spez .prs_center .p_blocks .bl.bg_dark .txt p,
body.spez .prs_center .p_blocks .bl.bg_blue .txt p {
  color: black !important;
}
body.spez .prs_center .p_blocks .bl p.hd {
  font-size: 20px;
  padding-bottom: 20px;
  line-height: 1.2em;
}
body.spez .left_menu .subscibe label {
  width: 200px;
}
body.spez .left_menu .subscibe i {
  font-size: 16px;
  color: #000;
}
body.spez .left_menu .subscibe span {
  font-size: 12px;
  top: 7px;
}
body.spez .left_menu .subscibe button {
  background: #000;
}
body.spez .prs_center .p_blocks .bl .txt p {
  font-size: 15px;
}
body.spez .prs_center .p_blocks .bl .rhombus.white {
  border-color: #000;
  color: #000;
}
body.spez .prs_center .p_blocks .bl .txt a.details {
  font-size: 14px;
}
body.spez .team img {
  filter: grayscale(100%);
}
body.spez .team p.name {
  color: black;
  font-size: 20px;
}
body.spez .team p.status {
  font-size: 16px;
}
body.spez .team p {
  font-size: 14px;
}
body.spez.regiment {
  background: white;
}
body.spez.regiment .breadcrumbs {
  background: #f8f8f8;
}
body.spez.regiment .breadcrumbs span {
  color: black;
}
body.spez.regiment i.star,
body.spez i.stars {
  display: none;
}
body.spez .project_photo_list .img,
body.spez .bridge_wrap a img {
  filter: grayscale(100%);
}
body.spez .categories a {
  background: white;
  color: black;
  border: 2px solid #000;
}
body.spez .categories a.bg_dark {
  background: black;
  color: white;
}
body.spez .bridge_list .search_b .select {
  border: 2px solid #000;
  width: auto;
  min-height: 40px;
  box-sizing: border-box;
}
body.spez .bridge_list .search_b .select span {
  font-size: 16px;
  line-height: 38px;
}
body.spez .bridge_list .search_b .select .options span {
  min-height: auto;
  line-height: 1.2em;
}
body.spez .bridge_list .search_b .select#search-form {
  width: 340px;
  padding: 3px 3px 0;
}
/* 
body.spez .bridge_list .search_b form.select{
  left: 280px;
  } */
body.spez .bridge_list .search_b input[type='text'] {
  font-size: 16px;
  width: 90%;
}
body.spez .bridge_list .search_b i {
  display: none;
}
body.spez .bridge_list .search_b form i {
  display: block;
  line-height: 27px;
}
body.spez .bridge_list .search_b i {
  width: 30px;
  height: 30px;
}
body.spez .bridge_list .search_b .select button {
  width: 40px;
  height: 40px;
}
body.spez .blog_item p.date {
  font-size: 15px;
}
body.spez .blog_item p.cont {
  font-size: 18px;
  color: black;
  line-height: 1.2em;
  height: 45px;
}
body.spez .contacts .slick-slide img,
body.spez .contacts .cont_info_blocks .img img,
body.spez .gallery_list .gallery_link_sec span.img img {
  filter: grayscale(100%);
}
body.spez .left_menu a.current {
  font-family: 'open_sansbold', sans-serif;
}
body.spez .doc_list a.doc_image span.img {
  filter: grayscale(100%);
}
body.spez .chief .banner {
  filter: grayscale(100%);
}
body.spez footer {
  height: auto;
  padding-bottom: 20px;
}
body.spez .facts .p-open {
  padding-top: 40px;
}
body.spez .prs_center .p_blocks .bl p.hd {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
body.spez .bridge_view .params p {
  color: #fff;
  font-size: 14px;
}
body.spez .bridge_list .search_b .select .options a,
body.spez .bridge_list .search_b .select .sub a {
  font-size: 16px;
  padding: 7px 30px 7px 14px;
}
body.spez .button {
  background: #000;
}
body.spez .job_list .bg_gray {
  background: white;
  border: 2px solid #000;
}
body.spez .light.big_form .input,
body.spez .light.big_form .textarea textarea {
  border: 1px solid #000;
  border-radius: 3px;
  padding: 0;
}
body.spez.color-black
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  background: #000;
  color: #fff;
  margin-top: 1px;
}
body.spez.color-black .light.big_form a.button {
  color: #fff;
}
body.spez .light.big_form p.hd {
  font-size: 18px;
}
body.spez .light.big_form .input input {
  font-size: 15px;
}
body.spez .form.light p.hh {
  color: #000;
  font-size: 18px;
}
body.spez .select2-container--default .select2-selection--single {
  height: 40px;
}
body.spez
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  filter: grayscale(100%);
}
body.spez .form.light .file .input {
  border: none;
}
body.spez .light.big_form .ez-checkbox .ez-checkbox {
  border: 1px solid #000;
  border-radius: 3px;
}
body.spez .light.big_form .button.b-file,
body.spez .form button {
  font-size: 15px;
}
body.spez .light.big_form a.button,
body.spez .form button,
body.spez .contest--title:after {
  background: #000;
}
body.spez .job_list .job_list--title span,
body.spez .form.light .file i {
  color: #000;
}
body.spez span.color-main {
  color: #000 !important;
}
body.spez .staff .img img {
  filter: grayscale(100%);
}
body.spez .staff .title,
body.spez .pagination_index .pag_select span i {
  color: #000;
}
body.spez .job_list .job_list--title h1 {
  font-size: 28px;
}
body.spez .wysiwyg h6,
body.spez .wysiwyg h5 {
  font-size: 14px;
}
body.spez .regiment .person-title + p {
}
body.spez .brige-100_years .f_head span,
body.spez .facts h1 span,
body.spez .facts h1,
body.spez .contest--head span,
body.spez .winner .contest--title {
  color: #000;
}
body.spez .wysiwyg img,
body.spez .bloggers img,
body.spez .contest .winner img,
body.spez .modal-content .img img,
body.spez .contest--gallery .img,
body.spez.regiment .img-wrap:before,
body.spez.regiment .img-wrap:after,
body.spez.regiment .img-wrap img,
body.spez.regiment .to-video .caption:before,
body.spez.regiment .to-video .caption:after,
body.spez.regiment .to-video .caption {
  filter: grayscale(100%);
}
body.spez .contest .likes i,
body.spez .contest--gallery .txt .name a,
body.spez .blue {
  color: #fff !important;
}
body.spez .wysiwyg a.round {
  background: #000;
  color: #fff;
}
body.spez.regiment .wysiwyg.centr:after,
body.spez.regiment footer {
  background: #000;
}
body.spez.regiment .red {
  color: #000;
}
body.spez .slider-polk .slick-arrow,
body.spez.regiment .persons .slick-arrow,
body.spez .slider-polk .slick-prev,
body.spez.regiment .persons .slick-prev {
  background-color: #dcdcdc;
}
body.spez img {
  filter: grayscale(100%);
}
body.spez.bloggers .discr-wrap p {
  font-size: 16px;
  color: #000 !important;
}
body.spez.bloggers .bloggers_list .img_wrap:after {
  color: #000 !important;
}
body.spez.bloggers .bloggers_view .blog_item .name,
body.spez.bloggers .bloggers_view .blog_item .txt a {
  color: #000;
}
body.spez.bloggers .bloggers_view .txt p {
  font-size: 14px;
}
body.spez .pagination a.round {
  border-color: #000;
}
body.spez .pagination_index .pag {
  font-size: 14px;
}
body.spez .doc_slide p.date span {
  font-size: 14px;
  color: #000;
}
body.spez .doc_slide p.date i:before,
body.spez .doc_slide p.date i:after {
  background: #000;
}
body.spez .prs_center .cnt_one {
  border: 1px solid #000;
  background: #fff;
}
body.spez .prs_center .cnt_one p.hd {
  font-size: 15px;
}
body.spez .prs_center .cnt_one p {
  font-size: 17px;
}
body.spez .plate-links {
  font-size: 14px;

  .plate-links__item {
    &:before {
      background-color: #000000;
    }

    &-link {
      &_doc {
        &:after {
          filter: grayscale(100%) brightness(0);
        }
      }
    }
  }
}
/* ===================== scale-2 ===================== */

body.spez.scale-2.bloggers .discr-wrap p {
  font-size: 18px;
}
body.spez.scale-2.spez__back {
  font-size: 16px;
}
body.spez.scale-2 .mob_m .header__top-phone,
body.spez.scale-2 .mob_m .header__top-mail a,
body.spez.scale-2 .mob_m .header__top-links a,
body.spez.scale-2 .mob_m .menu li a,
body.spez.scale-2 .mob_m .menu li span {
  font-size: 16px;
}
body.spez.scale-2 .header__top .header__top-phone,
body.spez.scale-2 .header__top .header__top-mail a,
body.spez.scale-2 .header__top .header__top-links a {
  font-size: 17px;
}
body.spez.scale-2 .header__middle .menu li a,
body.spez.scale-2 .header__middle .menu li span {
  font-size: 16px;
}
body.spez.scale-2 .slider .slide_one .completion .btn {
  font-size: 17px;
}
body.spez.scale-2 .left_menu a:first-child {
  font-size: 18px;
}
body.spez.scale-2 .left_menu a {
  font-size: 18px !important;
}
body.spez.scale-2 .wysiwyg p {
  font-size: 18px !important;
}
body.spez.scale-2 .wysiwyg h5:after {
  font-size: 16px;
}
body.spez.scale-2 .wysiwyg ul li {
  font-size: 18px;
}
body.spez.scale-2 .player .title header h1 {
  font-size: 25px;
}
body.spez.scale-2 footer .footer__top p.hd {
  font-size: 21px;
}
body.spez.scale-2 footer .footer__top p a {
  font-size: 17px;
}
body.spez.scale-2 .copyright,
body.spez.scale-2 .pr_link,
body.spez.scale-2 .siluet,
body.spez.scale-3 .siluet a {
  font-size: 16px;
}
body.spez.scale-2 footer p {
  font-size: 15px;
  line-height: 1.4em;
}
body.spez.scale-2 .breadcrumbs li,
body.spez.scale-2 .breadcrumbs a {
  font-size: 16px;
}
body.spez.scale-2 .sg_toggle {
  font-size: 17px;
}
body.spez.scale-2 .timetable .bridge .name {
  font-size: 18px;
}
body.spez.scale-2 .timetable .time_list span.t1,
body.spez.scale-2 .timetable .time_list span.t2 {
  font-size: 24px;
}
body.spez.scale-2 .m_news .head {
  font-size: 24px;
}
body.spez.scale-2 .m_news p {
  font-size: 18px;
}
body.spez.scale-2 .button {
  font-size: 16px;
}
body.spez.scale-2 .project_list .img a .title,
body.spez.scale-2 .project_list .img a .details {
  font-size: 18px;
}
body.spez.scale-2 .news_list a.news_link span.date,
body.spez.scale-2 .news_list a.news_link span.name,
body.spez.scale-2 .news_list a.news_link span.txt {
  font-size: 16px;
}
body.spez.scale-2 .news_list a.news_link span.name {
  font-size: 24px;
}
body.spez.scale-2 .news_list a.news_link span.details,
body.spez.scale-2 .prs_center .p_blocks .bl a.show_all,
body.spez.scale-2 .prs_center .p_blocks .bl.center a.show_all {
  font-size: 16px;
}
body.spez.scale-2 .prs_center .p_blocks .bl p.hd {
  font-size: 24px;
}
body.spez.scale-2 .prs_center .p_blocks .bl .txt p {
  font-size: 17px;
}
body.spez.scale-2 .prs_center .p_blocks .bl .txt a.details {
  font-size: 16px;
}
body.spez.scale-2 .team p.name {
  font-size: 24px;
}
body.spez.scale-2 .team p.status {
  font-size: 18px;
}
body.spez.scale-2 .team p {
  font-size: 16px;
}
body.spez.scale-2 .bridge_list .search_b .select span {
  font-size: 18px;
}
body.spez.scale-2 .bridge_list .search_b input[type='text'] {
  font-size: 18px;
}
body.spez.scale-2 .blog_item p.date {
  font-size: 17px;
}
body.spez.scale-2 .blog_item p.cont {
  font-size: 21px;
}
body.spez.scale-2 .bridge_view .params p {
  color: #fff;
  font-size: 16px;
}

body.spez.scale-2 .bridge_list .search_b .select .options a,
body.spez.scale-2 .bridge_list .search_b .select .sub a {
  font-size: 18px;
}
body.spez.scale-2 .left_menu .subscibe p {
  font-size: 14px;
}
body.spez.scale-2 .left_menu .subscibe label {
  width: 220px;
}
body.spez.scale-2 .left_menu .subscibe i {
  font-size: 17px;
  color: #000;
}
body.spez.scale-2 .left_menu .subscibe span {
  font-size: 14px;
  top: 6px;
}
body.spez.scale-2 .news_list a.news_link {
  padding-top: 120%;
}
body.spez.scale-2 .news_list a.news_link span.name {
  line-height: 1.2em;
  height: 58px;
}
body.spez.scale-2 .news_list a.news_link span.txt {
  height: 83px;
}
body.spez.scale-2 .content .container {
  padding-bottom: 460px;
}
body.spez.scale-2 .light.big_form p.hd,
body.spez.scale-2 .form.light p.hh {
  font-size: 19px;
}
body.spez.scale-2 .light.big_form .input input,
body.spez.scale-2 .form.light .input p,
body.spez.scale-2 .form .textarea p,
body.spez.scale-2 .light.big_form .checkbox span,
body.spez.scale-2
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 16px;
}
body.spez.scale-2 .select2-container--default .select2-selection--single {
  height: 42px;
}
body.spez.scale-2 .light.big_form a.button {
  height: 35px;
  line-height: 29px;
}
body.spez.scale-2 .light.big_form a.button:after {
  top: 13px;
}
body.spez.scale-2 .light.big_form a.button:before {
  top: 17px;
}
body.spez.scale-2 .light.big_form .button.b-file,
body.spez.scale-2 .form button {
  font-size: 17px;
}
body.spez.scale-2 .head {
  font-size: 60px;
}
body.spez.scale-2 .staff .title {
  font-size: 18px;
}
body.spez.scale-2 .staff .title span {
  font-size: 14px;
}
body.spez.scale-2 .job_list .job_list--title h1 {
  font-size: 30px;
}
body.spez.scale-2 .wysiwyg h6,
body.spez.scale-2 .wysiwyg h5 {
  font-size: 15px;
}

body.spez.scale-2 .pagination_index .pag {
  font-size: 16px;
}
body.spez.scale-2 .news_one .brief .name {
  font-size: 20px;
}
body.spez.scale-2 .news_one .brief p.date {
  font-size: 14px;
}
body.spez.scale-2 .doc_slide p.date span {
  font-size: 16px;
}

body.spez.scale-2 .prs_center .cnt_one p.hd {
  font-size: 17px;
}
body.spez.scale-2 .prs_center .cnt_one p {
  font-size: 19px;
}
body.spez.scale-2 .plate-links {
  font-size: 15px;
}
/* ===================== scale-3 ===================== */

body.spez.scale-3 .spez__back {
  font-size: 18px;
}
body.spez.scale-3 .mob_m .header__top-phone,
body.spez.scale-3 .mob_m .header__top-mail a,
body.spez.scale-3 .mob_m .header__top-links a,
body.spez.scale-3 .mob_m .menu li a,
body.spez.scale-3 .mob_m .menu li span {
  font-size: 18px;
}
body.spez.scale-3 .header__top .header__top-phone,
body.spez.scale-3 .header__top .header__top-mail a,
body.spez.scale-3 .header__top .header__top-links a {
  font-size: 19px;
}
body.spez.scale-3 .header__middle .menu li a,
body.spez.scale-3 .header__middle .menu li span {
  font-size: 18px;
}
body.spez.scale-3 .slider .slide_one .completion .btn {
  font-size: 19px;
}
body.spez.scale-3 .left_menu a:first-child {
  font-size: 21px;
}
body.spez.scale-3 .left_menu a {
  font-size: 21px !important;
}
body.spez.scale-3 .wysiwyg p {
  font-size: 21px !important;
}
body.spez.scale-3 .wysiwyg h5:after {
  font-size: 18px;
}
body.spez.scale-3 .wysiwyg ul li {
  font-size: 21px;
}
body.spez.scale-3 .player .title header h1 {
  font-size: 25px;
}
body.spez.scale-3 footer .footer__top p.hd {
  font-size: 24px;
}
body.spez.scale-3 footer .footer__top p a {
  font-size: 19px;
}
body.spez.scale-3 .copyright,
body.spez.scale-3 .pr_link,
body.spez.scale-3 .siluet,
body.spez.scale-3 .siluet a {
  font-size: 18px;
}
body.spez.scale-3 footer p {
  font-size: 17px;
  line-height: 1.5em;
}
body.spez.scale-3 .breadcrumbs li,
body.spez.scale-3 .breadcrumbs a {
  font-size: 18px;
}
body.spez.scale-3 .sg_toggle {
  font-size: 19px;
}
body.spez.scale-3 .timetable .bridge .name {
  font-size: 21px;
}
body.spez.scale-3 .timetable .time_list span.t1,
body.spez.scale-3 .timetable .time_list span.t2 {
  font-size: 27px;
}
body.spez.scale-3 .m_news .head {
  font-size: 27px;
}
body.spez.scale-3 .m_news p {
  font-size: 21px;
}
body.spez.scale-3 .button {
  font-size: 18px;
}
body.spez.scale-3 .project_list .img a .title,
body.spez.scale-3 .project_list .img a .details {
  font-size: 21px;
}
body.spez.scale-3 .news_list a.news_link span.date,
body.spez.scale-3 .news_list a.news_link span.name,
body.spez.scale-3 .news_list a.news_link span.txt {
  font-size: 18px;
}
body.spez.scale-3 .news_list a.news_link span.name {
  font-size: 27px;
}
body.spez.scale-3 .news_list a.news_link span.details,
body.spez.scale-3 .prs_center .p_blocks .bl a.show_all,
body.spez.scale-3 .prs_center .p_blocks .bl.center a.show_all {
  font-size: 18px;
}
body.spez.scale-3 .prs_center .p_blocks .bl p.hd {
  font-size: 27px;
}
body.spez.scale-3 .prs_center .p_blocks .bl .txt p {
  font-size: 19px;
}
body.spez.scale-3 .prs_center .p_blocks .bl .txt a.details {
  font-size: 18px;
}
body.spez.scale-3 .team p.name {
  font-size: 27px;
}
body.spez.scale-3 .team p.status {
  font-size: 21px;
}
body.spez.scale-3 .team p {
  font-size: 18px;
}
body.spez.scale-3 .bridge_list .search_b .select span {
  font-size: 21px;
}
body.spez.scale-3 .bridge_list .search_b input[type='text'] {
  font-size: 21px;
}
body.spez.scale-3 .blog_item p.date {
  font-size: 19px;
}
body.spez.scale-3 .blog_item p.cont {
  font-size: 24px;
}
body.spez.scale-3 .sg_toggle {
  width: 300px;
  margin-left: -150px;
}
body.spez.scale-3 .bridge_view .params p {
  font-size: 18px;
}
body.spez.scale-3 .bridge_list .search_b .select .options a,
body.spez.scale-3 .bridge_list .search_b .select .sub a {
  font-size: 21px;
}
body.spez.scale-3 .left_menu .subscibe p {
  font-size: 16px;
}
body.spez.scale-3 .left_menu .subscibe label {
  width: 230px;
}
body.spez.scale-3 .left_menu .subscibe i {
  font-size: 19px;
  color: #000;
}
body.spez.scale-3 .left_menu .subscibe span {
  font-size: 15px;
  top: 6px;
}
body.spez.scale-3 .news_list a.news_link {
  padding-top: 140%;
}
body.spez.scale-3 .news_list a.news_link span.name {
  line-height: 1.2em;
  height: 66px;
}
body.spez.scale-3 .news_list a.news_link span.txt {
  height: 87px;
}
/* body.spez.scale-3 .content .container{
    // padding-bottom: 540px;
  } */
body.spez.scale-3 .light.big_form p.hd,
body.spez.scale-3 .form.light p.hh {
  font-size: 21px;
}
body.spez.scale-3 .light.big_form .input input,
body.spez.scale-3 .form.light .input p,
body.spez.scale-3 .form .textarea p,
body.spez.scale-3 .light.big_form .checkbox span,
body.spez.scale-3
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 17px;
}
body.spez.scale-3 .light.big_form a.button {
  height: 35px;
  line-height: 29px;
}
body.spez.scale-3 .select2-container--default .select2-selection--single {
  height: 43px;
}
body.spez.scale-3
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 43px;
}
body.spez.color-black .select2-container--default .select2-selection--single {
}
body.spez.color-black .light.big_form .input,
body.spez.color-black .light.big_form .textarea textarea {
  border: 1px solid #000;
}
body.spez.scale-3 .light.big_form a.button:after {
  top: 13px;
}
body.spez.scale-3 .light.big_form a.button:before {
  top: 17px;
}
body.spez.scale-3 .light.big_form .button.b-file,
body.spez.scale-3 .form button {
  font-size: 19px;
}
body.spez.scale-3 .staff .title {
  font-size: 21px;
}
body.spez.scale-3 .staff .title span {
  font-size: 17px;
}
body.spez.scale-3 .job_list .job_list--title h1 {
  font-size: 34px;
}
body.spez.scale-3 .head {
  font-size: 65px;
}
body.spez.scale-3 .job_list .job_list--title h1 {
  font-size: 34px;
}
body.spez.scale-3 .wysiwyg h6,
body.spez.scale-3 .wysiwyg h5 {
  font-size: 16px;
}
body.spez.scale-3 .pagination_index .pag {
  font-size: 18px;
}
body.spez.scale-3 .news_one .brief .name {
  font-size: 24px;
}
body.spez.scale-3 .news_one .brief p.date {
  font-size: 16px;
}
body.spez.scale-3 .doc_slide p.date span {
  font-size: 18px;
}
body.spez.scale-3 .prs_center .cnt_one p.hd {
  font-size: 19px;
}
body.spez.scale-3 .prs_center .cnt_one p {
  font-size: 21px;
}
body.spez.scale-3 .plate-links {
  font-size: 16px;
}
/* ===================== color-black ===================== */
body.spez.color-black,
body.spez.color-black .contest--title:after {
  background: black;
}
body.spez.color-black .brige-100_years .f_head span,
body.spez.color-black .facts h1 span,
body.spez.color-black .facts h1,
body.spez.color-black .contest--head span,
body.spez.color-black .winner .contest--title {
  color: #fff;
}
body.spez.color-black .spez__back {
  color: #fff;
}
body.spez.color-black .header__top .header__top-phone,
body.spez.color-black .header__top .header__top-mail a,
body.spez.color-black .header__top .header__top-links a {
  color: white;
}
body.spez.color-black .header__middle .menu li a,
body.spez.color-black .header__middle .menu li span {
  color: white;
}
body.spez.color-black footer p span,
body.spez.color-black footer p span a {
  color: white !important;
}
body.spez.color-black .header__middle .menu .sub {
  background: #ececec;
}
body.spez.color-black .header__middle .menu li .sub a {
  color: black;
}
body.spez.color-black .left_menu a:first-child {
  color: white;
}
body.spez.color-black .left_menu a {
  color: white;
}
/* body.spez.color-black .wysiwyg p{
    //color: white;
  } */
body.spez.color-black .wysiwyg h5:after {
  color: white;
}
body.spez.color-black .wysiwyg ul li {
  color: white;
}
body.spez.color-black .player .title header h1 {
  color: white;
}
body.spez.color-black footer .footer__top p.hd {
  color: white;
}
body.spez.color-black footer .footer__top p a {
  color: white;
}
body.spez.color-black .copyright,
body.spez.color-black .pr_link,
body.spez.color-black .siluet {
  color: white;
}
body.spez.color-black footer p {
  color: white;
}
body.spez.color-black .breadcrumbs li,
body.spez.color-black .breadcrumbs a {
  color: white;
}
body.spez.color-black .sg_toggle {
  color: white;
}
/* body.spez.color-black .timetable .bridge .name{
    //color: white;
  } */
/* body.spez.color-black .timetable .time_list span.t1,
  body.spez.color-black .timetable .time_list span.t2{
    //color: white;
  } */
body.spez.color-black .m_news .head {
  color: white;
}
body.spez.color-black .m_news p {
  color: white;
}
body.spez.color-black .project_list .img a .title,
body.spez.color-black .project_list .img a .details {
  color: white;
}
body.spez.color-black .news_list a.news_link span.date,
body.spez.color-black .news_list a.news_link span.name,
body.spez.color-black .news_list a.news_link span.txt {
  color: black;
}
body.spez.color-black .news_list a.news_link span.name {
  color: black;
}
body.spez.color-black .left_menu .subscibe span {
  color: white;
}
body.spez.color-black .news_list a.news_link span.details,
body.spez.color-black .prs_center .p_blocks .bl a.show_all,
body.spez.color-black .prs_center .p_blocks .bl.center a.show_all {
  color: white;
}
body.spez.color-black .prs_center .p_blocks .bl p.hd {
  color: white;
}
body.spez.color-black .prs_center .p_blocks .bl .txt p {
  color: white;
}
body.spez.color-black .prs_center .p_blocks .bl .txt a.details {
  color: white;
}
body.spez.color-black .team p.name {
  color: white;
}
body.spez.color-black .team p.status {
  color: white;
}
body.spez.color-black .team p {
  color: white;
}
body.spez.color-black .bridge_list .search_b .select span {
  color: white;
}
body.spez.color-black .bridge_list .search_b input[type='text'] {
  color: white;
}
body.spez.color-black .blog_item p.date {
  color: white;
}
body.spez.color-black .blog_item p.cont {
  color: white;
}
body.spez.color-black .breadcrumbs {
  background: #222a32; /* 
  border-top: 1px solid white;
  border-bottom: 1px solid white; */
}
body.spez.color-black .left_menu .subscibe input[type='text'] {
  background: #000;
  color: #fff;
}
body.spez.color-black .breadcrumbs:before {
  display: none;
}
body.spez.color-black {
  color: white;
}
body.spez.color-black .header__middle .logo {
  filter: invert(100%);
}
body.spez.color-black .spez__scale_text,
body.spez.color-black .spez__scales,
body.spez.color-black .spez__color_text {
  filter: invert(100%);
  color: black;
}

body.spez.color-black .spez__color[data-color='black'] {
  border-color: #fff;
}
body.spez.color-black .spez__color.m--current:after {
  background: #fff;
}
body.spez.color-black .header__spez_panel {
  background: #222a32; /* 
  border-top: 1px solid white;
  border-bottom: 1px solid white; */
}
body.spez.color-black .header__top .header__top-phone i,
body.spez.color-black .header__top .header__top-mail i {
  filter: none;
}
body.spez.color-black .header__top {
  background: black;
}
body.spez.color-black .wysiwyg a {
  color: #fff;
}
body.spez.color-black .bridge_list .search_b .select span {
  color: #000;
}
body.spez.color-black .categories a {
  border-color: #000;
}
body.spez.color-black .categories a.bg_dark {
  border-color: #fff;
}
body.spez.color-black .pagination_index .pag_select {
  border-color: #fff;
  color: #fff;
}
body.spez.color-black .job_list .job_list--title span,
body.spez.color-black.job_list .job_list--title span {
  color: #fff;
}
body.spez.color-black .head:after {
  background: #fff;
}
body.spez.color-black span.color-main {
  color: #fff !important;
}
body.spez.color-black .head:after,
body.spez.color-black .wysiwyg ul li:before {
  background: white;
}

body.spez.color-black .staff .title,
body.spez.color-black .pagination_index .pag_select span i,
body.spez.color-black .staff .title span {
  color: #fff;
}

body.spez.color-black .pagination_index .pag_select span:after {
  filter: brightness(1000%);
}
body.spez.color-black .pagination_index .pag_select .select_wrap a {
  color: #fff;
}
body.spez.color-black .left_menu a:before {
  background: #fff;
}
body.spez.color-black.bloggers .img img {
  filter: grayscale(100%) brightness(1000%);
}
body.spez.color-black .wysiwyg a.round {
  background: #000;
  color: #fff;
}
body.spez.color-black.bloggers .bloggers_list .img_wrap:after {
  color: #fff !important;
}
body.spez.color-black.bloggers .bloggers_list .img_wrap:after {
  background: #000;
}
body.spez.color-black.bloggers .discr-wrap p {
  font-size: 16px;
  color: #fff !important;
}
body.spez.color-black.bloggers .bloggers_view .blog_item .name,
body.spez.color-black.bloggers .bloggers_view .blog_item .txt a {
  color: #fff;
}
body.spez.color-black .button {
  background: #fff;
  color: #000;
}
body.spez.color-black .pagination a.round {
  border-color: #fff;
  color: #fff;
}
body.spez.color-black.bloggers .bridge-about .img img {
  filter: grayscale(100%);
}
body.spez.color-black .news_list a.news_link {
  border-color: #fff;
  background: #000;
}
body.spez.color-black .news_list a.news_link span.date,
body.spez.color-black .news_list a.news_link span.name,
body.spez.color-black .news_list a.news_link span.txt {
  color: #fff;
}
body.spez.color-black .left_menu .subscibe i,
body.spez.color-black .left_menu .subscibe i {
  color: #fff;
}
body.spez.color-black .left_menu .subscibe button {
  background: #fff;
  color: #000;
}
body.spez .left_menu a.rss:before {
  background: url(../img/rss.png);
}
body.spez.color-black .left_menu a.rss:before {
  filter: invert(100%);
}
body.spez.color-black .pagination_index .pag {
  border-color: #fff;
}
body.spez .pagination_index .pag b {
  color: #000;
}
body.spez.color-black .pagination_index .pag b {
  color: #fff;
}
body.spez.color-black .icon.arr_r_double,
body.spez.color-black .icon.arr_r {
  filter: brightness(1000%);
}
body.spez .news_one .brief .name {
  color: #000;
}
body.spez.color-black .news_one .brief .name {
  color: #fff;
}
body.spez .ya-share2__container_size_m .ya-share2__item {
  filter: grayscale(100%);
}

body.spez.color-black .doc_slide p.date {
  color: #fff;
}
body.spez.color-black .doc_slide p.date i:before,
body.spez.color-black .doc_slide p.date i:after {
  background: #fff;
}
body.spez.color-black .prs_center .cnt_one {
  border-color: #fff;
  background: black;
}
body.spez.color-black .prs_center .cnt_one p.hd,
body.spez.color-black .prs_center .cnt_one p,
body.spez.color-black .prs_center .cnt_one p a,
body.spez.color-black .prs_center .cnt_one .rhombus i {
  color: #fff;
}
body.spez.color-black .prs_center .cnt_one .rhombus.dark {
  border-color: #fff;
}
body.spez .prs_center .form .input i,
body.spez .prs_center .form .textarea i {
  display: none;
}
body.spez .form .input p,
body.spez .form .textarea p {
  font-size: 15px;
  padding-left: 20px;
}
body.spez .form .input p span span,
body.spez .form p.mandatory span {
  color: #000;
}
body.spez.color-black .prs_center .formR {
  background: #000;
  border: 2px solid #fff;
}
body.spez.color-black .form .input p,
body.spez.color-black .form .textarea p,
body.spez.color-black .prs_center .form p.hd,
body.spez.color-black .form .input p span span,
body.spez.color-black .form p.mandatory span,
body.spez.color-black .form p.mandatory,
body.spez.color-black .bridge_wrap a span.txt {
  color: #fff;
}
body.spez.color-black .prs_center .form .input input,
body.spez.color-black .prs_center .form .textarea textarea,
body.spez.color-black .form .input input,
body.spez.color-black .form .textarea textarea {
  background: #000;
  border: 1px solid #fff;
  color: #fff;
}
body.spez.color-black .plate-links {
  background-color: #000;

  .plate-links__item {
    background-color: #000000;

    &:before {
      background-color: #ffffff;
    }

    &-link {
      color: #fff;

      &_doc {
        &:after {
          filter: grayscale(1) brightness(10);
        }
      }
    }
  }
}

body.spez .bridge_list .search_b .select .options a:hover,
body.spez .bridge_list .search_b .select .sub a:hover {
  background: #000;
}
body.spez .bridge_wrap a span.txt {
  font-size: 16px;
}
body.spez.scale-2 .bridge_wrap a span.txt {
  font-size: 18px;
}
body.spez.scale-3 .bridge_wrap a span.txt {
  font-size: 20px;
}
body.spez .bridge_view .raising-flag,
body.spez .slick-slide img,
body.spez .bridge_view .boggers-flag {
  filter: grayscale(100%);
}
body.spez .bridge_view .raising-flag .discr,
body.spez .bridge_view .boggers-flag .discr {
  font-size: 18px;
}
body.spez .bridge_view .boggers-flag span:not(.discr) {
  color: #fff;
}
body.spez .bridge_view .boggers-flag {
  font-size: 14px;
}
body.spez.scale-2 .bridge_view .boggers-flag {
  font-size: 15px;
}
body.spez.scale-3 .bridge_view .boggers-flag {
  font-size: 16px;
}
body.spez .bridge_view .raising-flag span:not(.discr) {
  font-size: 20px;
  color: #fff;
}
body.spez.scale-2 .bridge_view .raising-flag .discr,
body.spez.scale-2 .bridge_view .boggers-flag .discr {
  font-size: 20px;
}
body.spez.scale-2 .bridge_view .raising-flag span:not(.discr) {
  font-size: 22px;
}
body.spez.scale-3 .bridge_view .raising-flag .discr,
body.spez.scale-3 .bridge_view .boggers-flag .discr {
  font-size: 22px;
}
body.spez.scale-3 .bridge_view .raising-flag span:not(.discr) {
  font-size: 24px;
}
body.spez .photo_list .gallery_link span.count,
body.spez .photo_list .gallery_link_sec span.count {
  color: #fff;
  font-size: 15px;
}
body.spez .contacts .inf_block_one p.bold,
body.spez .contacts .inf_block_one p strong {
  font-size: 12px;
}
body.spez .contacts .inf_block_one p {
  font-size: 14px;
}
body.spez.scale-2 .contacts .inf_block_one p.bold,
body.spez.scale-2 .contacts .inf_block_one p strong {
  font-size: 14px;
}
body.spez.scale-2 .contacts .inf_block_one p {
  font-size: 16px;
}
body.spez .brige-100_years .breadcrumbs {
  border-top: 1px solid #000;
  background: white;
}
body.spez .brige-100_years .breadcrumbs span,
body.spez .brige-100_years .breadcrumbs a,
body.spez .brige-100_years .breadcrumbs a span {
  color: #000;
}
body.spez .form .input p i {
  display: none;
}
body.spez .contacts .form .input input {
  padding-left: 20px;
}
body.spez.color-black .brige-100_years .breadcrumbs {
  background: #000;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.regiment .header__top-spec {
  filter: hue-rotate(245deg);
}
body.spez.color-black .brige-100_years .breadcrumbs span,
body.spez.color-black .brige-100_years .breadcrumbs a,
body.spez.color-black .brige-100_years .breadcrumbs a span {
  color: #fff;
}
body.spez.scale-3 .contacts .inf_block_one p.bold,
body.spez.scale-3 .contacts .inf_block_one p strong {
  font-size: 16px;
}
body.spez.scale-3 .contacts .inf_block_one p {
  font-size: 17px;
}
body.spez.color-black .btn_mob span:after {
  background: #fff;
}
body.spez.color-black .contest--gallery .txt .name {
  color: #fff;
}
body.spez .form .captcha .input p {
  padding-left: 7px;
}
body.spez .wysiwyg ul li,
body.spez .wysiwyg ol li {
  font-size: 16px !important;
}
body.spez.scale-2 .wysiwyg ul li,
body.spez.scale-2 .wysiwyg ol li {
  font-size: 18px !important;
}
body.spez.scale-3 .wysiwyg ul li,
body.spez.scale-3 .wysiwyg ol li {
  font-size: 20px !important;
}
body.spez .form.light fieldset.row {
  margin: 0;
}
body.spez .form.light .file .input,
body.spez .light.big_form .checkbox span {
  font-size: 14px;
}
body.spez.scale-2 .form.light .file .input,
body.spez.scale-2 .light.big_form .checkbox span {
  font-size: 15px;
}
body.spez.scale-3 .form.light .file .input,
body.spez.scale-3 .light.big_form .checkbox span {
  font-size: 16px;
}
body.spez .form.light .file .input {
  padding: 10px 10px 10px 48px;
}
body.spez .form.light .file span.input {
  border: 0;
  margin-top: -10px;
  position: relative;
  display: block;
  width: 80%;
}
body.spez .form p.mandatory {
  font-size: 14px;
}
body.spez.scale-2 .form p.mandatory {
  font-size: 16px;
}
body.spez.scale-3 .form p.mandatory {
  font-size: 18px;
}
body.spez.color-black .light.big_form .checkbox span {
  color: #000;
}
body.spez.regiment .person-title h1 {
  font-size: 45px;
}
body.spez.regiment.scale-2 .person-title h1 {
  font-size: 50px;
}
body.spez.regiment.scale-3 .person-title h1 {
  font-size: 55px;
}
body.spez.regiment .person-title span {
  font-size: 26px;
}
body.spez.regiment.scale-2 .person-title span {
  font-size: 28px;
}
body.spez.regiment.scale-3 .person-title span {
  font-size: 30px;
}
body.spez.regiment footer .footer__top p:last-child a {
  color: #fff !important;
}
body.spez.regiment.color-black .breadcrumbs span {
  color: #fff;
}
body.spez .win_person a.blue {
  color: #000 !important;
}
body.spez .modal-content .name a,
body.spez .modal-content .name span {
  color: #000;
}
body.spez .staff .name h1 {
  color: #000;
  font-size: 22px;
}
body.spez .staff .name span {
  font-size: 14px;
}
body.spez.scale-2 .staff .name h1 {
  font-size: 24px;
}
body.spez.scale-2 .staff .name span {
  font-size: 16px;
}
body.spez.scale-3 .staff .name h1 {
  font-size: 26px;
}
body.spez.scale-3 .staff .name span {
  font-size: 18px;
}
body.spez.scale-2 .header__top .header__top-mail {
  margin-top: 4px;
  margin-left: 3%;
}
body.spez.scale-3 .header__top .header__top-mail {
  margin-top: 3px;
  margin-left: 3%;
}
body.spez.color-black .staff .name h1,
body.spez.color-black .staff .name span {
  color: #fff;
}
body.spez
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #000;
}
body.spez .news_one .ya-share2:before,
body.spez .dir .person .name,
body.spez .doc_list a.doc_image,
body.spez .prs_center .cnt_one p a,
body.spez .form .input p,
body.spez .form .textarea p,
body.spez .mob_m .menu li .sub a,
body.spez .select2-container--default .select2-results__option[aria-selected] {
  font-size: 14px;
}
body.spez.bloggers.scale-2 .bloggers_view .txt p,
body.spez.scale-2 .news_one .ya-share2:before,
body.spez.scale-2 .dir .person .name,
body.spez.scale-2 .doc_list a.doc_image,
body.spez.scale-2 .prs_center .cnt_one p a,
body.spez.scale-2 .form .input p,
body.spez.scale-2 .form .textarea p,
body.spez.scale-2 .mob_m .menu li .sub a,
body.spez.scale-2
  .select2-container--default
  .select2-results__option[aria-selected] {
  font-size: 16px;
}
body.spez.bloggers.scale-3 .bloggers_view .txt p,
body.spez.scale-3 .news_one .ya-share2:before,
body.spez.scale-3 .dir .person .name,
body.spez.scale-3 .doc_list a.doc_image,
body.spez.scale-3 .prs_center .cnt_one p a,
body.spez.scale-3 .form .input p,
body.spez.scale-3 .form .textarea p,
body.spez.scale-3 .mob_m .menu li .sub a,
body.spez.scale-3
  .select2-container--default
  .select2-results__option[aria-selected] {
  font-size: 18px;
}
body.spez .blog_item p.cont {
  height: 125px;
}
body.spez .contest--head {
  font-size: 50px !important;
}
body.spez.color-black .modal-content .name {
  color: #000;
}
body.spez .winner .contest--title {
  font-size: 30px !important;
}
body.spez .doc_list a.doc_image span.img i {
  filter: brightness(10%);
}
body.spez.color-black .doc_list a.doc_image span.img i {
  filter: none;
}
body.spez.color-black .doc_slide p.date span {
  color: #fff;
}
body.spez.color-black .prs_center .form p.hd,
body.spez.color-black .form p.mandatory,
body.spez.color-black .form p.mandatory span {
  color: #000;
}
body.spez .form .textarea p span span {
  color: #000;
}
body.spez.color-black .form .textarea p span span {
  color: #fff;
}
body.spez .contacts .form .textarea p span span {
  color: #fff;
}
body.spez.color-black .contacts .form p.mandatory,
body.spez.color-black .contacts .form p.mandatory span,
body.spez .contacts .form p.hd {
  color: #fff;
}
body.spez .contacts .form .input p span span,
body.spez .contacts .form p.mandatory span {
  color: #fff;
}
body.spez.color-black .contest--head a.bloggers {
  filter: brightness(500%) grayscale(100%);
}
body.spez .contacts .form button {
  border: 2px solid #fff;
  height: auto;
}
body.spez .pagination a.round,
body.spez .pagination_index .pag_select span {
  font-size: 14px;
}
body.spez.scale-2 .pagination_index .pag_select {
  width: 190px;
}
body.spez.scale-2 .pagination a.round,
body.spez.scale-2 .pagination_index .pag_select span,
body.spez.scale-2 .pagination_index .pag_select .select_wrap a {
  font-size: 15px;
}
body.spez.scale-3 .pagination_index .pag_select {
  width: 200px;
}
body.spez.scale-3 .pagination a.round,
body.spez.scale-3 .pagination_index .pag_select span,
body.spez.scale-3 .pagination_index .pag_select .select_wrap a {
  font-size: 16px;
}
body.spez .mob_m .menu li .sub a {
  color: #fff;
}
body.spez .breadcrumbs a span {
  color: #000;
}
body.spez.color-black .breadcrumbs a span {
  color: #fff;
}
body.spez.scale-2 .news_one .ya-share2 {
  padding-left: 120px;
}
body.spez.scale-3 .news_one .ya-share2 {
  padding-left: 140px;
}
body.spez .contact-map {
  font-size: 16px;
}
body.spez.scale-2 .contact-map {
  font-size: 18px;
}
body.spez.scale-3 .contact-map {
  font-size: 20px;
}
body.spez .contact-map .h {
  font-size: 20px;
  color: #000;
}
body.spez.scale-2 .contact-map .h {
  font-size: 22px;
}
body.spez.scale-3 .contact-map .h {
  font-size: 24px;
}
body.spez .slider .slide_one .p1.m--single {
  color: #fff;
}
body.spez .slider .slide_one .p1,
body.spez .slider .slide_one .p1.m--single,
body.spez .slider .slide_one .completion p,
body.spez .slider .slide_one p,
body.spez .info_blocks .widget span.details,
body.spez .info_blocks .widget span.s2 {
  font-size: 20px;
  line-height: 1em;
}
body.spez .p_blocks p.white {
  color: #000;
}
body.spez.scale-2 .slider .slide_one .p1,
body.spez.scale-2 .slider .slide_one .p1.m--single,
body.spez.scale-2 .slider .slide_one .completion p,
body.spez.scale-2 .slider .slide_one p,
body.spez.scale-2 .info_blocks .widget span.details,
body.spez.scale-2 .info_blocks .widget span.s2 {
  font-size: 22px;
}
body.spez.scale-3 .slider .slide_one .p1,
body.spez.scale-3 .slider .slide_one .p1.m--single,
body.spez.scale-3 .slider .slide_one .completion p,
body.spez.scale-3 .slider .slide_one p,
body.spez.scale-3 .info_blocks .widget span.details,
body.spez.scale-3 .info_blocks .widget span.s2 {
  font-size: 25px;
}
body.spez .slider .slide_one .current_time:after {
  filter: grayscale(100%);
}
body.spez .p_blocks p {
  font-size: 15px;
}
body.spez.color-black .p_blocks p {
  color: #000;
}
body.spez .dir .person .name {
  color: #000;
}
body.spez.color-black .dir .person .name {
  color: #fff;
}
body.spez.scale-2 .p_blocks p {
  font-size: 16px;
}
body.spez.scale-3 .p_blocks p {
  font-size: 17px;
}
body.spez .select-custom {
  filter: grayscale(100%);
}
body.spez .select {
  position: relative;
  z-index: 10;
}
body.spez.fb-body .select {
  z-index: 2;
}
body.spez.color-black .select-styled {
  right: 1px;
  left: 1px;
  background-color: #000;
}
/* body.spez.scale-2 .select{
  //height: 44px;
} */
body.spez.scale-2 .select-custom {
  height: 42px;
  font-size: 16px;
}
body.spez.scale-2 .select-options li {
  font-size: 16px;
}
body.spez.scale-2 .select-options {
  margin-top: -43px;
}
/* body.spez.scale-3 .select{
  //height: 45px;
} */
body.spez #audioguide {
  filter: grayscale(100%);
}
body.spez.scale-3 .select-custom {
  height: 43px;
  font-size: 17px;
}
body.spez.scale-3 .select-options li {
  font-size: 17px;
}
body.spez.scale-3 .select-options {
  margin-top: -43px;
}
body.spez.color-black .select-options {
  background: #000;
}
body.spez.color-black .select-options {
  background: #000;
}
body.spez.color-black .select-options li {
  border-color: #fff;
  color: #fff;
}
body.spez.color-black .select-options li:hover {
  background: #fff;
  color: #000;
}
body.spez .gallery_list .gallery_link_sec span.txt,
body.spez .gallery_list .gallery_link_sec span.txt b {
  font-size: 12px;
}
body.spez.scale-2 .gallery_list .gallery_link_sec span.txt,
body.spez.scale-2 .gallery_list .gallery_link_sec span.txt b {
  font-size: 14px;
}
body.spez.scale-3 .gallery_list .gallery_link_sec span.txt,
body.spez.scale-3 .gallery_list .gallery_link_sec span.txt b {
  font-size: 16px;
}
body.spez.jubilee header,
body.spez.jubilee .jubilee__bridge,
body.spez.jubilee .jubilee__content,
body.spez.jubilee,
body.spez.jubilee {
  background: none;
}

body.spez.jubilee .jubilee__bridge:before,
body.spez.jubilee .jubilee__bridge:after {
  display: none;
}
body.spez.jubilee header {
  border-bottom: 1px solid #000;
}
body.spez.jubilee .jubilee__bridge h1 {
  color: #000;
  font-size: 60px;
  margin-bottom: 30px;
}
body.spez.jubilee.scale-2 .jubilee__bridge h1 {
  font-size: 65px;
}
body.spez.jubilee.scale-3 .jubilee__bridge h1 {
  font-size: 70px;
}
body.spez.jubilee .jubilee__sep {
  display: none;
}
body.spez.jubilee .jubilee__bridge p {
  line-height: 40px;
  font-style: normal;
  font-size: 22px;
}
body.spez.jubilee.scale-2 .jubilee__bridge p {
  font-size: 24px;
}
body.spez.jubilee.scale-3 .jubilee__bridge p {
  font-size: 26px;
}
body.spez.jubilee .jubilee__content {
  padding: 0 0 40px;
}
body.spez.jubilee .jubilee__content h2,
body.spez.jubilee .jubilee__text h2,
body.spez.jubilee .jubilee__gallery h2 {
  font-size: 55px;
  color: #000;
}
body.spez.jubilee.scale-2 .jubilee__content h2,
body.spez.jubilee.scale-2 .jubilee__text h2,
body.spez.jubilee.scale-2 .jubilee__gallery h2 {
  font-size: 60px;
}
body.spez.jubilee.scale-3 .jubilee__content h2,
body.spez.jubilee.scale-3 .jubilee__text h2,
body.spez.jubilee.scale-3 .jubilee__gallery h2 {
  font-size: 65px;
}
body.spez.jubilee .header__middle .logo {
  background-image: url(../img/logo.png);
  background-size: contain;
  display: block;
  margin: 18px 0 0 1px;
}
body.spez.jubilee .jubilee__current_item h3,
body.spez.jubilee .jubilee__current_date,
body.spez.jubilee .jubilee__current_time {
  color: #000;
}
body.spez.jubilee.color-black .jubilee__current_item h3,
body.spez.jubilee.color-black .jubilee__current_date,
body.spez.jubilee.color-black .jubilee__current_time,
body.spez.jubilee.color-black .jubilee__icon {
  color: #fff;
}
body.spez.jubilee .jubilee__content_caption,
body.spez.jubilee
  .jubilee__grayblock_caption
  body.spez.jubilee
  .jubilee__grayblock_text
  p,
body.spez.jubilee .jubilee__content p,
body.spez.jubilee .jubilee__top p,
body.spez.jubilee .jubilee__text p,
body.spez.jubilee .jubilee__current_item h3,
body.spez.jubilee .jubilee__current_date,
body.spez.jubilee .jubilee__current_time,
body.spez.jubilee .jubilee__jinks_item,
body.spez.jubilee .jubilee__months_item,
body.spez.jubilee .jubilee__years_item span,
body.spez.jubilee .jubilee__button,
body.spez.jubilee .jubilee__gallery_btn {
  font-size: 17px !important;
  font-style: normal;
  text-shadow: none;
}
body.spez.jubilee.scale-2 .jubilee__content_caption,
body.spez.jubilee.scale-2
  .jubilee__grayblock_caption
  body.spez.jubilee.scale-2
  .jubilee__grayblock_text
  p,
body.spez.jubilee.scale-2 .jubilee__content p,
body.spez.jubilee.scale-2 .jubilee__top p,
body.spez.jubilee.scale-2 .jubilee__text p,
body.spez.jubilee.scale-2 .jubilee__current_item h3,
body.spez.jubilee.scale-2 .jubilee__current_date,
body.spez.jubilee.scale-2 .jubilee__current_time,
body.spez.jubilee.scale-2 .jubilee__jinks_item,
body.spez.jubilee.scale-2 .jubilee__months_item,
body.spez.jubilee.scale-2 .jubilee__years_item span,
body.spez.jubilee.scale-2 .jubilee__button,
body.spez.jubilee.scale-2 .jubilee__gallery_btn {
  font-size: 19px !important;
  font-style: normal;
}
body.spez.jubilee.scale-3 .jubilee__content_caption,
body.spez.jubilee.scale-3
  .jubilee__grayblock_caption
  body.spez.jubilee.scale-3
  .jubilee__grayblock_text
  p,
body.spez.jubilee.scale-3 .jubilee__content p,
body.spez.jubilee.scale-3 .jubilee__top p,
body.spez.jubilee.scale-3 .jubilee__text p,
body.spez.jubilee.scale-3 .jubilee__current_item h3,
body.spez.jubilee.scale-3 .jubilee__current_date,
body.spez.jubilee.scale-3 .jubilee__current_time,
body.spez.jubilee.scale-3 .jubilee__jinks_item,
body.spez.jubilee.scale-3 .jubilee__months_item,
body.spez.jubilee.scale-3 .jubilee__years_item span,
body.spez.jubilee.scale-3 .jubilee__button,
body.spez.jubilee.scale-3 .jubilee__gallery_btn {
  font-size: 21px !important;
  font-style: normal;
}
body.spez.jubilee .jubilee__grayblock {
  border: 1px solid #000;
}
body.spez.jubilee.color-black .jubilee__grayblock {
  border: 1px solid #fff;
}
body.spez.jubilee.color-black header,
body.spez.jubilee.color-black .jubilee__bridge,
body.spez.jubilee.color-black .jubilee__content,
body.spez.jubilee.color-black .jubilee__grayblock,
body.spez.jubilee.color-black .jubilee__gallery,
body.spez.jubilee.color-black .jubilee__main {
  background: #000;
}
body.spez.jubilee .jubilee__gallery_btn {
  color: #000;
  border-color: #000;
}
body.spez.jubilee.color-black .jubilee__bridge h1,
body.spez.jubilee.color-black .jubilee__content h2,
body.spez.jubilee.color-black .jubilee__bridge p,
body.spez.jubilee.color-black .jubilee__grayblock_caption,
body.spez.jubilee.color-black .jubilee__text h2,
body.spez.jubilee.color-black .jubilee__text p a,
body.spez.jubilee.color-black .jubilee__gallery_btn {
  color: #fff;
}
body.spez.jubilee.color-black .jubilee__gallery_btn {
  color: #000;
  border-color: #000;
  background: #fff;
}
body.spez.jubilee .jubilee__top p,
body.spez.jubilee .jubilee__gallery h2 {
  color: #000;
}
body.spez.jubilee .jubilee__video_wrap {
  display: none;
}
body.spez.jubilee .jubilee__top,
body.spez.jubilee .jubilee__gallery {
  background: #fff;
}
body.spez.jubilee.color-black .jubilee__top {
  background: #000;
}
body.spez.jubilee .jubilee__head,
body.spez.jubilee .jubilee__current h2 {
  color: #000;
}
body.spez.jubilee.color-black .jubilee__head,
body.spez.jubilee.color-black .jubilee__top {
  color: #fff;
}
body.spez.jubilee .jubilee__top:before,
body.spez.jubilee .jubilee__top:after,
body.spez.jubilee .jubilee__current:before,
body.spez.jubilee .jubilee__current:after {
  display: none;
}
body.spez.jubilee .jubilee__current {
  border-top: 1px solid #000;
}
body.spez.jubilee .jubilee__calendar {
  background: #fff;
  padding-top: 0;
}
body.spez.jubilee.color-black .jubilee__current,
body.spez.jubilee.color-black .jubilee__calendar,
body.spez.jubilee .jubilee__button {
  background: #000;
}
body.spez.jubilee .jubilee__button {
  color: #fff;
  background: #000;
}
body.spez.jubilee.color-black .jubilee__button {
  color: #000;
  background: #fff;
}
body.spez.jubilee.color-black .jubilee__top p,
body.spez.jubilee.color-black .jubilee__current h2,
body.spez.jubilee.color-black .jubilee__gallery h2 {
  color: #fff;
}
body.spez.jubilee .jubilee__main,
body.spez.jubilee .jubilee__current {
  background: #fff;
}
.body.spez.jubilee .jubilee__current {
  padding-bottom: 0;
}
body.spez.jubilee .jubilee__years_item span,
body.spez.jubilee .jubilee__months_item {
  color: #000;
  background: #fff;
  border-color: #000;
}
body.spez.jubilee .jubilee__years_item span:before {
  background: #000;
  border-color: #000;
}
body.spez.jubilee .jubilee__years_item.m--disabled span:before {
  background: rgba(0, 0, 0, 0.5);
}
body.spez.jubilee .jubilee__calendar.m--top:before {
  display: none;
}
body.spez.jubilee .jubilee__top {
  padding: 40px 0;
  height: auto;
}
body.spez.jubilee .jubilee__years .slick-prev,
body.spez.jubilee .jubilee__years .slick-next {
  filter: grayscale(100%);
}
body.spez.jubilee .jubilee__months_item.m--disabled {
  background: rgba(0, 0, 0, 0.5);
}
body.spez.jubilee.color-black .jubilee__months_item.m--disabled {
  background: rgba(255, 255, 255, 0.5);
}
body.spez.jubilee .jubilee__calendar h2 {
  font-size: 43px;
}
body.spez.jubilee.scale-2 .jubilee__calendar h2 {
  font-size: 46px;
}
body.spez.jubilee.scale-3 .jubilee__calendar h2 {
  font-size: 49px;
}
body.spez.jubilee.color-black .jubilee__calendar h2 {
  color: #fff;
}
body.spez.jubilee .jubilee__months_item {
  width: 92px;
}
body.spez.jubilee.scale-2 .jubilee__months_item {
  width: 102px;
}
body.spez.jubilee.scale-3 .jubilee__months_item {
  width: 122px;
}
body.spez.jubilee.color-black .container {
  background: #000;
}
body.spez.seige-body.color-black .seige_days-days,
body.spez.seige-body.color-black .seige_days-title,
body.spez.seige-body.color-black .seige-title,
body.spez.seige-body.color-black .seige_days .seige-h2,
body.spez.seige-body.color-black .seige-text,
body.spez.seige-body.color-black .seige-link,
body.spez.seige-body.color-black .seige-h4,
body.spez.seige-body.color-black .seige-h3,
body.spez.seige-body.color-black .seige-h2,
body.spez.seige-body.color-black .seige_star-list li,
body.spez.seige-body.color-black .seige-text-block p,
body.spez.seige-body.color-black
  .seige-docs-list
  ul
  li
  .seige-docs-item::before {
  color: #ffffff;
}
body.spez.seige-body.color-black .seige_jumb {
  background: #000 url(/img/seige_main.png) right top no-repeat;
  background-size: 95.5%;
}
body.spez.seige-body.color-black .seige-btn,
body.spez.seige-body.color-black .seige-header-cat {
  background: white;
  color: #000;
  padding-left: 16px;
}
body.spez.seige-body.color-black .seige-sep_stars {
  filter: invert(1);
}
body.spez.seige-body.color-black .seige-doc-sep_stars {
  filter: invert(1) grayscale(1);
}
body.spez.seige-body.color-black p.seige-footnote,
body.spez.seige-body.color-black .seige-docs-thanks p,
body.spez.seige-body.color-black p.photo-thanks,
body.spez.seige-body.color-black .seige-docs-list ul li {
  border-color: white;
}
body.spez.seige-body.color-black .seige_jumb-cat,
body.spez.seige-body.color-black .seige-photo_wrap,
body.spez.seige-body.color-black .seige-doc-btn.open {
  background: #000;
}
body.spez.seige-body.color-black .seige_star-list li:before {
  filter: contrast(10) grayscale(1) invert(1);
}
body.spez.scale-2.seige-body .content .container {
  padding-bottom: 0;
}
body.spez.fb-body .fb-sign {
  line-height: 30px;
  font-size: 22px;
}
body.spez.fb-body.scale-2 .fb-sign {
  font-size: 24px;
}
body.spez.fb-body.scale-3 .fb-sign {
  font-size: 26px;
}

body.spez.fb-body .fb_sbornaya-slide__text p,
body.spez.fb-body .routes_info,
body.spez.fb-body .fb_about p,
body.spez.fb-body .fb_new-text p,
body.spez.fb-body .fb-path-way ul li,
body.spez.fb-body .fb-path-intro__info-block p,
body.spez.fb-body .path-accent,
body.spez.fb-body .path-man,
body.spez.fb-body .fb-path-intro___arr-link,
body.spez.fb-body .fb-obj-list label,
body.spez.fb-body .fb-header label,
body.spez.fb-body .fb_sbornaya-slide__text a,
body.spez.fb-body .route-info,
body.spez.fb-body .fb-modal-text p,
body.spez.fb-body .info,
body.spez.fb-body .info-label,
body.spez.fb-body .fb_what-look__title,
body.spez.fb-body .fb-modal-list li {
  line-height: 20px;
  font-size: 16px;
}
body.spez.fb-body.scale-2 .fb_sbornaya-slide__text p,
body.spez.fb-body.scale-2 .routes_info,
body.spez.fb-body.scale-2 .fb_about p,
body.spez.fb-body.scale-2 .fb_new-text p,
body.spez.fb-body.scale-2 .fb-path-way ul li,
body.spez.fb-body.scale-2 .fb-path-intro__info-block p,
body.spez.fb-body.scale-2 .path-accent,
body.spez.fb-body.scale-2 .path-man,
body.spez.fb-body.scale-2 .fb-path-intro___arr-link,
body.spez.fb-body.scale-2 .fb-obj-list label,
body.spez.fb-body.scale-2 .fb-header label,
body.spez.fb-body.scale-2 .fb_sbornaya-slide__text a,
body.spez.fb-body.scale-2 .route-info,
body.spez.fb-body.scale-2 .fb-modal-text p,
body.spez.fb-body.scale-2 .info,
body.spez.fb-body.scale-2 .info-label,
body.spez.fb-body.scale-2 .fb_what-look__title,
body.spez.fb-body.scale-2 .fb-modal-list li {
  line-height: 22px;
  font-size: 18px;
}
body.spez.fb-body.scale-3 .fb_sbornaya-slide__text p,
body.spez.fb-body.scale-3 .routes_info,
body.spez.fb-body.scale-3 .fb_about p,
body.spez.fb-body.scale-3 .fb_new-text p,
body.spez.fb-body.scale-3 .fb-path-way ul li,
body.spez.fb-body.scale-3 .fb-path-intro__info-block p,
body.spez.fb-body.scale-3 .path-accent,
body.spez.fb-body.scale-3 .path-man,
body.spez.fb-body.scale-3 .fb-path-intro___arr-link,
body.spez.fb-body.scale-3 .fb-obj-list label,
body.spez.fb-body.scale-3 .fb-header label,
body.spez.fb-body.scale-3 .fb_sbornaya-slide__text a,
body.spez.fb-body.scale-3 .route-info,
body.spez.fb-body.scale-3 .fb-modal-text p,
body.spez.fb-body.scale-3 .info,
body.spez.fb-body.scale-3 .info-label,
body.spez.fb-body.scale-3 .fb_what-look__title,
body.spez.fb-body.scale-3 .fb-modal-list li {
  line-height: 24px;
  font-size: 20px;
}
body.spez .about-logo {
  filter: grayscale(100%) contrast(0);
}
body.spez.fb-body .fb-obj-block {
  width: 33%;
}
body.spez.fb-body .label-info,
body.spez.fb-body .route__player-info,
body.spez.fb-body .fb_what-look__address,
body.spez.fb-body .fb-slide-btn {
  font-size: 15px;
}
body.spez.fb-body.scale-2 .label-info,
body.spez.fb-body.scale-2 .route__player-info,
body.spez.fb-body.scale-2 .fb_what-look__address,
body.spez.fb-body.scale-2 .fb-slide-btn {
  font-size: 17px;
}
body.spez.fb-body.scale-3 .label-info,
body.spez.fb-body.scale-3 .route__player-info,
body.spez.fb-body.scale-3 .fb_what-look__address,
body.spez.fb-body.scale-3 .fb-slide-btn {
  font-size: 19px;
}

body.spez.fb-body.color-black .path-man:after {
  filter: invert(1);
}

body.spez.fb-body.color-black .fb_banner,
body.spez.fb-body.color-black .fb_about,
body.spez.fb-body.color-black .fb_routes,
body.spez.fb-body.color-black .fb_new-text,
body.spez.fb-body.color-black .fb-path-what-look,
body.spez.fb-body.color-black .fb-path-length,
body.spez.fb-body.color-black .fb_what-look-text,
body.spez.fb-body.color-black .fb-header,
body.spez.fb-body.color-black .fb-obj-list,
body.spez.fb-body.color-black .fb-modal .modal-content,
body.spez.fb-body.color-black .fb-map__route-info {
  background: #000;
}
body.spez.fb-body footer {
  background: #222a32;
}
body.spez.fb-body.color-black .fb_new-date,
body.spez.fb-body.color-black .fb_sbornaya-nav-slider li a,
body.spez.fb-body.color-black .fb-h2.dark,
body.spez.fb-body.color-black .fb-h2,
body.spez.fb-body.color-black .fb_sbornaya-slide__text a,
body.spez.fb-body.color-black .fb_routes p.route-title,
body.spez.fb-body.color-black .routes_count,
body.spez.fb-body.color-black .route_title-2,
body.spez.fb-body.color-black .fb-h1,
body.spez.fb-body.color-black .fb-path-intro___arr-link,
body.spez.fb-body.color-black .fb-path-way ul li,
body.spez.fb-body.color-black .fb-path-way ul li:before,
body.spez.fb-body.color-black .fb-modal-list li {
  color: white;
}
body.spez.fb-body.color-black .fb-btn,
body.spez.fb-body.color-black .fb-slide-btn,
body.spez.fb-body.color-black .fb-obj-block {
  border: 1px solid white;
  background: #000 !important;
}
