.content-with-map {
  padding: 32px 0 94px;

  &__wrap {
    display: grid;
    grid-template-columns: 1fr 390px;
    gap: 10px 165px;
  }

  .title_h2 {
    color: #000;
    font-family: open_sanslight, sans-serif;
    font-weight: 400;
    text-transform: none;
    font-size: 40px;
    line-height: 1.25;
    margin-bottom: 33px;
  }

  .wysiwyg {
    margin: 0;

    p {
      line-height: 250%;
      margin-bottom: 35px;
    }
  }

  .btn {
    color: #fff;
    background: #27aae0;
    font: 12px 'open_sansbold', sans-serif;
    text-transform: uppercase;
    padding: 8px 17px;
    padding-right: 62px;
    border-radius: 100px;
    position: relative;
    display: inline-block;

    i {
      position: absolute;
      right: 14px;
      top: 11px;
      filter: grayscale(1) invert(1) brightness(100);
    }
  }

  .map {
    [class*='ground-pane'] {
      filter: grayscale(100%);
    }
  }
}

@media screen and (max-width: 1200px) {
  .content-with-map {
    padding: 30px 0;

    &__wrap {
      display: grid;
      grid-template-columns: 1fr 390px;
      gap: 10px 30px;
    }
  }
}

@media screen and (max-width: 992px) {
  .content-with-map {
    &__wrap {
      display: grid;
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }
}

@media screen and (max-width: 680px) {
  .content-with-map {
    .title_h2 {
      font-size: 25px;
    }

    .wysiwyg {
      p {
        margin-bottom: 25px;
      }
    }
  }
}
