.project_photo_list {
  margin-bottom: 60px;
}
.project_photo_list .sep {
  background: url(../img/sep4.png) repeat-x;
  height: 1px;
  opacity: 0.5;
  margin: 8px 0 50px;
}
.project_photo_list .img {
  position: relative;
  padding-top: 100%;
  margin-bottom: 10px;
}
.project_photo_list .img a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.project_photo_list .img a img {
  width: 100%;
}

.project_list {
  padding: 0 0 10px;
}
.project_list .sep {
  background: url(../img/sep4.png) repeat-x;
  height: 1px;
  opacity: 0.5;
  margin: 8px 0 50px;
}
.project_list .img {
  position: relative;
  padding-top: 100%;
  margin-bottom: 10px;
}
.project_list .img a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.project_list .img a img {
  width: 100%;
}
.project_list .img a:after {
  background: rgba(0, 0, 0, 0.6);
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
}
.project_list .img a .title {
  position: absolute;
  top: 33px;
  left: 0;
  width: 100%;
  padding: 0 20px 0 40px;
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'open_sansextrabold', sans-serif;
  line-height: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
}
.project_list .img a .title:after {
  background: #27aae0;
  content: '';
  display: block;
  width: 54px;
  height: 3px;
  margin-top: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.project_list .img a .details {
  position: absolute;
  bottom: 36px;
  left: 0;
  width: 100%;
  padding: 0 20px 0 40px;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
}
