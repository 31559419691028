.breadcrumbs {
  background: #f8f8f8;
  position: relative;
  margin: 17px 0 35px;
  padding: 9px 0 12px;
}
.breadcrumbs:before {
  background: url(../img/sep4.png) repeat-x;
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0;
  height: 1px;
}
.breadcrumbs a {
  font-size: 12px;
  color: #919191;
}
.breadcrumbs a span {
  color: #919191;
}
.breadcrumbs .sep {
  color: #919191;
}
.breadcrumbs li {
  display: inline-block;
  font-size: 12px;
}
.breadcrumbs ul {
  margin-bottom: 2px;
}
