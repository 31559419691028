.staff {
  position: relative;
  overflow: hidden;
}
.staff.view:after {
  background: url(../img/sep4.png) repeat-x;
  content: '';
  display: block;
  opacity: 0.5;
  height: 1px;
}
.staff.view .img img {
  max-width: 100%;
  width: auto;
}

.staff:not(.view) .img {
  position: relative;
  padding-top: 100%;
}

.staff:not(.view) .img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.staff .title {
  position: relative;
  overflow: hidden;
  font-size: 15px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
  line-height: 20px;
  padding: 9px 0 0;
  height: 82px;
  margin: 0 0 39px;
}
.staff .title span {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  color: black;
  margin-top: 4px;
}
.staff .name h1 {
  font-size: 18px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
  text-transform: uppercase;
  margin-top: 0;
}
.staff .name span {
  display: block;
  font-size: 12px;
  font-family: 'open_sansextrabold', sans-serif;
  text-transform: uppercase;
  color: black;
  margin-top: 4px;
}
.staff .wysiwyg {
  padding-top: 32px;
}

.team {
  position: relative;
  overflow: hidden;
}
.team .row_sec div {
  margin-bottom: 50px;
}
.team p {
  line-height: 20px;
}
.team p.name {
  font-size: 15px;
  font-family: 'open_sansextrabold', sans-serif;
  color: #27aae0;
  margin-top: 30px;
}
.team p.name.frs {
  font-size: 18px;
  text-transform: uppercase;
  margin: 0;
}
.team p.status {
  font-size: 12px;
  font-family: 'open_sansbold', sans-serif;
  text-transform: uppercase;
  margin: 7px 0 28px;
}
.team img {
  width: 100%;
}
.chief .banner {
  background: #f2f2f2;
  min-height: 370px;
  margin-bottom: 45px;
}
.chief .banner .name {
  padding-top: 121px;
  margin-left: 42px;
  text-transform: uppercase;
  font-size: 30px;
  font-family: 'open_sansextrabold', sans-serif;
  line-height: 40px;
  color: #27aae0;
}
.chief .banner .name span {
  display: block;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  margin-top: 25px;
}
.chief .banner img {
  float: right;
  height: 370px;
}
.chief .wysiwyg p {
  font-size: 12px;
  margin-bottom: 21px;
}
