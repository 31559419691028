.main-map {
  position: relative;
  padding: 53px 0 30px;

  [class*='ground-pane'] {
    filter: grayscale(100%);
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 1190px;
    height: 1px;
    margin: auto;
    background-color: #e8e9ea;
  }

  .block-title {
    margin-bottom: 20px;
  }

  &__wrap {
    position: relative;
  }

  &__tabs {
    display: flex;
    align-items: center;
    position: absolute;
    left: 60px;
    top: 30px;
    z-index: 2;

    button {
      background-color: #fff;
      font-family: 'open_sanssemibold', sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      border-left: transparent;
      border-top: 2px solid #27aae0;
      border-right: transparent;
      border-bottom: 2px solid #27aae0;
      padding: 6px 16px;

      &:first-child {
        border-left: 2px solid #27aae0;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
      }

      &:last-child {
        border-right: 2px solid #27aae0;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
      }

      &.active {
        background-color: #27aae0;
        color: #ffffff;
      }
    }
  }

  &__contents {
    height: 580px;
    background-color: #f4f4f5;
    border-radius: 10px;
    overflow: hidden;
  }

  &__content {
    box-sizing: border-box;
    display: none;
    height: 100%;
    max-height: 100%;
    overflow: auto;

    &_list {
      padding: 85px 62px 56px;
    }

    &.active {
      display: block;
    }

    #mainmap {
      height: 100%;
      width: 100%;
    }

    .list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: block;

      &__item {
        display: flex;
        align-items: center;
        padding: 7px 0;

        &:not(:last-child) {
          border-bottom: 1px solid #dfdfe1;
        }

        .key {
          font-size: 17px;
        }

        .value {
          display: flex;
          align-items: center;
          gap: 22px;
          margin-left: auto;

          p {
            font-family: 'open_sansbold', sans-serif;
            font-size: 20px;

            &:not(:last-child) {
              position: relative;
              padding-right: 23px;

              &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: #222a32;
              }
            }
          }
        }
      }
    }
  }

  // Элементы карты
  &__balloon {
    position: relative;
    display: block;
    width: 235px;
    padding: 33px 20px 20px;
    background-color: #fff;
    box-shadow: 0px 3px 7px 0px rgba(0, 41, 75, 0.35);
    border-radius: 10px;
    transform: translate(-38px, calc(-100% - 8px));

    &-close {
      width: 25px;
      height: 25px;
      position: absolute;
      z-index: 2;
      right: 10px;
      top: 10px;
      border: 2px solid #27aae0;
      border-radius: 100px;
      background-color: transparent;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #222a32;
        width: 11px;
        height: 2px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &-arrow {
      position: absolute;
      left: 30px;
      top: 100%;
      border: 8px solid transparent;
      border-top: 8px solid #fff;
      z-index: 2;
    }

    &-content {
      position: relative;

      // overflow: hidden;

      .name {
        font-family: 'open_sansbold', sans-serif;
        font-size: 20px;
        color: #222a32;
        margin: 0;
        margin-bottom: 10px;
        background-color: #fff;
      }

      .content {
        background-color: #fff;
        padding: 0;
        min-height: auto;
        position: static;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0 10px;
          &:not(:last-child) {
            margin-bottom: 5px;
          }

          .key {
            font-size: 14px;
            max-width: 120px;
          }

          .value {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-family: 'open_sansbold', sans-serif;
            font-size: 16px;

            p {
              font-size: 16px;
              text-align: right;
            }

            &_timer {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-map {
    padding: 40px 0 20px;

    &__tabs {
      left: 20px;
      top: 25px;
    }

    &__contents {
      height: 488px;
    }

    &__content {
      &_list {
        padding: 70px 20px 20px;
      }

      .list {
        &__item {
          .key {
            font-size: 15px;
          }

          .value {
            gap: 10px;
            p {
              font-size: 17px;

              &:not(:last-child) {
                padding-right: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .main-map {
    &__contents {
      height: 360px;
    }

    &__content {
      .list {
        &__item {
          .value {
            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .main-map {
    &__content {
      .list {
        &__item {
          display: flex;
          align-items: flex-start;
          gap: 10px;

          .key {
            font-size: 12px;
          }

          .value {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            min-width: 110px;
            width: 110px;

            p {
              &:not(:last-child) {
                padding-right: 0;

                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
